import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { NumberFormatCustom } from 'realtor/components/common/PriceNumberMask/PriceNumberMask';

export const FlooringFilter = ({ filterErrors , filter, onInputChange, isTitleLabel }) => {
  const value = filter.flooring;

  const style = value ? { paddingLeft: '4px' } : {};

  const renderTitle = (title) => {
    return isTitleLabel ? <label>{title}</label> : <h3>{title}</h3>;
  };

  return (
    <FormControl
      margin={'none'}
      helperText={filterErrors.flooring}
      error={filterErrors.flooring}
    >
      {renderTitle(`${i18n.t('FLOORING')}:`)}
      <TextField
        variant="outlined"
        margin="none"
        required
        fullWidth
        id={'flooring'}
        name={'flooring'}
        onChange={onInputChange}
        value={value}
        inputProps={{
          min: 0,
          style,
        }}
        error={!!filterErrors.flooring}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: value && <InputAdornment position="start"></InputAdornment>,
        }}
      />
      <FormHelperText>{filterErrors.flooring}</FormHelperText>
    </FormControl>
  );
};

FlooringFilter.propTypes = {
  filterErrors: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isTitleLabel: PropTypes.bool,
};
