import { connect } from 'react-redux';
import qs from 'qs';
import ShareObjectsListPage from '../components/SharePages/ShareObjectsListPage/ShareObjectsListPage';
import { fetchSharedObjectsByToken, clearObjectsList } from '../actions/objects';

const mapStateToProps = ({ myObjects, appConfigurations }, props) => {
  const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  if (!queryParams.token) location.href = '/';
  return {
    objectsList: myObjects,
    filters: appConfigurations.objectFilters,
    regions: appConfigurations.citiesList,
    objectsHash: queryParams.token,
  };
};

const mapDispatchToProps = {
  clearObjectsList,
  fetchObjects: fetchSharedObjectsByToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareObjectsListPage);
