import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import PropTypes from 'prop-types';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../../../../common/i18n';
import { MAX_PHOTO_SIZE } from '../../../../constants/photos';
import Alert from 'react-s-alert';

const FileUploadBox = ({ onUpload, classes }) => {
  const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

  const handleChange = (files) => {
    const filesToUpload = Array.from(files).filter(({ size }) => size < MAX_PHOTO_SIZE);

    if (files.length !== filesToUpload.length) {
      Alert.error(
        i18n.t('INVALID_PHOTO_SIZE'),
        { position: 'top-right', effect: 'slide', timeout: 3000 }
      );
    }

    onUpload({
      target: {
        files: filesToUpload,
      },
    });
  };

  return (
    <FileUploader
      multiple={true}
      handleChange={handleChange}
      types={fileTypes} name={'files'}>
      <div className={classes.uploadBox}>
        <h1>Drag&Drop</h1>
        <p className={classes.addPhotosSlogan}>{i18n.t('ADD_PHOTOS_BUTTON')}</p>
      </div>
    </FileUploader>
  );
};

FileUploadBox.propTypes = {
  onUpload: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(FileUploadBox);
