import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getObjectPhoto, IMAGE_SIZE } from '../../../../../../services/imageServise';
import ImageCarouselModal from 'realtor/components/common/ImageCarouselModal/ImageCarouselModal';

const styles = () => {
  return {
    previewMain: {
      width: '100%',
      '@media (max-width: 900px)': {
        padding: '0',
      },
    },
    previewMainImage: {
      width: '100%',
      cursor: 'zoom-in;',
    },
    subPreview: {
      marginTop: '1%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 900px)': {
        padding: '0',
      },
    },
    subPreviewImage: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '18%',
      height: '110px',
      cursor: 'zoom-in;',
      '@media (max-width: 3000px)': {
        height: '170px',
      },
      '@media (max-width: 2500px)': {
        height: '150px',
      },
      '@media (max-width: 2000px)': {
        height: '90px',
      },
      '@media (max-width: 1700px)': {
        height: '80px',
      },
      '@media (max-width: 1400px)': {
        height: '70px',
      },
      '@media (max-width: 1200px)': {
        height: '50px',
      },
      '@media (max-width: 600px)': {
        height: '70px',
      },
      '@media (max-width: 450px)': {
        height: '55px',
      },
      '@media (max-width: 390px)': {
        height: '40px',
      },
    },
    subPreviewRest: {
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, .5)',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2.5em',
    },
  };
};

export const RealEstateObjectDetailsImages = ({
  classes,
  realEstateObject,
}) => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);

  const handleOpenPhotoModal = () => setIsPhotoModalOpen(true);
  const handleClosePhotoModal = () => setIsPhotoModalOpen(false);

  const { photos } = realEstateObject;
  const photosByOrder = photos.sort((a, b) => a.order - b.order);
  const mainPhoto = getObjectPhoto(photosByOrder[0], IMAGE_SIZE.SMALL);

  let photoToShow;
  if (photos.length > 6) {
    photoToShow = photosByOrder.slice(1, 6);
  } else {
    photoToShow = photosByOrder.slice(1, photosByOrder.length);
  }
  const diff = photosByOrder.length - photoToShow.length - 1;

  return (
    <div>
      <div className={classes.previewMain}>
        <img
          src={mainPhoto}
          className={classes.previewMainImage}
          onClick={handleOpenPhotoModal}
        />
      </div>
      <div className={classes.subPreview}>
        {photoToShow.map((photo, index) =>
          (
            <div
              key={index}
              className={classes.subPreviewImage}
              onClick={handleOpenPhotoModal}
              style={
                { backgroundImage: `url(${getObjectPhoto(photo, IMAGE_SIZE.ORIGIN)})` }
              }
            >
              {index === photoToShow.length - 1 && diff && <div className={classes.subPreviewRest}>+{diff}</div>}
            </div>
          )
        )}
      </div>
      <ImageCarouselModal
        isOpen={isPhotoModalOpen}
        photos={photos}
        onClose={handleClosePhotoModal}
      />
    </div>
  );
};

RealEstateObjectDetailsImages.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsImages);
