import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';

import badMoodImage from '../../../../../../../../../public/images/bad_mood_smile.png';
import i18n from '../../../../../../../../common/i18n';

const NoClients = ({ classes }) => {
  return (
    <div className={classes.emptyStateBox}>
      <div className={classes.emptyStateMarker}>
        <img width={'100%'} src={badMoodImage}/>
      </div>
      <Typography className={classes.noClientsText}>{i18n.t('NO_CLIENTS')}</Typography>
      <Link className={classes.wontAddClientText} to="/clients/create" component={RouterLink}>
        {i18n.t('WANT_ADDED_FIRST')}
      </Link>
    </div>
  );
};

NoClients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default NoClients;
