import { connect } from 'react-redux';

import MyObjectsPage from '../../components/pages/RealEstateObjectPages/MyObjectsPage/MyObjectsPage';
import { deleteObject, fetchMyObjects, fetchTagsList, getMyRealEstateObjectTotalCount } from 'realtor/actions';

const mapStateToProps = (state) => {
  const { appConfigurations, myObjectsList, tag, realEstateObjectsTotalCount } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    exchangeRates: appConfigurations.exchangeRates,
    objectList: myObjectsList.list,
    totalCount: myObjectsList.totalCount,
    inProgress: myObjectsList.inProgress,
    listInProgress: myObjectsList.listInProgress,
    citiesList: appConfigurations.citiesList,
    isOwnerList: true,
    tagsList: tag.tagsList,
    totalCountInProgress: realEstateObjectsTotalCount.inProgress,
    objectsWithoutFiltersTotalCount: realEstateObjectsTotalCount.totalCount,
  };
};

const mapDispatchToProps = {
  fetchMyObjects,
  fetchTagsList,
  onDelete: deleteObject,
  getMyRealEstateObjectTotalCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyObjectsPage);
