import {
  FETCH_MY_REAL_ESTATE_OBJECT_START,
  FETCH_MY_REAL_ESTATE_OBJECT_FAIL,
  FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, ADD_OBJECT_IMAGES_SUCCESS,
} from '../actions';
import { DELETE_OBJECT_IMAGE_SUCCESS } from '../../actions/object';

const initialState = {
  inProgress: false,
  error: null,
  realEstateObject: null,
};

export const realEstateObject = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_MY_REAL_ESTATE_OBJECT_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };
  case FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS:
    return {
      ...state,
      realEstateObject: action.realEstateObject,
      inProgress: false,
    };
  case FETCH_MY_REAL_ESTATE_OBJECT_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };
  case ADD_OBJECT_IMAGES_SUCCESS: {
    state.realEstateObject.photos = [...state.realEstateObject.photos, ...action.payload];

    return {
      ...state,
      realEstateObject: Object.create(
        Object.getPrototypeOf(state.realEstateObject),
        Object.getOwnPropertyDescriptors(state.realEstateObject)
      ),
      inProgress: false,
    };
  }
  case DELETE_OBJECT_IMAGE_SUCCESS:
    state.realEstateObject.photos = state.realEstateObject.photos.filter(({ id }) => id !== action.id);

    return {
      ...state,
      realEstateObject: Object.create(
        Object.getPrototypeOf(state.realEstateObject),
        Object.getOwnPropertyDescriptors(state.realEstateObject)
      ),
      inProgress: false,
    };
  default:
    return state;
  }
};
