import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import style from './style';
import PropTypes from 'prop-types';
import CityField from './CityField';
import DistrictField from './DistrictField';
import { CityService } from '../../../../services/CityService';

const DEFAULT_GRID_PARAMS = {
  sm: 12,
  md: 6,
  lg: 6,
  xl: 4,
  xs: 4,
};

const Location = ({
  cityError,
  onDistrictChange,
  selectedDistrict,
  regions,
  cityId,
  districtError,
  classes,
  cityHeader,
  districtHeader,
  gridParams,
  onCityChange,
}) => {
  const [districtsList, setDistrictsList] = useState([]);

  useEffect(() => {
    if (regions && cityId) {
      const city = CityService.getCityFormRegions(regions, cityId);
      setDistrictsList(city.districts);
    }
  }, [regions, cityId]);

  const getGridParams = () => {
    return gridParams || DEFAULT_GRID_PARAMS;
  };

  const handleCityChange = (location) => {
    let districtsList = [];

    if (location) {
      const region = regions.find(({ name }) => name === location.region);
      const city = region.cities.find(({ name }) => name === location.city);
      if (location.withDistricts) {
        districtsList = get(city, 'districts.length', []);
      }
    } else {
      onDistrictChange(null);
    }
    setDistrictsList(districtsList);
    return onCityChange(location);
  };

  const showDistrictsList = cityId && !!districtsList.length;

  return (
    <Fragment>
      <Grid className={classes.locationTextField} item {...getGridParams()}>
        {regions && <CityField
          regions={regions}
          cityId={cityId}
          onCityChange={handleCityChange}
          error={cityError}
          cityHeader={cityHeader}
        />}
      </Grid>
      {showDistrictsList &&
        <Grid className={classes.locationTextField} item {...getGridParams()}>
          <DistrictField
            districtsList={districtsList}
            onDistrictChange={onDistrictChange}
            selectedDistrict={selectedDistrict}
            error={districtError}
            districtHeader={districtHeader}
          />
        </Grid>
      }
    </Fragment>
  );
};

export default withStyles(style)(Location);

Location.propTypes = {
  regions: PropTypes.array,
  cityId: PropTypes.number,
  onCityChange: PropTypes.func,
  onDistrictChange: PropTypes.func,
  selectedDistrict: PropTypes.number,
  cityError: PropTypes.string,
  districtError: PropTypes.string,
  gridParams: PropTypes.object,
  classes: PropTypes.object,
  cityHeader: PropTypes.string,
  districtHeader: PropTypes.string,
  errors: PropTypes.object,
};
