import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from '../styles';
import { BaseParams } from './BaseParams';
import { Grid } from '@material-ui/core';
import { AreaRangeFilter } from 'realtor/components/layears/RangeFilters';

export class LandPlotParams extends BaseParams {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderArea() {
    const { onSelect, filter, errors } = this.props;

    return (
      <AreaRangeFilter
        filter={filter}
        onSelect={onSelect}
        errors={errors}
      />
    );
  }

  renderFullParamsList() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid item sm={6} xs={12} md={12} lg={12}>
          { this.renderCitiesFilter() }
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          { this.renderArea() }
        </Grid>
        <Grid sm={12}>
          { this.renderCurrency() }
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          { this.renderTotalPriceRange() }
          { this.landAreaPriceRange() }
        </Grid>
        { this.renderDescription() }
      </Fragment>
    );
  }

  render() {
    const { filter } = this.props;
    return (
      <Grid container>
        <Grid sm={12}>
          { this.renderPartialServiceType() }
        </Grid>
        { filter.objectPartialServiceType && this.renderFullParamsList() }
      </Grid>
    );
  }
}

export default withStyles(styles)(LandPlotParams);

LandPlotParams.propTypes = {
  filter: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
