import axios from '../../common/axios';

class App {
  /**
     * Fetch app configuration data
     */
  fetchConfiguration(data) {
    return axios.get('/v1/app/params', data)
      .then((res) => res.data);
  }
}

export default new App();
