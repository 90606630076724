export default (theme) => {
  return {
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    backToSelect: {
      cursor: 'pointer',
    },
    container: {
      height: '100%',
      maxWidth: '420px',
    },
    submit: {
      margin: `${theme.spacing(3)}px 0px`,
      padding: `${theme.spacing(1.5)}px 0px`,
      background: '#F5841F',
      borderRadius: theme.spacing(3),
    },
    clientForm: {
      maxWidth: '420px',
    },
    '@global': {
      '.MuiOutlinedInput-input': {
        paddingTop: `${theme.spacing(1)}px!important`,
        paddingBottom: `${theme.spacing(1)}px!important`,
      },
    },
  };
};
