import { BaseRealEstateObject } from './BaseRealEstateObject';

export class House extends BaseRealEstateObject {
  get objectServiceType() {
    return this.props.objectServiceType;
  }

  set objectServiceType(objectServiceType) {
    this.props.objectServiceType = objectServiceType;
  }

  get landArea() {
    return this.props.landArea;
  }

  set landArea(landArea) {
    this.props.landArea = landArea;
  }

  get livingArea() {
    return this.props.livingArea;
  }

  set livingArea(livingArea) {
    this.props.livingArea = livingArea;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  set area(area) {
    this.props.area = area;
  }

  get area() {
    return this.props.area;
  }

  get kitchenArea() {
    return this.props.kitchenArea;
  }

  set kitchenArea(kitchenArea) {
    this.props.kitchenArea = kitchenArea;
  }

  get objectMaterial() {
    return this.props.objectMaterial;
  }

  set objectMaterial(objectMaterial) {
    this.props.objectMaterial = objectMaterial;
  }

  get flooring() {
    return this.props.flooring;
  }
  set flooring(flooring) {
    this.props.flooring = flooring;
  }

  get objectCondition() {
    return this.props.objectCondition;
  }

  set objectCondition(objectCondition) {
    this.props.objectCondition = objectCondition;
  }

  get squarePrice() {
    return this.props.squarePrice;
  }

  set squarePrice(squarePrice) {
    this.props.squarePrice = squarePrice;
  }

  get roomsCount() {
    return this.props.roomsCount;
  }

  set roomsCount(roomsCount) {
    this.props.roomsCount = roomsCount;
  }

  get isMortgage() {
    return this.props.isMortgage;
  }

  set isMortgage(isMortgage) {
    this.props.isMortgage = isMortgage;
  }
  /* eslint-disable complexity */
  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      subtype: this.props.objectSubtype ? Number(this.props.objectSubtype) : null,
      serviceType: this.props.objectServiceType ? Number(this.props.objectServiceType) : null,
      area: this.props.area ? Number(this.props.area) : null,
      flooring: this.props.flooring ? Number(this.props.flooring) : null,
      squarePrice: this.props.squarePrice ? Number(this.props.squarePrice) : null,
      condition: this.props.objectCondition ? Number(this.props.objectCondition) : null,
      material: this.props.objectMaterial ? Number(this.props.objectMaterial) : null,
      roomsCount: this.props.roomsCount ? Number(this.props.roomsCount) : null,
      landArea: this.props.landArea ? Number(this.props.landArea) : null,
      livingArea: this.props.livingArea ? Number(this.props.livingArea) : null,
      kitchenArea: this.props.kitchenArea ? Number(this.props.kitchenArea) : null,
      isMortgage: Boolean(this.props.isMortgage),
    };
  }
}
