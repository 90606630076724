export default (theme) => {
  return {
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    clientBlock: {
      maxWidth: '500px',
    },
    '@media (max-width: 900px)': {
      paper: {
        paddingBottom: theme.spacing(4),
      },
    },
  };
};
