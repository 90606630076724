import { BaseValidator } from './BaseValidator';

export class CommercialValidator extends BaseValidator{
  validate() {
    const errors = this.initBaseValidation();

    errors.floor = this.validateFloorRange();

    errors.squarePrice = this.validateSquarePrice();

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceTo);
    }

    if (!errors.floor) {
      errors.floorFrom = this.validateMaxValue(this.realEstateFilter.floorFrom);
      errors.floorTo = this.validateMaxValue(this.realEstateFilter.floorTo);
    }

    return errors;
  }
}
