export default (theme) => {
  return {
    rangeTextField: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    rangeTextInputBox: {
      maxWidth: '100%',
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    rangeTextFieldTextInput: {
      marginLeft: theme.spacing(1),
      width: theme.spacing(15),
    },
    dash: {
      width: theme.spacing(2),
      borderBottom: '1px solid #C4C4C4',
      margin: `0px ${theme.spacing(1)}px`,
    },
    errorText: {
      color: '#f44336',
    },
    fieldErrorText: {
      position: 'absolute',
      top: theme.spacing(4.5),
    },
  };
};
