import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import cn from 'classnames';

const AppStyledSwitch = withStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: '#F5841F',
    },
    '&$checked + $track': {
      backgroundColor: '#F5841F',
    },
  },
  checked: {},
  track: {},
  box: {
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}))(Switch);

const componentStyles = (theme) => ({
  box: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(1.5),
  },
  formControl: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  asButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 14%)',
    borderRadius: '8px',
    padding: '8px 16px',
    marginTop: 0,
    '& label': {
      marginBottom: '0px',
    },
  },
});

const CustomSwitch = ({ classes, label, checked, onChange, name, labelPlacement, asButton, disabled }) => {
  const handleChange = ({ target }) => {
    return onChange(target.name, target.checked);
  };

  const boxClasses = cn({
    [classes.box]: true,
    [classes.asButton]: asButton,
  });

  return (
    <div className={boxClasses}>
      <FormControlLabel
        control={<AppStyledSwitch />}
        label={label}
        checked={checked}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        className={classes.formControl}
        labelPlacement={labelPlacement || 'end'}
      />
    </div>
  );
};

CustomSwitch.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
  asButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(componentStyles)(CustomSwitch);
