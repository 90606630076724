export default (theme) => {
  return {
    location: {
      display: 'flex',
      alignItems: 'center',
      margin: `${theme.spacing(2)}px 0`,
    },
    locationIcon: {
      color: '#C4C4C4',
    },
  };
};
