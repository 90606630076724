import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import i18n from '../../../../../common/i18n';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const FiltersDrawerComponent = ({
  classes,
  isFiltersOpen,
  onFiltersToggle,
  typeFilterComponent,
  filtersListByTypeComponent,
  onConfirm,
  onReset,
}) => {
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isFiltersOpen,
        [classes.drawerClose]: !isFiltersOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isFiltersOpen,
          [classes.drawerClose]: !isFiltersOpen,
          [classes.navigationBox]: true,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <div className={classes.toolbarHeader}>
          <h3 className={classes.toolbarHeaderTitle}>
            {i18n.t('FILTERS')}
          </h3>
          <CloseIcon
            className={classes.closeButton}
            onClick={onFiltersToggle}
          />
        </div>
        <div>
          {i18n.t('OBJECT_PARAMS')}
        </div>
        <div className={classes.filterList}>
          {typeFilterComponent}
          {filtersListByTypeComponent}
        </div>
        <div className={classes.filterControl}>
          <Button
            color={'primary'}
            type={'submit'}
            className={classes.submit}
            onClick={onConfirm}
          >
            {i18n.t('APPLY_FILTERS')}
          </Button>
          <Button
            className={classes.cancel}
            onClick={onReset}
          >
            {i18n.t('RESET_FILTERS')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const FilterDrawer = withStyles(styles)(FiltersDrawerComponent);

FiltersDrawerComponent.propTypes = {
  classes: PropTypes.object,
  isFiltersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  typeFilterComponent: PropTypes.object,
  filtersListByTypeComponent: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default FilterDrawer;
