import { BaseValidator } from './BaseValidator';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../../constants/validation';

export class HouseFilterValidator extends BaseValidator{
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.landArea = this.validateRange(
      this.filter.landAreaFrom,
      this.filter.landAreaTo
    );

    errors.flooring = this.validateFlooringRange();

    errors.kitchenArea = this.validateKitchenArea();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.livingArea = this.validateLivingArea();

    errors.objectServiceType = FormValidation.validateRequired(this.filter.objectServiceType);

    errors.city = FormValidation.validateRequired(this.filter.cityId);
    errors.description = FormValidation.validateLength(this.filter.description, 0, NOTES_AND_DESCRIPTION_LENGTH);

    if (!errors.area) {
      errors.areaFrom = this.validateMaxValue(this.filter.areaFrom);
      errors.areaTo = this.validateMaxValue(this.filter.areaTo);
    }

    if (!errors.landArea) {
      errors.landAreaFrom = this.validateMaxValue(this.filter.landAreaFrom);
      errors.landAreaTo = this.validateMaxValue(this.filter.landAreaTo);
    }

    if (!errors.kitchenArea) {
      errors.kitchenAreaFrom = this.validateMaxValue(this.filter.kitchenAreaFrom);
      errors.kitchenAreaTo = this.validateMaxValue(this.filter.kitchenAreaTo);
    }

    if (!errors.livingArea) {
      errors.livingAreaFrom = this.validateMaxValue(this.filter.livingAreaFrom);
      errors.livingAreaTo = this.validateMaxValue(this.filter.livingAreaTo);
    }

    if (!errors.flooring) {
      errors.flooringFrom = this.validateMaxValue(this.filter.flooringFrom);
      errors.flooringTo = this.validateMaxValue(this.filter.flooringTo);
    }

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.filter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.filter.squarePriceTo);
    }

    if (!errors.totalPrice) {
      const requiredError = FormValidation.validateRequired(this.filter.totalPriceTo);

      errors.totalPriceFrom = this.validatePriceWithCurrency(this.filter.totalPriceFrom);
      errors.totalPriceTo = requiredError ? requiredError : this.validatePriceWithCurrency(this.filter.totalPriceTo);
    }
    return errors;
  }
}
