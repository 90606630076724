import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Grid, TextareaAutosize } from '@material-ui/core';

import styles from '../styles';
import i18n from '../../../../../../common/i18n';
import Location from '../../../common/Location/Location';
import SelectButtonByFilter from '../../../common/SelectButtonByFilter/SelectButtonByFilter';
import CustomSwitch from '../../../common/CustomSwitch/CustomSwitch';
import {
  AreaFilter, FloorFilter, FlooringFilter, KitchenAreaFilter, LandAreaFilter, LandAreaPriceFilter, LivingAreaFilter,
  SquarePriceFilter,
  TotalPriceFilter,
} from 'realtor/components/layears/Filters';

import {
  VirtualTourLink,
  TitleInput,
} from 'realtor/components/forms/CreateRealEstateObjectForm/RealEstateObjectParams/components';

export class BaseParams extends PureComponent {

  renderTotalPrice() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<TotalPriceFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderAreaPrice() {
    const { realEstateObject, onInputChange, errors } = this.props;

    const isDisabled = Boolean(realEstateObject.totalPrice) && Boolean(realEstateObject.area);

    return (<LandAreaPriceFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
      disabled={isDisabled}
    />);
  }

  renderSquarePrice(placeholder) {
    const { realEstateObject, onInputChange, errors } = this.props;

    const isDisabled = Boolean(realEstateObject.totalPrice) && Boolean(realEstateObject.area);

    return (<SquarePriceFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
      placeholder={placeholder}
      disabled={isDisabled}
    />);
  }

  renderTotalArea(placeholder) {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<AreaFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
      placeholder={placeholder}
    />);
  }

  renderFloor() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<FloorFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderFlooring() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<FlooringFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderLivingArea() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<LivingAreaFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderLandArea() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<LandAreaFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderKitchenArea() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<KitchenAreaFilter
      filter={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
      isTitleLabel={true}
    />);
  }

  renderTitle() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<TitleInput
      realEstateObject={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
    />);
  }

  virtualTourLink() {
    const { realEstateObject, onInputChange, errors } = this.props;

    return (<VirtualTourLink
      realEstateObject={realEstateObject}
      filterErrors={errors}
      onInputChange={onInputChange}
    />);
  }

  renderCitiesFilter() {
    const { citiesList, onCityChange, realEstateObject, onDistrictChange, errors } = this.props;

    return (
      <Fragment>
        <h3>{i18n.t('MAIN_INFORMATION')}</h3>
        <Grid container xs={12} lg={6} sm={12}>
          <Location
            regions={citiesList}
            onCityChange={onCityChange}
            onDistrictChange={onDistrictChange}
            cityId={realEstateObject.cityId}
            selectedDistrict={realEstateObject.districtId}
            gridParams={{
              sm: 12,
              md: 6,
              lg: 6,
              xl: 6,
              xs: 12,
            }}
            cityError={errors.city}
          />
        </Grid>
      </Fragment>
    );
  }

  renderPartialServiceType() {
    const { filtersList, onSelect, realEstateObject, errors, disableServiceType } = this.props;
    const filterName = 'objectPartialServiceType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_SERVICE_TYPE');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onSelect}
        errors={errors}
        disabled={disableServiceType}
      />
    );
  }

  renderServiceType() {
    const { filtersList, realEstateObject, errors, onFilterServiceSelect, disableServiceType } = this.props;
    const filterName = 'objectServiceType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_SERVICE_TYPE');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onFilterServiceSelect}
        errors={errors}
        disabled={disableServiceType}
      />
    );
  }

  renderMaterial() {
    const { filtersList, onSelect, realEstateObject } = this.props;
    const filterName = 'objectMaterial';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_MATERIAL_';
    const header = i18n.t('FILTER_REAL_ESTATE_MATERIAL');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onSelect}
        isMulti={false}
      />
    );
  }

  renderRoomsCount() {
    const { filtersList, onSelect, realEstateObject } = this.props;
    const filterName = 'roomsCount';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_ROOMS_COUNT_';
    const header = i18n.t('FILTER_REAL_ESTATE_ROOMS_COUNT');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onSelect}
      />
    );
  }

  renderDescription() {
    const { onSelect, realEstateObject, errors, classes } = this.props;
    return (
      <Fragment>
        <FormControl fullWidth margin={'normal'}>
          <label>{i18n.t('CLIENT_PARAMS_DESCRIPTION')}</label>
          <TextareaAutosize
            helperText={errors.description}
            error={!!errors.description}
            className={!!errors.description ? classes.descriptionAreaError : ''}
            rowsMin={5}
            onChange={(event) => {
              const { name, value } = event.target;
              return onSelect(name, value);
            }}
            value={realEstateObject.description}
            name={'description'}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className={classes.errorText}>{errors.description}</FormHelperText>
        </FormControl>
      </Fragment>
    );
  }

  renderNotes() {
    const { onSelect, realEstateObject, errors, classes } = this.props;
    return (
      <Fragment>
        <FormControl fullWidth margin={'normal'}>
          <label>{i18n.t('CLIENT_PARAMS_NOTES')}</label>
          <TextareaAutosize
            helperText={errors.notes}
            error={!!errors.notes}
            className={!!errors.notes ? classes.descriptionAreaError : ''}
            rowsMin={5}
            onChange={(event) => {
              const { name, value } = event.target;
              return onSelect(name, value);
            }}
            value={realEstateObject.notes}
            name={'notes'}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className={classes.errorText}>{errors.notes}</FormHelperText>
        </FormControl>
      </Fragment>
    );
  }

  renderCondition() {
    const { filtersList, onSelect, realEstateObject } = this.props;
    const filterName = 'objectCondition';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_FLOR_CONDITION_';
    const header = i18n.t('FILTER_REAL_ESTATE_FLOR_CONDITION');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onSelect}
        isMulti={false}
      />
    );
  }

  renderCurrency() {
    const { filtersList, onSelect, realEstateObject } = this.props;
    const filterName = 'currency';
    const buttonTitlePrefix = 'CURRENCY_';
    const header = i18n.t('CURRENCY');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onSelect}
      />
    );
  }

  renderParamsOnlyForBuyService() {
    const { realEstateObject, filtersList, onSelect } = this.props;

    const paramName = 'isMortgage';

    return (realEstateObject.objectServiceType === filtersList.objectServiceType.BUY &&
      <Grid sm={12} md={12} xs={12}>
        <CustomSwitch
          checked={realEstateObject[paramName]}
          label={i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE')}
          onChange={onSelect}
          name={paramName}
        />
      </Grid>);
  }
}

export default withStyles(styles)(BaseParams);

BaseParams.propTypes = {
  realEstateObject: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onFilterServiceSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
