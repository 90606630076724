import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import cn from 'classnames';

import i18n from '../../../../../../../common/i18n';

export const SearchTag = ({
  onLoadTags,
  list,
  isLoading,
  classes,
  onSelectTag,
  assignedTags,
  searchTag,
  onAddNewTag,
}) => {
  const renderExistTagsList = () => {

    return (
      <InfiniteScroll
        className={classes.tagsInfiniteScrollBox}
        next={onLoadTags}
        hasMore={true}
        dataLength={list.length}
        scrollableTarget="scrollableDiv"
        loader={
          isLoading &&
          (
            <div className="loader" key="loader">
              Loading ...
            </div>
          )
        }
      >
        {list.map((tag, index) => {
          const handleTagSelect = (e) => {
            onSelectTag(tag);
            e.preventDefault();
          };

          const isTagChecked = !!Array.from(assignedTags).find((assignedTag) => {
            return assignedTag.id === tag.id;
          });

          const countOfUseView = tag.countOfUse ? `(${tag.countOfUse})` : '';
          const visibleTagTitle = `${tag.title}  ${countOfUseView}`;

          return (
            <FormControlLabel
              className={classes.tagsInfiniteScrollItem}
              onClick={handleTagSelect}
              key={index}
              control={
                <Checkbox
                  checked={isTagChecked}
                  name="checkedB"
                  color="primary"
                />
              }
              label={visibleTagTitle}
            />
          );
        })}
      </InfiniteScroll>
    );
  };

  const renderCreateNewTag = () => {
    const addBlockClasses = cn({
      [classes.tagsNotFoundItem]: true,
      [classes.tagsNotFoundAddTag]: true,
    });

    return (
      <div className={classes.tagsNotFoundBox}>
        <div className={classes.tagsNotFoundItem}>
          <span>{i18n.t('NOT_FOUND_RESULT')}</span>
        </div>
        <div onClick={onAddNewTag} className={addBlockClasses}>
          <span>{i18n.t('ADD_NEW_TAG')} <b>&quot;{searchTag}&quot;</b></span>
          <AddIcon />
        </div>
      </div>
    );
  };

  return (
    <div
      id="scrollableDiv"
      className={classes.tagsInfiniteScroll}
    >
      { list.length ? renderExistTagsList() : renderCreateNewTag() }
    </div>
  );
};

SearchTag.propTypes = {
  isLoading: PropTypes.object.isRequired,
  onLoadTags: PropTypes.func.isRequired,
  onSelectTag: PropTypes.func.isRequired,
  onAddNewTag: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  assignedTags: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  searchTag: PropTypes.string.isRequired,
};
