export default (theme) => {
  return {
    uploadBox: {
      maxWidth: '769px!important',
      marginTop: theme.spacing(4),
      border: '1px dashed #3694D1!important',
      height: '170px!important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
    },
    addPhotosSlogan: {
      textDecoration: 'underline',
      color: '#337ab7',
      marginTop: 0,
    },
  };
};
