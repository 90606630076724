import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import styles from './styles.js';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getObjectPhoto, IMAGE_SIZE } from '../../../../services/imageServise';

const ImageCarousel = ({ images = [], classes }) => {
  return (
    <Carousel
      showStatus={false}
      showThumbs={true}
      swipeable={true}
      showIndicators={false}
      className={classes.carouselClass}
      renderThumbs={(children) => {
        return children.map((child, index) =>
          <div key={index} className={classes.imageThumb}>{child}</div>
        );
      }}
    >
      {images.map((photo, i) => {
        return (
          <div className={classes.sliderImageBox} key={i}>
            {/* <div*/}
            {/*  className={classes.sliderImage}*/}
            {/*  style={*/}
            {/*    { backgroundImage: `url(${getObjectPhoto(photo, IMAGE_SIZE.ORIGIN)})`,*/}
            {/*    }}*/}
            {/* />*/}
            <img src={getObjectPhoto(photo, IMAGE_SIZE.ORIGIN)} alt=""/>
          </div>
        );
      })}
    </Carousel>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles(styles)(ImageCarousel);
