export default (theme) => {
  return {
    emptyStateBox: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyStateMarker: {
      display: 'block',
      width: theme.spacing(15),
      height: theme.spacing(15),
      backgroundColor: '#C4C4C4',
    },
    notFoundCode: {
      fontSize: '3em',
      marginTop: theme.spacing(3),
    },
    notFoundText: {
      padding: theme.spacing(2),
      fontSize: '2.3em',
    },
    backToMainPage: {
      fontSize: '1.4em',
    },
  };
};
