
const getDomainWitApiUrl = () => {
  const originLocation = location.origin;
  // Set local url
  let locationUrl = 'http://localhost:8080';

  if ((new RegExp('m2c.com')).test(originLocation)) {
    locationUrl = (new RegExp('.dev')).test(originLocation) ? originLocation : `${originLocation}/m2c-api`;
  }

  return locationUrl;
};

export const DOMAIN = getDomainWitApiUrl();

export const ORIGINAL_DOMAIN = location.origin;
