export default () => {
  return {
    addMoreTelephoneNumber: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
};
