const drawerWidth = 240;

export default (theme) => {
  return {
    mainNavItem: {
      padding: 0,
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      width: '90%',
      margin: '0 auto',
    },
    profileItem: {
    },
    profileContent: {
      '& span': {
        fontWeight: 'bold',
        color: '#fff',
      },
      '& p': {
        color: '#fff',
      },
    },
    mainNavItemSelected: {
      background: 'rgba(255, 255, 255, 0.2)',
      '&:before': {
        width: '3px',
        height: '100%',
        display: 'block',
        background: '#fff',
        position: 'absolute',
        content: '""',
        left: 0,
      },
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.2)',
      },
    },
    collapse: {
      marginTop: 'auto',
      width: '100%',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `${theme.spacing(12) + 1}px`,
      [theme.breakpoints.down('md')]: {
        width: `${theme.spacing(9) + 1}px`,
      },
    },
    navigationBox: {
      background: '#1B457D',
      color: 'rgba(255,255,255,0.8)',
      borderRadius: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px 0px`,
    },
    listIcon: {
      minWidth: theme.spacing(12),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        minWidth: theme.spacing(9),
      },
    },
    navigationItemIcon: {
      color: '#ffffff',
      opacity: '0.8',
    },
    '@media (max-width: 900px)': {
      drawerOpen: {
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        position: 'absolute',
        height: '100%',
        top: '0px',
        visibility: 'visible',
      },
      drawerClose: {
        transition: theme.transitions.create(['width', 'visibility'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        position: 'absolute',
        height: '100%',
        top: '0px',
        width: 0,
        visibility: 'hidden',
      },
    },
    addObjectButton: {
      background: '#e18230',
      borderRadius: theme.spacing(3),
      color: '#fff',
      fontWeight: 700,
      width: '90%',
      height: '30px',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      transition: '0.5s',
      marginBottom: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
        background: '#F5841F',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
        transition: '0.5s',
      },
    },
    createButtons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    link: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    addClientButton: {
      background: '#4c9dd2',
      borderRadius: theme.spacing(3),
      color: '#fff',
      fontWeight: 700,
      width: '90%',
      height: '30px',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      transition: '0.5s',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
        background: '#3694D1',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
        transition: '0.5s',
      },
    },
    smallerButton: {
      fontSize: '0px',
      width: '30px',
      minWidth: '30px',
      padding: 0,
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  };
};
