export class NullRealEstateObject {
  constructor(props) {
    this.props = props;
  }

  toServerEntity() {
    return this.props;
  }

  toUiEntity() {
    return this.props;
  }
}
