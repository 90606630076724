import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';

export const FloorRangeFilter = ({ onSelect, filter, errors }) => {
  return (
    <RangeTextInput
      valueFrom={filter.floorFrom}
      valueTo={filter.floorTo}
      nameFrom={'floorFrom'}
      nameTo={'floorTo'}
      onFieldChange={onSelect}
      label={i18n.t('FLOOR')}
      error={errors.floor}
      errorFrom={errors.floorFrom}
      errorTo={errors.floorTo}
    />
  );
};

FloorRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
