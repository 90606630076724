export default (theme) => {
  return {
    root: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      width: '100%',
      maxWidth: '100%',
      gap: '10%',
    },
    paramsTitle: {
      '& p': {
        position: 'relative',
        '&:before': {
          position: 'absolute',
          left: 0,
          bottom: '4px',
          display: 'block',
          width: '100%',
          borderBottom: '1px dotted #d2d2d2',
          content: '" "',
        },
        '& b': {
          position: 'relative',
          display: 'inline-block',
          paddingRight: theme.spacing(3),
          background: '#fafafa',
        },
      },
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  };
};
