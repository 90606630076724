import { ClientTelephoneNumber, TelephoneNumber } from '../../domain/valueObjects';

export class TelephoneNumberBuilder {
  static buildFromFormData(number, id = null) {
    const telephoneNumber = new TelephoneNumber();
    telephoneNumber.telephoneNumberFromFormData = number;
    telephoneNumber.id = id;

    return telephoneNumber;
  }

  static buildClientTelephoneNumberFromFormData(number, id = null, order, isPrimary) {
    const telephoneNumber = new ClientTelephoneNumber();
    telephoneNumber.telephoneNumberFromFormData = number;
    telephoneNumber.id = id;
    telephoneNumber.order = order;
    telephoneNumber.isPrimary = isPrimary;

    return telephoneNumber;
  }

  static buildFromServerData(number, id = null) {
    const telephoneNumber = new TelephoneNumber();
    telephoneNumber.telephoneNumberFromContract = number;
    telephoneNumber.id = id;

    return telephoneNumber;
  }
}
