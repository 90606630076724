import { get } from 'lodash';

import {
  FETCH_APP_CONFIGURATION_FAIL,
  FETCH_APP_CONFIGURATION_START,
  FETCH_APP_CONFIGURATION_SUCCESS,
} from '../actions/appConfiguration';

const initialState = {
  errorCodes: {},
  objectFilters: {},
  exchangeRates: [],
  inProgress: false,
  error: null,
  isMobile: window.innerWidth < 600,
};

// eslint-disable-next-line complexity
export default function appConfigurations(state = initialState, action) {
  switch (action.type) {
  case FETCH_APP_CONFIGURATION_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };
  case FETCH_APP_CONFIGURATION_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };
  case FETCH_APP_CONFIGURATION_SUCCESS:
    return {
      ...state,
      errorCodes: get(action, 'data.errorCodes', {}),
      objectFilters: get(action, 'data.objectFilters', {}),
      citiesList: get(action, 'data.locations', {}),
      exchangeRates: get(action, 'data.exchangeRates', {}),
      inProgress: false,
    };
  default:
    return state;
  }
}
