import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../../../../../common/i18n';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';

export const SaveWithoutClient = (
  {
    isDialogOpen,
    onDialogClose,
    onEditContinue,
    onSaveWithoutClient,
  }
) => {
  return (
    <Dialog
      open={isDialogOpen}
      keepMounted
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {i18n.t('ON_SAVE_WITHOUT_CLIENTS')}
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onEditContinue}
          style={{
            background: '#3694D1',
            color: '#FFF',
          }}
        >
          {i18n.t('ADD_CLIENTS')}
        </Button>
        <Button
          variant="contained"
          onClick={onSaveWithoutClient}
          style={{
            background: '#EC6C6C',
            color: '#FFF',
          }}
        >
          {i18n.t('SAVE_WITHOUT_CLIENTS')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SaveWithoutClient.propTypes = {
  onDialogClose: PropTypes.func,
  onEditContinue: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  onSaveWithoutClient: PropTypes.func,
};

export default SaveWithoutClient;
