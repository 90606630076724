import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import {
  Button,
  TextField,
  FormControl,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import PasswordField from '../../fields/PasswordField/PasswordField';
import InputMask from 'react-input-mask';
import Link from '@material-ui/core/Link';
import { SUPPORT_TELEPHONE_URL } from '../../../../constants/links';

export class LoginForm extends PureComponent {
  state = {
    values: {
      email: '',
      password: '',
    },
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors)) {
      return {
        ...state,
        errors: props.errors,
      };
    }
    return state;
  }

  handleSubmit = (e) => {
    const { login, onDropErrors } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const isFromValid = this.validateForm();
    isFromValid ? login(this.state.values) : onDropErrors();

    return false;
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    return this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  }

  validateForm = () => {
    const { password, telephoneNumber } = this.state.values;
    const errors = {};
    errors.telephoneNumber = FormValidation.validateTelephoneNumber(telephoneNumber);
    errors.password = FormValidation.validateRequired(password);
    errors.password = FormValidation.validateLength(password, 6);

    this.setState({
      ...this.state,
      errors,
    });
    return FormValidation.isFormValid(errors);
  }

  render() {
    const {
      classes,
      inProgress,
    } = this.props;
    const { values, errors } = this.state;

    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={this.handleSubmit}
        method={'POST'}
      >
        <FormControl fullWidth margin={'normal'}>
          <label htmlFor="outlined-adornment-telephoneNumber" >{i18n.t('TELEPHONE_NUMBER')}</label>
          <InputMask
            mask="+38(099) 999 99 99"
            onChange={this.handleFieldChange}
            value={values.telephoneNumber}
            alwaysShowMask
          >
            {() => (
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                name="telephoneNumber"
                type="test"
                id="telephoneNumber"
                helperText={errors.telephoneNumber}
                error={!!errors.telephoneNumber}
              />
            )}
          </InputMask>
        </FormControl>
        <PasswordField
          onFieldChange={this.handleFieldChange}
          error={errors.password}
          value={values.password}
        />
        <Link className={classes.supportLink} href={SUPPORT_TELEPHONE_URL} target="_blank">
          {i18n.t('FORGOT_PASS_OR_HAVE_PROBLEMS_CONTACT_US')}
        </Link>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={inProgress}
        >
          {i18n.t('SIGN_IN')}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(LoginForm);

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  login: PropTypes.func,
  onDropErrors: PropTypes.func,
  inProgress: PropTypes.bool,
};
