import i18n from '../../../../common/i18n';

export class Location {
  props = {
    city: null,
    district: null,
    region: null,
  }

  constructor(props) {
    this.props = props;
  }

  set city(city) {
    this.props.city = city;
  }

  get city() {
    return this.props.city;
  }

  set district(district) {
    this.props.district = district;
  }

  get district() {
    return this.props.district;
  }

  set region(region) {
    this.props.region = region;
  }

  get region() {
    return this.props.region;
  }

  getFilterListView() {
    const { city, district } = this.props;

    const districtSlug = district ? `${district.name} ${i18n.t('DISTRICT').toLowerCase()}, ` : '';
    const citySlug = city ? `м.${city.name} ` : '';

    return `${districtSlug}${citySlug}`;
  }
}
