import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = (theme) => {
  return {
    appBar: {
      boxShadow: 'none',
      padding: `${theme.spacing(3)}px 0px`,
    },
    appToolbar: {
      padding: 0,
    },
  };
};

export const RealtorPageHeader = ({
  children, classes,
}) => {
  return (
    <AppBar
      className={classes.appBar}
      color={'transparent'}
      position={'relative'}>
      <Toolbar className={classes.appToolbar}>{children}</Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(RealtorPageHeader);

RealtorPageHeader.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object,
};
