import React, { PureComponent } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

class FormHeaderLayer extends PureComponent {
  render() {
    const { classes, children, title } = this.props;

    return (
      <div className={classes.header}>
        <h1>{title}</h1>
        <Typography className={classes.formHeaderDescription} align={'center'}>
          {children}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FormHeaderLayer);

FormHeaderLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
};
