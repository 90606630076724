import { Button } from '@material-ui/core';
import i18n from '../../../../../../../common/i18n';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => {
  return {
    shareButton: {
      background: '#F5841F',
      borderRadius: theme.spacing(3),
      color: '#fff',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
      fontWeight: 700,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      opacity: 0.8,
      transition: '0.5s',
      '&:hover': {
        cursor: 'pointer',
        background: '#F5841F',
        opacity: 1,
        transition: '0.5s',
      },
    },
  };
};

export const ShareObjectButton = ({ onShareObjectsDialogOpen, classes }) => {
  return (
    <Button
      className={classes.shareButton}
      variant="contained"
      onClick={onShareObjectsDialogOpen}
    >
      {i18n.t('SHARE_REAL_ESTATE_OBJECT')}
    </Button>
  );
};

ShareObjectButton.propTypes = {
  onShareObjectsDialogOpen: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareObjectButton);
