export default (theme) => {
  return {
    objectCardBox: {
      overflow: 'auto',
    },
    tabContent: {
      flex: '0 1 auto',
      overflowY: 'auto',
    },
    createObjectButtonBlock: {
      paddingTop: theme.spacing(2),
    },
    createObjectButton: {
      marginLeft: theme.spacing(2),
      float: 'right',
    },
    filtersBlock: {
      overflowX: 'hidden',
    },
  };
};
