import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../../common/i18n';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientWithThisTelephoneNumberAlreadyExistDialog = (props) => {
  const handleRedirectToClient = () => {
    const linkToClient = `/clients/${props.client.id}/update`;

    window.open(linkToClient, '_blank' );
  };

  return (
    <Dialog
      open={props.isDeleteDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {i18n.t('CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST_TEXT')}
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleRedirectToClient}
          style={{
            background: '#F5841F',
            color: '#FFF',
          }}
        >
          {i18n.t('GO_TO_EXISTING_CLIENT')}
        </Button>
        <Button
          variant="contained"
          onClick={props.onDialogClose}
          style={{
            background: '#3694D1',
            color: '#FFF',
          }}
        >
          {i18n.t('UPDATE_CURRENT_CLIENT')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClientWithThisTelephoneNumberAlreadyExistDialog.propTypes = {
  onDialogClose: PropTypes.func,
  client: PropTypes.object,
  isDeleteDialogOpen: PropTypes.bool,
};

export default ClientWithThisTelephoneNumberAlreadyExistDialog;
