import { connect } from 'react-redux';

import CreateClientPage from '../../components/pages/ClientsPages/CreateClientPage/CreateClientPage';

import { createClient, createNewTag, dropCreatedTag, fetchTagsList, dropErrors, restoreClient } from '../../actions';

const mapStateToProps = (state) => {
  const { appConfigurations, client, tag } = state;

  return {
    appConfigurations,
    inProgress: client.inProgress,
    error: client.error,
    tag,
  };
};

const mapDispatchToProps = {
  onCreateClient: createClient,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
  dropErrors,
  restoreClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientPage);
