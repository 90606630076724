import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { chunk } from 'lodash';
import {
  getParamViewList,
} from './ClientListParamView/getParamViewList';
import { Grid } from '@material-ui/core';
import i18n from '../../../../../../../common/i18n';

const ClientListParamsBody = ({ classes, filter, paramsList }) => {
  const paramsViewList = getParamViewList(filter, paramsList);
  const renderBooleanParam = (param) => {
    return (
      <Grid item className={classes.paramsViewListColumn} md={12}>
        <b className={classes.paramsViewListColumnTitle}>{param.title}</b>
      </Grid>
    );
  };

  const renderParam = (param) => {
    return (
      <Fragment>
        <Grid item className={classes.paramsViewListColumn} md={5} xs={6}>
          <b className={classes.paramsViewListColumnTitle}>{param.title}:</b>
        </Grid>
        <Grid item className={classes.paramsViewListColumn} md={7} xs={6}><span>{param.value}</span></Grid>
      </Fragment>
    );
  };

  return (
    <div>
      {
        chunk(paramsViewList, 2)
          .map(
            (row, rowKey) => {
              return (
                <Grid key={rowKey} md={12} container>
                  { row.map((param, columnKey) => {
                    return (
                      <Grid item container md={6} key={columnKey}>
                        { param.value ? renderParam(param) : renderBooleanParam(param) }
                      </Grid>
                    );
                  }) }
                </Grid>
              );
            })
      }
      {filter.description &&
        <Grid md={12} className={classes.paramsViewListColumn} item>
          <b className={classes.paramsViewListColumnTitle}>{`${i18n.t('DESCRIPTION')}:`}</b>
          <p>{filter.description}</p>
        </Grid>
      }
    </div>);
};

ClientListParamsBody.propTypes = {
  filter: PropTypes.object.isRequired,
  paramsList: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ClientListParamsBody;
