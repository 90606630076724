export default () => {
  return {
    typeChip: {
      background: '#EC6C6C',
      color: '#ffffff',
      position: 'absolute',
      left: '8px',
      top: '8px',
      zIndex: 1,
    },
    typeChipStatic: {
      position: 'static',
    },
    chipHouse: {
      background: '#51BCA9',
    },
    chipCommercial: {
      background: '#A06EC7',
    },
    chipLand: {
      background: '#C7836E',
    },
    chipGarage: {
      background: '#529CC6',
    },
  };
};
