import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../../common/i18n';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteClientDialog = (props) => {
  const clientFullName = props.client ? props.client.fullName : '';

  return (
    <Dialog
      open={props.isDeleteDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {i18n.t('DELETE_CLIENT_CONFIRM_TEXT', { fullName: clientFullName })}
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.onDeleteConfirm}
          style={{
            background: '#EC6C6C',
            color: '#FFF',
          }}
        >
          {i18n.t('YES')}
        </Button>
        <Button
          variant="contained"
          onClick={props.onDialogClose}
          style={{
            background: '#3694D1',
            color: '#FFF',
          }}
        >
          {i18n.t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteClientDialog.propTypes = {
  onDialogClose: PropTypes.func,
  onDeleteConfirm: PropTypes.func,
  client: PropTypes.object,
  isDeleteDialogOpen: PropTypes.bool,
};

export default DeleteClientDialog;
