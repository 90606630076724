import Alert from 'react-s-alert';
import i18n from '../i18n';
import auth from '../sevices/auth';

/* eslint-disable no-console, max-statements, complexity, max-depth */
export default () => (next) => (action) => {
  try {
    const actionResult = next(action);
    const error = actionResult.err || actionResult.error || actionResult.errors;
    // Handling server errors
    if (error) {
      if (error.status === 401) {
        // Unauthorized and redirect to identity service login page
        auth.destroySession();
        window.location.href = '/';
      };
      console.log(error);
    }
    return actionResult;
  } catch (error) {
    console.error(i18n.t('caughtAnException'), error);
    Alert.error(
      `${i18n.t('caughtAnException')}. ${error}`,
      { position: 'top-right', effect: 'slide', timeout: 3000 }
    );
  }
};
