import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import {
  Card, CardActionArea, CardActions, CardContent, CardMedia,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { DOMAIN } from '../../../Config';
import i18n from '../../../common/i18n';

export class ObjectCard extends PureComponent {

  componentDidMount() {

  }

  render() {
    const {
      classes,
      title,
      thumbnail,
      url,
      code,
    } = this.props;

    const image = thumbnail || `${DOMAIN}/static/images/image-not-found.png`;

    return (
      <Card className={classes.root}>
        <CardActionArea>
          <Link className={classes.cardLink} to={url}>
            <CardMedia
              className={classes.media}
              image={image}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography className={classes.objectCardTitle} variant="h5" component="h2">
                {title}
              </Typography>
              <Typography>
                <span>{i18n.t('OBJECT_CODE')}: <b>{code}</b></span>
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          { this.props.actions }
        </CardActions>
      </Card>
    );
  };
};

export default withStyles(styles)(ObjectCard);

ObjectCard.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.element,
  title: PropTypes.string,
  code: PropTypes.number,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
};
