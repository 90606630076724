import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import PriceRangeInput from 'realtor/components/common/PriceRangeInput/PriceRangeInput';

export const TotalPriceRangeFilter = ({ onSelect, filter, errors }) => {
  const currencyPrefix = getCurrencyLabel(filter.currency);

  return (
    <PriceRangeInput
      valueFrom={filter.totalPriceFrom}
      valueTo={filter.totalPriceTo}
      nameFrom={'totalPriceFrom'}
      nameTo={'totalPriceTo'}
      onFieldChange={onSelect}
      label={i18n.t('TOTAL_PRICE')}
      placeholderFrom={getCurrencyLabel(filter.currency)}
      placeholderTo={getCurrencyLabel(filter.currency)}
      error={errors.totalPrice}
      currencyPrefix={currencyPrefix}
      errorFrom={errors.totalPriceFrom}
      errorTo={errors.totalPriceTo}
    />
  );
};

TotalPriceRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
