import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';

const FakeSearch = ({ classes, children }) => {
  return (<div className={classes.fakeSearch}><Search color={'disabled'} />{children}</div>);
};

export default withStyles(styles)(FakeSearch);

FakeSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};
