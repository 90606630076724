import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../../../../../../common/i18n';
import {
  getRealEstateConditionTranslation,
  getRealEstateMaterialTranslation,
  getRealEstateRoomsCountTranslation,
  getRealEstateServiceTypeTranslation,
  getRealEstateSubtypeTranslation,
} from 'realtor/helpers/objectParams';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import Grid from '@material-ui/core/Grid';

const styles = () => {
  return {
    rowLabel: {
      color: '#C1C1C1',
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '0',
    },
  };
};

export const RealEstateObjectDetailsParamsList = ({ classes, filters, realEstateObject }) => {
  const updateWithExistingParams = (params, realEstateObject, realEstateVisibleParams) => {
    const notEmptyValues = Object.keys(realEstateObject).filter((key) => {
      const currentVal = realEstateObject[key];
      return !(currentVal === null || currentVal === undefined);
    });

    notEmptyValues.forEach((value) => {
      if (realEstateVisibleParams[value]) params.push(realEstateVisibleParams[value]);
    });
  };
  // eslint-disable-next-line max-statements
  const customObjectParams = () => {
    const {
      subtype,
      type,
      roomsCount,
      material,
      condition,
      currency,
    } = realEstateObject;

    const blankSpace = '-';

    const currencyLabel = getCurrencyLabel(currency);

    const subtypeParam = {
      title: i18n.t('PARAMS_FILTER_SUBTYPE_TYPE'),
      value: getRealEstateSubtypeTranslation(filters, type, subtype),
    };
    const flooringParams = {
      title: i18n.t('FLOORING'),
      value: realEstateObject.flooring,
    };
    const livingAreaParam = {
      title: i18n.t('LIVING_AREA'),
      value: (
        <Fragment>
          {realEstateObject.livingArea}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const kitchenAreaParam = {
      title: i18n.t('KITCHEN_AREA'),
      value: (
        <Fragment>
          {realEstateObject.kitchenArea}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const squarePriceParam = {
      title: i18n.t('SQUARE_PRICE'),
      value: (
        <Fragment>
          {realEstateObject.squarePrice}
          <span>{currencyLabel}</span>
        </Fragment>
      ),
    };
    const roomsCountParam = {
      title: i18n.t('FILTER_REAL_ESTATE_ROOMS_COUNT'),
      value: getRealEstateRoomsCountTranslation(filters, roomsCount),
    };
    const materialParam = {
      title: i18n.t('FILTER_REAL_ESTATE_MATERIAL'),
      value: getRealEstateMaterialTranslation(filters, material),
    };
    const conditionParam = {
      title: i18n.t('FILTER_REAL_ESTATE_FLOR_CONDITION'),
      value: getRealEstateConditionTranslation(filters, condition),
    };
    const areaParam = {
      title: i18n.t('TOTAL_AREA'),
      value: (
        <Fragment>
          {realEstateObject.area}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const landAreaParam = {
      title: i18n.t('LAND_AREA'),
      value: (
        <Fragment>{realEstateObject.landArea || blankSpace}{' '}
          <span>{i18n.t('AREA_PLACEHOLDER')}</span>
        </Fragment>
      ),
    };
    const landAreaPriceParam = {
      title: i18n.t('LAND_AREA_PRICE_PLACEHOLDER'),
      value: (
        <Fragment>{realEstateObject.landAreaPrice || blankSpace}{' '}
          <span>{currencyLabel}</span>
        </Fragment>
      ),
    };
    const mortgageParam = {
      title: i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE'),
      value:
  <Fragment>
    {realEstateObject.isMortgage ?
      i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE_YES') :
      i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE_NO')}
  </Fragment>,
    };
    const floorWithFlooringParam = {
      title: `${i18n.t('FLOOR')} / ${i18n.t('FLOORING')}`,
      value: `${realEstateObject.floor}/${realEstateObject.flooring}`,
    };

    switch (realEstateObject.objectType) {

    case filters.objectType.HOUSE_OR_COUNTRY_HOUSE: {
      const params = [subtypeParam];
      const houseParams = {
        livingArea: livingAreaParam,
        kitchenArea: kitchenAreaParam,
        landArea: landAreaParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        material: materialParam,
        condition: conditionParam,
        isMortgage: mortgageParam,
        floor: floorWithFlooringParam,
        area: areaParam,
        flooring: flooringParams,
      };

      updateWithExistingParams(params, realEstateObject, houseParams);

      return params;
    }
    case filters.objectType.APARTMENT: {
      const params = [subtypeParam];
      const apartmentParams = {
        livingArea: livingAreaParam,
        kitchenArea: kitchenAreaParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        material: materialParam,
        condition: conditionParam,
        isMortgage: mortgageParam,
        floor: floorWithFlooringParam,
        area: areaParam,
      };

      updateWithExistingParams(params, realEstateObject, apartmentParams);

      return params;
    }
    case filters.objectType.COMMERCIAL: {
      const params = [subtypeParam];

      const commercialParams = {
        kitchenArea: kitchenAreaParam,
        material: materialParam,
        squarePrice: squarePriceParam,
        roomsCount: roomsCountParam,
        condition: conditionParam,
        floor: floorWithFlooringParam,
        area: areaParam,
      };

      updateWithExistingParams(params, realEstateObject, commercialParams);

      return params;
    }
    case filters.objectType.LAND_PLOT: {
      const params = [subtypeParam];

      const landPlotParams = {
        area: areaParam,
        landAreaPrice: landAreaPriceParam,
        landArea: landAreaParam,
      };

      updateWithExistingParams(params, realEstateObject, landPlotParams);

      return params;
    }
    case filters.objectType.GARAGE_PARKING: {
      const params = [subtypeParam];

      const garageParkingParams = {
        material: materialParam,
        area: areaParam,
      };

      updateWithExistingParams(params, realEstateObject, garageParkingParams);

      return params;
    }
    default:
      return [];
    }
  };
  const getObjectParams = () => {
    const { objectServiceType, objectPartialServiceType } = realEstateObject;

    const params = [];
    params.push({
      title: i18n.t('PARAMS_FILTER_SERVICE_TYPE'),
      value: getRealEstateServiceTypeTranslation(filters, objectPartialServiceType || objectServiceType),
    });
    return [
      ...params,
      ...customObjectParams(),
    ];
  };

  const renderObjectParam = (title, value, index) => {
    return (
      <Grid key={index} item sm={12} md={11} lg={6} xl={6}>
        <p className={classes.rowLabel}>{title}</p>
        <p>{value}</p>
      </Grid>
    );
  };

  const params = getObjectParams();

  return params
    .filter(({ value }) => Boolean(value))
    .map(({ title, value }, index) => {
      return renderObjectParam(title, value, index);
    });
};

RealEstateObjectDetailsParamsList.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsParamsList);
