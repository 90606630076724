import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from '../styles';
import CommercialParams from './CommercialParams';
import ApartmentParams from './ApartmentParams';
import LandPlotParams from './LandPlotParams';
import GarageParkingParams from './GarageParkingParams';
import HouseParams from './HouseParams';

export const RealEstateParamsFactory = (props) => {
  const {
    filtersList,
    realEstateObject,
  } = props;

  switch (realEstateObject.objectType) {
  case filtersList.objectType.COMMERCIAL:
    return (<CommercialParams
      { ...props }
    />);
  case filtersList.objectType.APARTMENT:
    return (<ApartmentParams
      { ...props }
    />);
  case filtersList.objectType.LAND_PLOT:
    return (<LandPlotParams
      { ...props }
    />);
  case filtersList.objectType.GARAGE_PARKING:
    return (<GarageParkingParams
      { ...props }
    />);
  case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
    return (<HouseParams
      { ...props }
    />);
  default:
    return (<div></div>);
  }
};

export default withStyles(styles)(RealEstateParamsFactory);

RealEstateParamsFactory.propTypes = {
  realEstateObject: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
