import TagResource from 'realtor/resourses/Tag.resource';
import { Tag } from 'realtor/domain';

export const DEFAULT_LOAD_TAGS_LIMIT = 10;
export const DEFAULT_LOAD_TAGS_OFFSET = 0;
export const DEFAULT_LOAD_LIST_PARAMS = {
  limit: DEFAULT_LOAD_TAGS_LIMIT,
  offset: DEFAULT_LOAD_TAGS_OFFSET,
  search: '',
};

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_FAIL = 'FETCH_TAGS_FAIL';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';

export const CREATE_TAGS_START = 'CREATE_TAGS_START';
export const CREATE_TAGS_FAIL = 'CREATE_TAGS_FAIL';
export const CREATE_TAGS_SUCCESS = 'CREATE_TAGS_SUCCESS';
export const DROP_CREATED_TAG = 'DROP_CREATED_TAG';

const createTagSuccess = (tag) => {
  return { type: CREATE_TAGS_SUCCESS, tag };
};
const fetchTagsListStart = () => {
  return { type: FETCH_TAGS_START };
};

const fetchTagsListFail = () => {
  return { type: FETCH_TAGS_FAIL };
};

const fetchTagsListSuccess = (tagsList) => {
  return { type: FETCH_TAGS_SUCCESS, tagsList: tagsList.map((tagData) => new Tag(tagData)) };
};

const dropCreatedTagSuccess = () => {
  return { type: DROP_CREATED_TAG };
};

export const fetchTagsList = (params) => {
  return (dispatch) => {
    dispatch(fetchTagsListStart());
    TagResource.getList(params)
      .then((response) => {
        dispatch(fetchTagsListSuccess(response));
      })
      .catch((error) => {
        fetchTagsListFail(error);
      });
  };
};

export const createNewTag = (tagName) => {
  return (dispatch) => {
    dispatch(fetchTagsListStart());
    TagResource.createTag(tagName)
      .then((tag) => {
        dispatch(createTagSuccess(tag));
        TagResource.getList(DEFAULT_LOAD_LIST_PARAMS)
          .then((response) => {
            dispatch(fetchTagsListSuccess(response));
          });
      })
      .catch((error) => {
        dispatch(fetchTagsListFail(error));
      });
  };
};

export const dropCreatedTag = () => {
  return (dispatch) => {
    return dispatch(dropCreatedTagSuccess());
  };
};

