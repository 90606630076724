export default (theme) => {
  return {
    imageGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    uploadButton: {
      height: 15,
      borderRadius: 5,
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    uploadImageButton: {
      float: 'right',
      marginTop: theme.spacing(2),
    },
    floorsControlBox: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-around',
    },
    floorsControl: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    floorsText: {
      flex: 0,
      margin: `0px ${theme.spacing(3)}px`,
    },
    floorInput: {
      flex: 1,
    },
    floorInputLabel: {
      position: 'relative',
    },
    deleteImageIcon: {
      background: '#ffffff',
      position: 'absolute',
      zIndex: 1,
      fill: 'red',
      right: 5,
      bottom: 5,
      cursor: 'pointer',
      minWidth: '20px',
      '& .MuiSvgIcon-root': {
        fill: '#d30808',
      },
    },
    gridList: {
      width: '100%',
      gap: '24px',
    },
    noBorder: {
      border: '0px',
    },
    loader: {
      height: '5px',
    },
    imageBox: {
      height: '130px',
      width: '240px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginBottom: '2px',
      textAlign: 'center',
      verticalAlign: 'middle',
      alignItems: 'center',
      display: 'flex',
    },
  };
};
