import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import {
  Button,
  TextField,
  FormControl,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import InputMask from 'react-input-mask';

export class RegistrationConfirmForm extends PureComponent {
  state = {
    values: {
      confirmationCode: '',
    },
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors)) {
      return {
        ...state,
        errors: props.errors,
      };
    }
    return state;
  }

  handleSubmit = (e) => {
    const { onConfirmRegistration, onDropErrors } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const isFromValid = this.validateForm();
    isFromValid ? onConfirmRegistration(this.state.values) : onDropErrors();

    return false;
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    return this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  }

  validateForm = () => {
    const { confirmationCode } = this.state.values;
    const errors = {};
    errors.confirmationCode = FormValidation.validateConfirmationCode(confirmationCode);
    this.setState({
      ...this.state,
      errors,
    });
    return FormValidation.isFormValid(errors);
  }

  render() {
    const {
      classes,
      inProgress,
    } = this.props;
    const { values, errors } = this.state;

    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={this.handleSubmit}
        method={'POST'}
      >
        <FormControl fullWidth margin={'normal'}>
          <label htmlFor="outlined-adornment-confirmationCode" >{i18n.t('CONFIRMATION_CODE')}</label>
          <InputMask
            mask="999-9999"
            onChange={this.handleFieldChange}
            value={values.confirmationCode}
            alwaysShowMask
          >
            {() => (
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                name="confirmationCode"
                type="test"
                id="confirmationCode"
                helperText={errors.confirmationCode}
                error={!!errors.confirmationCode}
              />
            )}
          </InputMask>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={inProgress}
        >
          {i18n.t('REGISTRATION')}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(RegistrationConfirmForm);

RegistrationConfirmForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onConfirmRegistration: PropTypes.func,
  onDropErrors: PropTypes.func,
  inProgress: PropTypes.bool,
};
