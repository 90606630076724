import React from 'react';
import PropTypes from 'prop-types';

import SelectButtons from '../SelectButtons/SelectButtons';
import { getListParamCollection } from 'realtor/helpers/clientFilterParams';

const SelectButtonByFilter = (
  {
    filtersList,
    filter,
    filterName,
    buttonTitlePrefix,
    header,
    title,
    onSelect,
    isMulti,
    disabled,
    onSelectAll,
    errors,
  }
) => {

  const realEstateTypeCollection = getListParamCollection(filtersList, filterName, buttonTitlePrefix);

  return (
    <SelectButtons
      list={realEstateTypeCollection}
      onSelect={onSelect}
      selectedValue={filter[filterName]}
      name={filterName}
      header={header}
      title={title}
      isMulti={isMulti}
      disabled={disabled}
      onSelectAll={onSelectAll}
      error={errors && errors[filterName]}
    />
  );
};

SelectButtonByFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterName: PropTypes.string.isRequired,
  buttonTitlePrefix: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  header: PropTypes.string,
  title: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
};

export default SelectButtonByFilter;
