import { connect } from 'react-redux';
import RegistrationPage from '../components/pages/RegistrationPage/RegistrationPage';
import { userRegistration, dropRegistrationErrors } from '../actions/userRegistration';

const mapStateToProps = ({ appConfigurations, registration }) => {
  const { inProgress, errors, registrationSuccess } = registration;
  return {
    errorCodes: appConfigurations.errorCodes,
    inProgress,
    errors,
    registrationSuccess,
  };
};

const mapDispatchToProps = {
  registration: userRegistration,
  dropRegistrationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
