import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import i18n from '../../../../i18n';

export default class PasswordField extends PureComponent {
  state = {
    showPassword: false,
  }

  handleClickShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {

    const { showPassword } = this.state;
    const { onFieldChange, value, error } = this.props;

    return (
      <FormControl fullWidth margin={'normal'}>
        <label htmlFor="outlined-adornment-password">{i18n.t('PASSWORD')}</label>
        <OutlinedInput
          required
          name="password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          onChange={onFieldChange}
          value={value}
          error={!!error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!error && (
          <FormHelperText error>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

PasswordField.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};
