import { FormValidation } from '../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../constants/validation';

export class ClientValidation {
  constructor(client) {
    this.client = client;
  }

  validate() {
    const { fullName, telephoneNumber, description, moreTelephoneNumbers } = this.client;
    const errors = {
      moreTelephoneNumbers: [],
      fullName: null,
      description: null,
      telephoneNumber: null,
    };

    if (fullName) {
      errors.fullName = FormValidation.validateNameWithSpaces(fullName);
    } else {
      errors.fullName = FormValidation.validateRequired(fullName);
    }

    errors.description = FormValidation.validateLength(description, 0, NOTES_AND_DESCRIPTION_LENGTH);

    moreTelephoneNumbers.forEach(({ telephoneNumber }, index) => {
      if (telephoneNumber) {
        errors.moreTelephoneNumbers[index] = FormValidation.validateTelephoneNumber(telephoneNumber);
      }
    });

    if (telephoneNumber) {
      errors.telephoneNumber = FormValidation.validateTelephoneNumber(telephoneNumber);
    } else {
      errors.telephoneNumber = FormValidation.validateRequired(telephoneNumber);
    }

    return {
      isClientFormValid: FormValidation.isFormValid(errors),
      clientErrors: errors,
    };
  }
}
