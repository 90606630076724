import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  TextField,
  FormHelperText,
  OutlinedInput,
  FormControl,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../i18n';
import styles from './styles';
import PasswordField from '../../fields/PasswordField/PasswordField';

export class RegistrationForm extends PureComponent {
  state = {
    values: {
      password: '',
      confirmPassword: '',
      telephoneNumber: '',
    },
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors)) {
      return {
        ...state,
        errors: props.errors,
      };
    }
    return state;
  }

  passwordValidation = () => {
    const password = this.state.values.password;
    if (!password) {
      return i18n.t('FIELD_REQUIRED');
    }

    if (password.length < 8) {
      return i18n.t('INVALID_PASSWORD_LENGTH');
    }

    const re = /(?=.*\d)(?=.*[A-ZА-ЯҐЄІЇЎЁ]).{8,}/;
    if (!re.test(password)) {
      return i18n.t('INVALID_PASSWORD');
    }

    return null;
  }

  passwordConfirmValidation = () => {
    const confirmPassword = this.state.values.confirmPassword;
    const password = this.state.values.password;
    if (!confirmPassword) {
      return i18n.t('FIELD_REQUIRED');
    }

    if (confirmPassword !== password) {
      return i18n.t('PASSWORDS_DO_NOT_MATCH');
    }

    return null;
  }

  validateForm = () => {
    const errors = {};
    const { telephoneNumber } = this.state.values;

    errors.password = this.passwordValidation();
    errors.confirmPassword = this.passwordConfirmValidation();
    errors.telephoneNumber = FormValidation.validateTelephoneNumber(telephoneNumber);
    this.setState({
      ...this.state,
      errors,
    });
    return FormValidation.isFormValid(errors);
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    return this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  }

  handleSubmit = (e) => {
    const { odRegistration, onDropErrors } = this.props;

    e.preventDefault();

    const isFromValid = this.validateForm();
    isFromValid ? odRegistration(this.state.values) : onDropErrors();

    return false;
  }

  renderForm() {
    const { classes, inProgress } = this.props;
    const { values, errors, showPassword } = this.state;

    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={this.handleSubmit}
        method={'POST'}
      >
        <FormControl fullWidth margin={'normal'}>
          <label htmlFor="outlined-adornment-telephoneNumber" >{i18n.t('TELEPHONE_NUMBER')}</label>
          <InputMask
            mask="+38(099) 999 99 99"
            onChange={this.handleFieldChange}
            value={values.telephoneNumber}
            alwaysShowMask
          >
            {() => (
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                name="telephoneNumber"
                type="test"
                id="telephoneNumber"
                helperText={errors.telephoneNumber}
                error={!!errors.telephoneNumber}
              />
            )}
          </InputMask>
        </FormControl>
        <PasswordField
          onFieldChange={this.handleFieldChange}
          error={errors.password}
          value={values.password}
        />

        <FormControl fullWidth margin={'normal'} variant="outlined">
          <label htmlFor="outlined-adornment-confirmPassword">{i18n.t('CONFIRM_PASSWORD')}</label>
          <OutlinedInput
            variant="outlined"
            required
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            id="confirmPassword"
            onChange={this.handleFieldChange}
            value={values.confirmPassword}
            error={!!errors.confirmPassword}
          />
          {!!errors.confirmPassword && (
            <FormHelperText error>
              {errors.confirmPassword}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={inProgress}
        >
          {i18n.t('CONFIRM_REGISTRATION')}
        </Button>
      </form>
    );
  }

  render() {
    return (this.renderForm());
  }
}

export default withStyles(styles)(RegistrationForm);

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  odRegistration: PropTypes.func.isRequired,
  onDropErrors: PropTypes.func.isRequired,
  errors: PropTypes.object,
  inProgress: PropTypes.bool,
};
