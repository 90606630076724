import axios from '../../common/axios';

class ObjectResource {
  /**
   * Fetch objects for current user
   */
  fetchMy(params) {
    return axios.get('/v1/realtor/my/objects', params)
      .then((res) => res.data);
  }

  /**
   * Fetch objects other realtors objects
   */
  fetchAny(params) {
    return axios.get('/v1/realtor/objects/public', params)
      .then((res) => res.data);
  }

  /**
   * Fetch single object by id
   */
  fetchObject(id) {
    return axios.get(`/v1/realtor/my/object/${id}`)
      .then((res) => res.data);
  }

  /**
   * Fetch public object by id
   */
  fetchPublicObject(id) {
    return axios.get(`/v1/realtor/object/${id}/public`)
      .then((res) => res.data);
  }

  /**
   * Create new object
   */
  saveNewObject(data) {
    return axios.post(
      '/v1/realtor/my/object',
      data
    )
      .then((res) => res.data);
  }

  /**
   * Update object by id
   */
  updateObject(id, data) {
    return axios.put(
      `/v1/realtor/my/object/${id}`,
      data
    )
      .then((res) => res.data);
  }

  /**
   * Delete object
   */
  deleteObject(id) {
    return axios.delete(`/v1/realtor/my/object/${id}`)
      .then((res) => res.data);
  }

  addObjectPhotos(id, data) {
    return axios.post(
      `/v1/realtor/my/object/${id}/photos`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then((res) => res.data);
  }

  fetchObjectByHash(hash) {
    return axios.get(`/v1/shared/real-estate/${hash}`)
      .then((res) => res.data);
  }

  fetchObjectsByHash(params, hash) {
    return axios.get(`/v1/shared/objects/${hash}/show`, { params })
      .then((res) => res.data);
  }

  deleteObjectImage(objectId, imageId) {
    return axios.delete(`/v1/realtor/my/object/${objectId}/image/${imageId}`)
      .then((res) => res.data);
  }
}

export default new ObjectResource();
