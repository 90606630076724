import {
  FETCH_OBJECT_FAIL,
  FETCH_OBJECT_START,
  FETCH_OBJECT_SUCCESS,
} from '../../actions/object';

const initialState = {
  inProgress: false,
  error: null,
  objectData: null,
};

export const sharedSingleRealEstateObject = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_OBJECT_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };
  case FETCH_OBJECT_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };
  case FETCH_OBJECT_SUCCESS:
    return {
      ...state,
      inProgress: false,
      objectData: action.data,
    };
  default:
    return state;
  }
};
