import axios from '../../common/axios';

class User {

  /**
   * Get user profile
   */
  fetchUserInfo(data) {
    return axios.get('/v1/realtor/my/profile', data)
      .then((res) => res.data);
  }
}

export default new User();
