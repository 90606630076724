import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Person from '@material-ui/icons/Person';

export const AvatarBlock = ({ userProfile, classes }) => {

  return (
    <div className={classes.avatarBlock}>
      <Avatar className={classes.avatarBlockAvatar}>
        <Person />
      </Avatar>
      <h2 className={classes.avatarBlockFullName}>{`${userProfile.firstName} ${userProfile.lastName}`}</h2>
    </div>
  );
};

AvatarBlock.propTypes = {
  userProfile: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(AvatarBlock);
