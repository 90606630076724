export class District {
  constructor(props) {
    this.props = props;
  }
  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get name() {
    return this.props.name;
  }

  set name(name) {
    this.props.name = name;
  }
}
