import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../../common/i18n';
import RealtorPageHeader from 'realtor/components/layears/RealtorPageHeader/RealtorPageHeader';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Paper } from '@material-ui/core';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import
CreateSingleRealEstateObjectForm
  from 'realtor/components/forms/CreateSingleRealEstateObjectForm/CreateSingleRealEstateObjectForm';
import AssignClientContainer from 'realtor/containers/Client/AssignClientContainer';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import SaveWithoutClient from 'realtor/components/dialogs/SaveWithoutClient/SaveWithoutClient';

export const DEFAULT_REAL_ESTATE_OBJECT = {
  currency: 1,
  photos: [],
  tags: [],
};

// eslint-disable-next-line max-statements
export const CreateRealEstateObjectPage = ({
  classes,
  appConfigurations,
  createRealEstateObject,
  inProgress,
  fetchTagsList,
  tag,
  createNewTag,
  dropCreatedTag }) => {
  const pageTitle = i18n.t('ADD_NEW_REAL_ESTATE_OBJECT');
  useEffect(() => {
    setPageTitle(pageTitle);
  }, []);

  const [client, setClient] = useState(() => null);
  const [
    isSaveWithoutClientDialogOpen,
    setIsSaveWithoutClientDialogOpen,
  ] = useState(() => false);
  const [realEstateObjectToSave, setRealEstateObjectToSave] = useState(() => null);

  const topRef = useRef(null);

  const executeScroll = () => topRef.current.scrollIntoView({ behavior: 'smooth' });

  const fatsData = useMemo(() => {
    return {
      ...tag,
      tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
    };
  }, [tag]);
  const handleCreateRealEstateObject = (realEstateObject) => {
    if (!client) {
      setIsSaveWithoutClientDialogOpen(true);
      setRealEstateObjectToSave(realEstateObject);
    } else {
      createRealEstateObject({
        ...realEstateObject,
        clientId: client ? client.id : null,
      });
    }
  };

  const handleEditContinue = () => {
    setIsSaveWithoutClientDialogOpen(false);
    executeScroll();
  };

  const handleConfirmSaveWithoutClient = () => {
    createRealEstateObject({
      ...realEstateObjectToSave,
      clientId: client ? client.id : null,
    });
  };
  const renderHeader = () => {
    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{pageTitle}</h1>
        </div>
      </RealtorPageHeader>
    );
  };

  const renderForm = () => {
    return (
      <Fragment>
        <div ref={topRef}></div>
        <div className={classes.clientBlock}>
          <AssignClientContainer client={client} onClientSelected={setClient}/>
        </div>
        <div>
          <CreateSingleRealEstateObjectForm
            onConfirm={handleCreateRealEstateObject}
            realEstateObject={DEFAULT_REAL_ESTATE_OBJECT}
            appConfigurations={appConfigurations}
            confirmButtonText={i18n.t('ADD_NEW_REAL_ESTATE_OBJECT_SAVE_BUTTON_TEXT')}
            cancelButtonText={i18n.t('CANCEL')}
            onAnyValueChange={() => null}
            fetchTagsList={fetchTagsList}
            tag={fatsData}
            onCreateNewTag={createNewTag}
            dropCreatedTag={dropCreatedTag}
          />
          {/* TODO: INVESTIGATE THE LOGIC OF THIS onAnyValueChange*/}
        </div>
      </Fragment>
    );
  };

  const renderLoader = () => {
    return <CircularProgressBox />;
  };

  const renderContent = () => {
    return !inProgress ? renderForm() : renderLoader();
  };

  return (
    <Fragment>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        {renderContent()}
        <SaveWithoutClient
          isDialogOpen={isSaveWithoutClientDialogOpen}
          onEditContinue={handleEditContinue}
          onSaveWithoutClient={handleConfirmSaveWithoutClient}
        />
      </Paper>
    </Fragment>
  );
};

CreateRealEstateObjectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  appConfigurations: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  createRealEstateObject: PropTypes.func.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  createNewTag: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateRealEstateObjectPage);
