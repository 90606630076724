import 'core-js/stable';
import 'regenerator-runtime/runtime';

import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';

import { i18nLoader } from '../common/i18n/i18nLoader';

import configureStore from './configureStore';
import AppLoaderContainer from './containers/AppLoaderContainer';

const APP_ID = 'app';

const render = (Component, configureStore) => {
  const store = configureStore();

  ReactDOM.render(
    <Provider store={store}>
      <Component></Component>
    </Provider>,
    document.getElementById(APP_ID)
  );
};

const start = (AppLoaderContainer, configureStore) => {
  i18nLoader((lng) => import(`../common/i18n/translations/${lng}.yml`))
    .then(() => {
      return render(AppLoaderContainer, configureStore);
    });
  // render(AppLoaderContainer, configureStore);
};

start(AppLoaderContainer, configureStore);
