export default (theme) => {
  return {
    submit: {
      margin: `${theme.spacing(3)}px 0px`,
      padding: `${theme.spacing(1.5)}px 0px`,
      background: '#F5841F',
      borderRadius: theme.spacing(3),
    },
    supportLink: {
      fontSize: '0.9rem',
    },
  };
};
