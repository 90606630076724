import React, { useState } from 'react';
import style from './styles';
import { Button, GridList, GridListTile } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteImageDialog from '../Dialogs/DeleteImageDialog/DeleteImageDialog';
import { ListManager } from 'react-beautiful-dnd-grid';
import { getObjectPhoto, IMAGE_SIZE } from '../../services/imageServise';
import { DeleteOutline } from '@material-ui/icons';

export const UploadedImagesGrid = (
  {
    onImageDelete,
    images,
    classes,
    isBlobList,
    onReorder,
  }
) => {
  const [imageIndexToDelete, setImageIndexToDelete] = useState(null);

  const handleDeleteImage = (index) => {
    setImageIndexToDelete(index);
  };

  const handleDeleteDialogClose = () => {
    setImageIndexToDelete(null);
  };

  const handleDeleteDialogConfirm = () => {
    onImageDelete(imageIndexToDelete);
    handleDeleteDialogClose();
  };

  const getImageListFromBlob = () => {
    return images.map((image, index) => {
      return {
        img: URL.createObjectURL(image),
        index,
      };
    });
  };

  const getImagesList = () => {
    return images.map((image, index) => {
      return {
        img: getObjectPhoto(image, IMAGE_SIZE.SMALL),
        index,
      };
    });
  };

  const getItems = () => {
    return isBlobList ? getImageListFromBlob() : getImagesList();
  };

  const renderTile = (item) => {
    return (
      <GridListTile
        style={{
          marginRight: '24px',
        }
        }
        key={item.img}
        cols={item.cols || 1}
      >
        <Button
          className={classes.deleteImageIcon}
          onClick={() => handleDeleteImage(item.index)}
        ><DeleteOutline/>
        </Button>

        <div
          className={classes.imageBox}
          style={{
            backgroundImage: `url(${item.img})`,
          }}
        >
        </div>
      </GridListTile>
    );
  };

  const isSmallScreen = window.innerWidth < 750;
  const cols = isSmallScreen ? 'auto' : 3;
  const maxItems = isSmallScreen ? 'auto' : 3;

  return (
    <div className={classes.imageGrid}>
      <GridList cellHeight={160} className={classes.gridList} cols={cols}>
        {images && images.length && <ListManager
          style={{
            gap: '24px',
          }}
          spacing={24}
          items={getItems()}
          direction="horizontal"
          maxItems={maxItems}
          render={renderTile}
          onDragEnd={onReorder}
        />}
      </GridList>
      <DeleteImageDialog
        isDeleteDialogOpen={imageIndexToDelete !== null}
        onDeleteConfirm={handleDeleteDialogConfirm}
        onDialogClose={handleDeleteDialogClose}
      />
    </div>
  );
};

export default withStyles(style)(UploadedImagesGrid);

UploadedImagesGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  isBlobList: PropTypes.bool,
};
