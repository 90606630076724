import { connect } from 'react-redux';

import AssignClientPage from '../../components/pages/AssignClientPage/AssignClientPage';

import { createNewTag, dropCreatedTag, fetchTagsList, restoreClient } from '../../actions';

const mapStateToProps = (state) => {
  const { appConfigurations, tag } = state;

  return {
    appConfigurations,
    tag,
  };
};

const mapDispatchToProps = {
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
  restoreClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignClientPage);
