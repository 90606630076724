import Auth from '../resourses/Auth.resource';
import { TelephoneNumberBuilder } from 'realtor/domain';
import { Authentication } from '../domain';
import { AuthenticationRequestDataBuilder } from '../helpers/requestDataBuildes/AuthenticationRequestDataBuilder';

export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';

export const DROP_ERRORS_SUCCESS = 'DROP_ERRORS_SUCCESS';

const dropRegistrationErrorsSuccess = () => {
  return { type: DROP_ERRORS_SUCCESS };
};


const registrationStart = () => {
  return { type: REGISTRATION_START };
};

const registrationFail = (errors) => {
  return {
    type: REGISTRATION_FAIL,
    errors,
  };
};

export const registrationSuccess = (data) => {
  return {
    type: REGISTRATION_SUCCESS,
    data,
  };
};

export const userRegistration = (data) => {
  return (dispatch) => {
    dispatch(registrationStart());
    const authenticationDomain = new Authentication({
      telephoneNumber: TelephoneNumberBuilder.buildFromFormData(data.telephoneNumber),
      password: data.password,
    });

    Auth.registrations(AuthenticationRequestDataBuilder.build(authenticationDomain))
      .then((data) => {
        dispatch(registrationSuccess(data));
      })
      .catch(({ error }) => {
        dispatch(registrationFail(error));
      });
  };
};

export const dropRegistrationErrors = () => {
  return (dispatch) => {
    dispatch(dropRegistrationErrorsSuccess());
  };
};
