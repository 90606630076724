import axios from '../../../common/axios';

class ClientResource {
  /**
   * Create new client
   */
  create(data) {
    return axios.post('/v1/realtor/client', data)
      .then((res) => res.data);
  }

  /**
   * Get clients list
   */
  getList(params) {
    return axios.get('/v1/realtor/clients', { params })
      .then((res) => res.data);
  }

  /**
   * Get clients list by all realtors
   */
  getAllList(params) {
    return axios.get('/v1/realtor/clients/all', { params })
      .then((res) => res.data);
  }

  /**
   * Get total clients count by all realtors
   */
  getAllTotal() {
    return axios.get('/v1/realtor/clients/total/all', {})
      .then((res) => res.data);
  }

  /**
   * Get all clients for search
   */
  getAllForSearch(params = { dir: 'DESC', order: 'created_at' }) {
    return axios.get('/v1/realtor/clients/for-search', { params })
      .then((res) => res.data);
  }

  /**
   * Get total clients count
   */
  getTotal() {
    return axios.get('/v1/realtor/clients/total', {})
      .then((res) => res.data);
  }

  /**
   * Delete client
   */
  deleteClient(clientId) {
    return axios.delete(`/v1/realtor/client/${clientId}`, {})
      .then((res) => res.data);
  }

  /**
   * Get single client
   */
  getSingleClient(clientId) {
    return axios.get(`/v1/realtor/client/${clientId}`)
      .then((res) => res.data);
  }

  /**
   * Update client
   */
  updateClient(clientId, clientData) {
    return axios.put(`/v1/realtor/client/${clientId}`, clientData)
      .then((res) => res.data);
  }

  /**
   * Restore client
   */
  restoreClient(clientId) {
    return axios.patch(`/v1/realtor/client/${clientId}/restore`)
      .then((res) => res.data);
  }
}

export default new ClientResource();
