import axios from '../../../common/axios';

class ClientFilterResource {
  /**
   * Get single client filter
   */
  getSingleClientFilter(clientId, filterId) {
    return axios.get(`/v1/realtor/client/${clientId}/filter/${filterId}`)
      .then((res) => res.data);
  }

  /**
   * Update single client filter
   */
  updateSingleClientFilter(clientId, filterId, clientFilterData) {
    return axios.put(`/v1/realtor/client/${clientId}/filter/${filterId}`, clientFilterData)
      .then((res) => res.data);
  }

  /**
   * Create single client filter
   */
  createSingleClientFilter(clientId, clientFilterData) {
    return axios.post(`/v1/realtor/client/${clientId}/filter`, clientFilterData)
      .then((res) => res.data);
  }

  /**
   * Delete single client filter
   */
  deleteSingleClientFilter(clientId, filterId) {
    return axios.delete(`/v1/realtor/client/${clientId}/filter/${filterId}`)
      .then((res) => res.data);
  }
}

export default new ClientFilterResource();
