import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator={' '}
      isNumericString
      maxLength={13}
      value={value}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: Number(values.value) > 0 ? values.floatValue : null,
          },
        });
      }}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
};
