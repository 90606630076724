import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import i18n from '../../../../../../common/i18n';
import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import { Paper } from '@material-ui/core';
import CreateSingleFilterForm from '../../../forms/CreateSingleFilterForm/CreateSingleFilterForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';

export const UpdateClientFilterPage = ({
  clientId,
  filterId,
  fetchClientFilter,
  updateClientFilter,
  classes,
  clientFilter,
  appConfigurations,
  inProgress,
}) => {
  useEffect(() => {
    setPageTitle(i18n.t('UPDATE_FILTER'));
    fetchClientFilter(clientId, filterId);
  }, [clientId, filterId]);

  const handleFormConfirm = (formData) => {
    return updateClientFilter(clientId, filterId, formData);
  };

  const renderForm = () => {
    return (
      <Fragment>
        <Paper className={classes.paper} elevation={0}>
          <div>
            <CreateSingleFilterForm
              onConfirm={handleFormConfirm}
              filter={clientFilter.toFormEntity()}
              appConfigurations={appConfigurations}
              confirmButtonText={i18n.t('SAVE')}
              disableTypeSelect={clientFilter && Boolean(clientFilter.objectType)}
            />
          </div>
        </Paper>
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{i18n.t('UPDATE_FILTER')}</h1>
        </div>
      </RealtorPageHeader>
    );
  };

  const renderLoader = () => {
    return <CircularProgressBox />;
  };

  const renderContent = () => {
    const showForm = !inProgress && clientFilter;

    return showForm ? renderForm() : renderLoader();
  };

  return (
    <Fragment>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        { renderContent() }
      </Paper>
    </Fragment>
  );
};

UpdateClientFilterPage.propTypes = {
  fetchClientFilter: PropTypes.func.isRequired,
  updateClientFilter: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  filterId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  clientFilter: PropTypes.object,
  appConfigurations: PropTypes.object,
  inProgress: PropTypes.bool,
};

export default withStyles(styles)(UpdateClientFilterPage);
