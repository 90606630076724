import { BaseRealEstateObject } from './BaseRealEstateObject';

export class Commercial extends BaseRealEstateObject {

  get area() {
    return this.props.area;
  }

  set area(area) {
    this.props.area = area;
  }

  get landArea() {
    return this.props.landArea;
  }

  set landArea(landArea) {
    this.props.landArea = landArea;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  get objectMaterial() {
    return this.props.objectMaterial;
  }

  set objectMaterial(objectMaterial) {
    this.props.objectMaterial = objectMaterial;
  }

  get floor() {
    return this.props.floor;
  }
  set floor(floor) {
    this.props.floor = floor;
  }

  get flooring() {
    return this.props.flooring;
  }
  set flooring(flooring) {
    this.props.flooring = flooring;
  }

  get objectCondition() {
    return this.props.objectCondition;
  }

  set objectCondition(objectCondition) {
    this.props.objectCondition = objectCondition;
  }

  get squarePrice() {
    return this.props.squarePrice;
  }

  set squarePrice(squarePrice) {
    this.props.squarePrice = squarePrice;
  }

  get objectPartialServiceType() {
    return this.props.objectPartialServiceType;
  }

  set objectPartialServiceType(objectPartialServiceType) {
    this.props.objectPartialServiceType = objectPartialServiceType;
  }

  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      subtype: this.props.objectSubtype ? Number(this.props.objectSubtype) : null,
      serviceType: this.props.objectPartialServiceType ? Number(this.props.objectPartialServiceType) : null,
      area: this.props.area ? Number(this.props.area) : null,
      landArea: this.props.landArea ? Number(this.props.landArea) : null,
      floor: this.props.floor ? Number(this.props.floor) : null,
      flooring: this.props.flooring ? Number(this.props.flooring) : null,
      squarePrice: this.props.squarePrice ? Number(this.props.squarePrice) : null,
      condition: this.props.objectCondition ? Number(this.props.objectCondition) : null,
      material: this.props.objectMaterial ? Number(this.props.objectMaterial) : null,
    };
  }
}
