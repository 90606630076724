import {
  DefaultFilterValidator,
  GarageParkingValidator,
  LandPlotValidator,
  CommercialValidator,
  ApartmentValidator,
  HouseValidator,
} from './';

export const realEstateObjectFormValidationFactory = (realEstateTypes, exchangeRates, realEstateObject) => {
  switch (realEstateObject.objectType) {
  case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
    return new HouseValidator(realEstateObject, exchangeRates);
  case realEstateTypes.APARTMENT:
    return new ApartmentValidator(realEstateObject, exchangeRates);
  case realEstateTypes.COMMERCIAL:
    return new CommercialValidator(realEstateObject, exchangeRates);
  case realEstateTypes.LAND_PLOT:
    return new LandPlotValidator(realEstateObject, exchangeRates);
  case realEstateTypes.GARAGE_PARKING:
    return new GarageParkingValidator(realEstateObject, exchangeRates);
  default:
    return new DefaultFilterValidator(realEstateObject, exchangeRates);
  }
};
