const CI_MODE_LANG = 'cimode';
export const DEFAULT_LNG = 'ua';

const config = {
  initImmediate: true,
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  load: 'languageOnly',
  detection: {
    lookupQuerystring: 'lang',
  },

  // load fallback language as well (english)
  // when translation not found in requested language app will display label in english
  fallbackLng: DEFAULT_LNG,
};

// redefine language in test env to `cimode` to skip translations loading at all
// set language to `cimode` to always return keys instead of translations
if (process.env.NODE_ENV === 'test') {
  config.lng = CI_MODE_LANG;
  config.fallbackLng = CI_MODE_LANG;
}

export default config;
