import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { getSubtypeList } from 'realtor/helpers/clientFilterParams';
import SelectButtons from 'realtor/components/common/SelectButtons/SelectButtons';

export const SubtypeFilter = ({ filtersList, filter, onFilterChange, onMultiSelectAll, isMulti = false }) => {
  const realEstateTypeCollection = getSubtypeList(filter, filtersList);

  return (
    <SelectButtons
      list={realEstateTypeCollection}
      onSelect={onFilterChange}
      onSelectAll={onMultiSelectAll}
      selectedValue={filter.objectSubtype}
      name={'objectSubtype'}
      header={i18n.t('PARAMS_FILTER_SUBTYPE_TYPE')}
      isMulti={isMulti}
    />
  );
};

SubtypeFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func,
  isMulti: PropTypes.bool,
};
