import { Chip } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dropFiltersCount } from 'realtor/actions';
import { useHistory } from 'react-router-dom';
import { getUrlWithFilterQueryParams } from './helpers/searchUrl';

const styles = (theme) => {
  return {
    filtersCount: {
      marginLeft: theme.spacing(1),
      padding: `0px ${theme.spacing(1)}px`,
      cursor: 'pointer',
      background: 'rgba(229,236,3,0.39)',
    },
  };
};

const ClipObjectFiltersCount = ({
  classes,
  realEstateObject,
  dropFiltersCount,
}) => {
  const history = useHistory();
  const handleClick = () => {
    dropFiltersCount(realEstateObject.id);

    const urlParams = getUrlWithFilterQueryParams(realEstateObject);

    return history.push(`/search-clients${urlParams}`);
  };

  return (
    <Chip
      onClick={handleClick}
      className={classes.filtersCount}
      label={`+${realEstateObject.clientFiltersCount}`}
      size="small"
    />
  );
};

const component = withStyles(styles)(ClipObjectFiltersCount);

ClipObjectFiltersCount.propTypes = {
  classes: PropTypes.object.isRequired,
  realEstateObject: PropTypes.object.isRequired,
  dropFiltersCount: PropTypes.func,
};

const mapDispatchToProps = {
  dropFiltersCount,
};

export const ClipObjectFiltersCountWidget = connect(null, mapDispatchToProps)(component);
