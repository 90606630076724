import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import ClientCard from '../ClientCard/ClientCard';

export const ClientsList = ({
  clients,
  paramsList,
  onDeleteFilter,
  onSelectTag,
  isOwnerList,
  onAddToFavorite,
  onRemoveFromFavorite,
  onDelete,
}) => {
  return clients.map((client) => {
    return (
      <ClientCard
        key={`${client.id}_${client.isFavorite}`}
        client={client}
        onDelete={onDelete}
        paramsList={paramsList}
        onDeleteFilter={onDeleteFilter}
        onTagClick={onSelectTag}
        isOwnerList={isOwnerList}
        onAddToFavorite={onAddToFavorite}
        onRemoveFromFavorite={onRemoveFromFavorite}
      />
    );
  });
};

export default withStyles(styles)(ClientsList);

ClientsList.propTypes = {
  clients: PropTypes.array,
  onDelete: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  paramsList: PropTypes.object,
  onSelectTag: PropTypes.func,
  isOwnerList: PropTypes.bool.isRequired,
  onAddToFavorite: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
};
