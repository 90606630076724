export default (theme) => {
  return {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: `${theme.spacing(3)}px 0px`,
    },
    authWrapper: {
      width: '100%',
      maxWidth: '500px',
      margin: '0 auto',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};
