import { createMuiTheme } from '@material-ui/core/styles/index';
import { responsiveFontSizes } from '@material-ui/core';

export const config = {
  palette: {
    primary: {
      main: '#337ab7',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1600,
    },
  },
  typography: {
    // TODO: material ui is going to restyle Typography to v4
    // suppress warnings for now, revisit it later
    // details https://material-ui.com/ru/guides/migration-v3/
    useNextVariants: true,
  },
  spacing: 8,
};

// Create the theme
let theme = createMuiTheme(config);

// Optionally, make the theme responsive
theme = responsiveFontSizes(theme);

// Dynamically adjust spacing based on screen size
const breakpoints = theme.breakpoints;

theme = createMuiTheme({
  ...theme,
  overrides: {
    MuiTypography: {
      body1: {
        [breakpoints.down('md')]: {
          fontSize: '0.9rem', // Adjust the font size for smaller screens
        },
      },
    },
    MuiGrid: {
      item: {
        [breakpoints.down('md')]: {
          fontSize: '.8rem', // Adjust the font size for smaller screens
        },
      },
    },
    MuiSelect: {
      select: {
        [breakpoints.down('md')]: {
          fontSize: '.8rem', // Adjust the font size for smaller screens
        },
      },
    },
    MuiInputBase: {
      input: {
        [breakpoints.down('md')]: {
          fontSize: '.8rem', // Adjust the font size for smaller screens
        },
      },
    },
  },
});

export { theme };
