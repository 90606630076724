import {
  DefaultFilterValidator,
  GarageParkingValidator,
  LandPlotValidator,
  CommercialValidator,
  ApartmentValidator,
  HouseValidator,
} from './';

export const realEstateObjectFilterValidationFactory = (realEstateTypes, exchangeRates, realEstateObjectFilter) => {
  switch (realEstateObjectFilter.objectType) {
  case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
    return new HouseValidator(realEstateObjectFilter, exchangeRates);
  case realEstateTypes.APARTMENT:
    return new ApartmentValidator(realEstateObjectFilter, exchangeRates);
  case realEstateTypes.COMMERCIAL:
    return new CommercialValidator(realEstateObjectFilter, exchangeRates);
  case realEstateTypes.LAND_PLOT:
    return new LandPlotValidator(realEstateObjectFilter, exchangeRates);
  case realEstateTypes.GARAGE_PARKING:
    return new GarageParkingValidator(realEstateObjectFilter, exchangeRates);
  default:
    return new DefaultFilterValidator(realEstateObjectFilter, exchangeRates);
  }
};
