export default (theme) => {
  return {
    hr: {
      margin: `${theme.spacing(2)}px 0`,
    },
    alreadyHaveAccountSlug: {
      margin: `${theme.spacing(2)}px 0`,
      fontSize: '0.87em',
    },
    header: {
      overflow: 'hidden',
    },
  };
};
