import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import './CustomSlider.scss';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { DEFAULT_IMAGE_URL } from '../../../../services/imageServise';
import { Link } from 'react-router-dom';

const CustomSlider = ({ key ,images = [], classes, objectId }) => {
  const photosListToView = images.length ? images.map(({ image }) => image.small) :
    [DEFAULT_IMAGE_URL];
  return (
    <Carousel
      autoPlay={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={images.length > 1}
      key={key}
    >
      {photosListToView.map((photo, i) => {

        return (
          <Link key={i} className target="_blank" to={`/real-estate-object/${objectId}`}>
            <div className={classes.imageBox} style={{ backgroundImage: `url("${photo}")` }} >
            </div>
          </Link>

        );
      })}
    </Carousel>
  );
};

CustomSlider.propTypes = {
  key: PropTypes.string,
  images: PropTypes.array,
  classes: PropTypes.object,
  objectId: PropTypes.number,
};

export default withStyles(styles)(CustomSlider);
