export default (theme) => {
  const confirmProfileWrapper = {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (theme.isMobile) {
    confirmProfileWrapper.flexDirection = 'column';
  }

  return {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    confirmProfileWrapper,
    confirmProfileBox: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: '510px',
      background: theme.palette.background.paper,
      padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
      boxSizing: 'border-box',
      overflow: 'auto',
      maxHeight: '95%',
      height: 'auto',
    },
  };
};
