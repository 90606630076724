import Storage from './storage';

const USER_CONFIRM_TOKEN_KEY = 'USER_CONFIRM_TOKEN_KEY';

class ConfirmRegistrationService {
  storage = Storage;
  isUserConfirmTokenExist() {
    return !!this.getToken();
  }

  getToken() {
    return this.storage.getData(USER_CONFIRM_TOKEN_KEY);
  }

  saveConfirmUserToken(token) {
    this.storage.setData(USER_CONFIRM_TOKEN_KEY, token);
  }

  destroyConfirmUserToken() {
    this.storage.removeData(USER_CONFIRM_TOKEN_KEY);
  }
}

export default new ConfirmRegistrationService();
