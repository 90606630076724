import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import logoImage from '../../../../public/images/logo.png';

const styles = (theme) => {
  let logoImage = {};

  if (theme.isMobile) {
    logoImage = {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    };
  } else {
    logoImage = {
      position: 'absolute',
      left: theme.spacing(5),
      top: theme.spacing(5),
    };
  }

  return {
    logoImage,
  };
};

class Logo extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <img alt={'logo'} name={'logo'} className={classes.logoImage} src={logoImage}/>
    );
  }
}

export default withStyles(styles)(Logo);

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};
