/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import addClientSmallImage from '../../../../../../public/images/add_client_mobile.png';
import addRealEstateImage from '../../../../../../public/images/add_real_estate_mobile.png';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Avatar,
  Divider,
  Drawer, Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import Person from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import styles from './styles';
import SmallLogo from '../SmallLogo/SmallLogo';
import i18n from '../../../../../common/i18n';
import TGLogo from '../../../../../../public/images/tg_nav_logo.png';
import myObjects from '../../../../../../public/images/my-objects.png';
import searchObjects from '../../../../../../public/images/objects.png';
import { SUPPORT_TELEPHONE_URL } from '../../../../constants/links';
import Button from '@material-ui/core/Button';
import cn from 'classnames';

const MyNavLink = React.forwardRef((props, ref) => {
  const { to, className, activeClassName, children } = props;

  return (
    <NavLink
      ref={ref}
      to={to}
      className={className}
      exact={true}
      activeClassName={activeClassName}
      strict={true}
    >
      {children}
    </NavLink>
  );
});

MyNavLink.displayName = 'MyNavLink';
MyNavLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.element,
};

class NavigationBar extends PureComponent {

  state = {
    anchorEl: null,
  }

  setAnchorEl(element) {
    this.setState({
      ...this.state,
      anchorEl: element,
    });
  }

  handleProfileMenuOpen = (event) => {
    if (this.state.anchorEl) {
      return this.setAnchorEl(null);
    }
    return this.setAnchorEl(event.currentTarget);
  }

  handleDrawerToggle = () => {
    return this.props.onMenuToggle(false);
  };

  handleRouteChange = () => {
    const { isMenuOpenFromMobile } = this.props;

    if (isMenuOpenFromMobile) {
      return this.props.onMenuToggle(false);
    }
  }

  renderNavigationList() {
    const { classes } = this.props;

    const items = [
      {
        path: '/clients',
        title: i18n.t('MY_CLIENTS'),
        icon: <GroupIcon className={classes.navigationItemIcon} />,
      },
      {
        path: '/search-clients',
        title: i18n.t('SEARCH_CLIENTS'),
        icon: <SearchIcon className={classes.navigationItemIcon} />,
      },
      {
        path: '/objects',
        title: i18n.t('OBJECT_LIST_PAGE_TITLE'),
        icon: <img className={classes.navigationItemIcon} src={myObjects} />,
      },
      {
        path: '/search-objects',
        title: i18n.t('SEARCH_OBJECT_PAGE_TITLE'),
        icon: <img className={classes.navigationItemIcon} src={searchObjects}/>,
      },
    ];

    const itemsComponents = items.map((item) => {
      return (
        <ListItem
          onClick={this.handleRouteChange}
          component={MyNavLink}
          to={item.path}
          button
          key={item.title}
          className={classes.mainNavItem}
          activeClassName={classes.mainNavItemSelected}
        >
          <ListItemIcon className={classes.listIcon}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
      );
    });

    return (
      <List>
        {itemsComponents}
      </List>
    );
  }

  renderUserInfo() {
    const { userProfile, classes } = this.props;

    // TODO: Remove or uncomment if design will changed
    // return (
    //   <ListItem button onClick={this.handleProfileMenuOpen}>
    //     <ListItemAvatar>
    //       <Avatar>
    //         <Person />
    //       </Avatar>
    //     </ListItemAvatar>
    //     <ListItemText
    //       className={classes.profileContent}
    //       primary={userProfile.firstName}
    //       secondary={i18n.t('REALTOR')}
    //     />
    //     <div>
    //       <ArrowDropDown/>
    //     </div>
    //     <Menu
    //       id="simple-menu"
    //       anchorEl={this.state.anchorEl}
    //       keepMounted
    //       open={Boolean(this.state.anchorEl)}
    //     >
    //       <MenuItem button onClick={logout}>{i18n.t('LOGOUT')}</MenuItem>
    //       <MenuItem to={'/profile'} component={MyNavLink}>{i18n.t('PROFILE')}</MenuItem>
    //     </Menu>
    //   </ListItem>
    // );

    return (
      <ListItem
        onClick={this.handleRouteChange}
        component={MyNavLink}
        to={'/profile'}
        button
        className={classes.mainNavItem}
        activeClassName={classes.mainNavItemSelected}
      >
        <ListItemAvatar className={classes.listIcon}>
          <Avatar>
            <Person />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.profileContent}
          primary={userProfile.firstName}
          secondary={i18n.t('REALTOR')}
        />
      </ListItem>
    );
  }

  renderCreateButtons() {
    const { classes, isMenuOpen } = this.props;

    const objectBtnClasses = cn({
      [classes.addObjectButton]: true,
      [classes.smallerButton]: !isMenuOpen,
    });

    const clientBtnClasses = cn({
      [classes.addClientButton]: true,
      [classes.smallerButton]: !isMenuOpen,
    });

    const addObjectLabel = i18n.t('ADD_OBJECT');
    const addObjectContent = isMenuOpen ?
      addObjectLabel :
      <img alt={addObjectLabel} width={38} src={addRealEstateImage}/>;

    const addClientLabel = i18n.t('ADD_CLIENT');
    const addClientContent = isMenuOpen ?
      addClientLabel :
      <img alt={addClientLabel} width={38} src={addClientSmallImage}/>;

    return (
      <div className={classes.createButtons}>

        <Link className={classes.link}
          to="/clients/create" component={RouterLink}>
          <Button className={clientBtnClasses}>{addClientContent} </Button>
        </Link>
        <Link className={classes.link}
          to="/real-estate-object/create" component={RouterLink}>
          <Button className={objectBtnClasses}>{addObjectContent}</Button>
        </Link>

      </div>
    );
  }

  renderBottomNav = () => {
    const { classes, isMenuOpen } = this.props;

    return (
      <div className={classes.collapse}>
        <List>
          <ListItem
            button
            className={classes.mainNavItem}
            component={'a'}
            href={SUPPORT_TELEPHONE_URL}
            target={'_blank'}
          >
            <ListItemIcon className={classes.listIcon}>
              <img width={32} src={TGLogo}/>
            </ListItemIcon>
            <ListItemText primary={i18n.t('SUPPORT')} />
          </ListItem>
          <ListItem button onClick={this.handleDrawerToggle} className={classes.mainNavItem}>
            <ListItemIcon className={classes.listIcon}>
              {isMenuOpen ?
                <ChevronLeftIcon className={classes.navigationItemIcon} /> :
                <ChevronRightIcon className={classes.navigationItemIcon} />
              }
            </ListItemIcon>
            <ListItemText primary={i18n.t('COLLAPSE')} />
          </ListItem>
        </List>
      </div>
    );
  }

  render() {
    const { classes, isMenuOpen } = this.props;

    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isMenuOpen,
          [classes.drawerClose]: !isMenuOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isMenuOpen,
            [classes.drawerClose]: !isMenuOpen,
            [classes.navigationBox]: true,
          }),
        }}
      >
        <div className={classes.logo}>
          <SmallLogo/>
        </div>
        {this.renderNavigationList()}
        <Divider className={classes.divider} />
        { this.renderUserInfo() }
        { this.renderCreateButtons() }
        { this.renderBottomNav() }
        <Divider />
      </Drawer>
    );
  }
}

export default withStyles(styles)(NavigationBar);

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  isMenuOpenFromMobile: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};
