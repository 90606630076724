export class RealEstateFilterParamsBuilder {
  static build(filterData) {
    return {
      ...filterData,
      objectSubtype: filterData.objectSubtype ? Array.from(filterData.objectSubtype) : undefined,
      objectMaterial: filterData.objectMaterial ? Array.from(filterData.objectMaterial) : undefined,
      objectCondition: filterData.objectCondition ? Array.from(filterData.objectCondition) : undefined,
      roomsCount: filterData.roomsCount ? Array.from(filterData.roomsCount) : undefined,
    };
  }
}
