import Auth from '../resourses/Auth.resource';
import ConfirmRegistrationService from '../../../common/sevices/confirmRegistrationService';

export const CONFIRM_REGISTRATION_START = 'CONFIRM_REGISTRATION_START';
export const CONFIRM_REGISTRATION_SUCCESS = 'CONFIRM_REGISTRATION_SUCCESS';
export const CONFIRM_REGISTRATION_FAIL = 'CONFIRM_REGISTRATION_FAIL';
export const DROP_CONFIRM_REGISTRATION_ERRORS = 'DROP_CONFIRM_REGISTRATION_ERRORS';

const confirmRegistrationStart = () => {
  return { type: CONFIRM_REGISTRATION_START };
};

const confirmRegistrationSuccess = (data) => {
  return { type: CONFIRM_REGISTRATION_SUCCESS, data };
};

const confirmRegistrationFail = (error) => {
  return { type: CONFIRM_REGISTRATION_FAIL, error };
};

export const dropConfirmRegistrationErrors = () => {
  return {
    type: DROP_CONFIRM_REGISTRATION_ERRORS,
  };
};

export const confirmRegistration = (data) => {
  return (dispatch) => {
    const token = ConfirmRegistrationService.getToken();
    const code = Number(data.confirmationCode.replace('-', ''));
    const requestData = {
      code,
      token,
    };

    dispatch(confirmRegistrationStart());
    Auth.registrationsConfirm(requestData)
      .then((data) => {
        return dispatch(confirmRegistrationSuccess(data));
      })
      .catch(({ error }) => {
        return dispatch(confirmRegistrationFail(error));
      });
  };
};
