import {
  FETCH_MY_OBJECTS_FAIL,
  FETCH_MY_OBJECTS_START,
  FETCH_MY_OBJECTS_SUCCESS,
  DROP_OBJECTS_LIST,
  FETCH_SHARE_TOKEN_START,
  FETCH_SHARE_TOKEN_FAIL,
  FETCH_SHARE_TOKEN_SUCCESS,
} from '../actions/objects';

const initialState = {
  inProgress: false,
  error: null,
  list: [],
  totalCount: 0,
  shareToken: null,
};

export default function myObjects(state = initialState, action) {
  switch (action.type) {
  case FETCH_MY_OBJECTS_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };
  case FETCH_MY_OBJECTS_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };
  case FETCH_MY_OBJECTS_SUCCESS:
    return {
      ...state,
      inProgress: false,
      totalCount: action.data.totalCount,
      list: [...state.list, ...action.data.result],
    };
  case DROP_OBJECTS_LIST:
    return {
      ...state,
      list: initialState.list,
    };
  case FETCH_SHARE_TOKEN_SUCCESS:
    return {
      ...state,
      shareToken: action.data.token,
    };
  case FETCH_SHARE_TOKEN_FAIL:
  case FETCH_SHARE_TOKEN_START:
    return {
      ...state,
    };
  default:
    return state;
  }
}
