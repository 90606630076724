export class FormDataHelper {
  static buildFormDataForSaveObject(data) {
    const formData = new FormData();
    for ( const key in data ) {
      switch (key){
      case 'photos':
        data[key].filter((photo) => !!photo).forEach((photo) => {
          formData.append(key, photo, photo.name);
        });
        break;
      case 'districtId':
        formData.append(key, data[key]);
        break;
      default:
        if (data[key]) formData.append(key, data[key]);
      }
    }
    return formData;
  }

  static buildFormDataForSaveObjectPhotos(photos = []) {
    const formData = new FormData();
    photos.forEach((photo) => {
      formData.append('photos', photo, photo.name);
    });
    return formData;
  }
}
