/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import React, { Fragment, PureComponent } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Alert from 'react-s-alert';

import styles from './styles';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../../common/i18n';
import CreateRealEstateObjectForm from '../CreateRealEstateObjectForm/CreateRealEstateObjectForm';
import { Link as RouterLink } from 'react-router-dom';
import { realEstateObjectFormValidationFactory } from 'realtor/validation/realEstateObject';
import UploadedImagesGrid from '../../../../components/UpdateObject/UploadedImagesGrid';
import { arrayMoveImmutable } from 'array-move';
import FileUploadBox from 'realtor/components/common/FileUploadBox/FileUploadBox';
import { MAX_COUNT_OF_REAL_ESTATE_OBJECT_PHOTOS } from '../../../../constants/photos';
import TagsForm from 'realtor/components/forms/TagsForm/TagsForm';
import {
  DEFAULT_REAL_ESTATE_OBJECT,
} from 'realtor/components/pages/RealEstateObjectPages/CreateRealEstateObjectPage/CreateRealEstateObjectPage';

export class CreateSingleRealEstateObjectForm extends PureComponent {

  state = {
    realEstateObject: this.props.realEstateObject,
    realEstateObjectErrors: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tag.createdTag){
      const realEstateObject = { ...state.realEstateObject };

      props.dropCreatedTag();
      props.onAnyValueChange();
      realEstateObject.tags = [...state.realEstateObject.tags, props.tag.createdTag];

      return {
        ...state,
        realEstateObject,
      };
    }

    if (state.realEstateObject.id){
      if (props.realEstateObject.photos && state.realEstateObject.photos) {
        if (props.realEstateObject.photos.length !== state.realEstateObject.photos.length) {
          const realEstateObject = { ...state.realEstateObject };
          realEstateObject.photos = props.realEstateObject.photos;

          return {
            ...state,
            realEstateObject,
          };
        }
      }
    }
    return true;
  }


  validateFilterForm = () => {
    const { appConfigurations } = this.props;

    const validator = realEstateObjectFormValidationFactory(
      appConfigurations.objectFilters.objectType,
      appConfigurations.exchangeRates,
      this.state.realEstateObject
    );

    const errors = validator.validate();

    return {
      isFilterFormValid: FormValidation.isFormValid(errors),
      realEstateObjectErrors: errors,
    };
  }

  handleAssignTag = (tag) => {
    const { onAnyValueChange } = this.props;
    const { realEstateObject } = this.state;

    let tagsList = realEstateObject.tags || [];

    const isTagAssigned = !!tagsList.find((assignedTag) => {
      return assignedTag.id === tag.id;
    });

    if (isTagAssigned) {
      tagsList = tagsList.filter((assignedTag) => {
        return assignedTag.id !== tag.id;
      });
    } else {
      tagsList.push(tag);
    }

    onAnyValueChange();

    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        tags: tagsList,
      },
    });
  }

  handleImageUpload = (event) => {
    const { files } = event.target;
    const { realEstateObject } = this.state;
    const { onObjectPhotosAdded } = this.props;

    const photos = realEstateObject.photos || [];
    const maxCountPhotosToSave = MAX_COUNT_OF_REAL_ESTATE_OBJECT_PHOTOS - photos.length;
    const filesToSave = files.slice(0, maxCountPhotosToSave);


    if (Boolean(realEstateObject.id)) {
      return onObjectPhotosAdded(filesToSave);
    }

    this.setState({
      ...this.state,
      realEstateObject: {
        ...realEstateObject,
        photos: [...photos, ...filesToSave],
      },
    });

  }

  handleSubmit = (e) => {
    const { onConfirm } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const { isFilterFormValid, realEstateObjectErrors } = this.validateFilterForm();

    this.setState({
      ...this.state,
      realEstateObjectErrors,
    });

    if (isFilterFormValid) {
      onConfirm(this.state.realEstateObject);
    } else {
      Alert.error(
        i18n.t('CREATE_CLIENT_FORM_ERROR'),
        { position: 'top-right', effect: 'slide', timeout: 3000 }
      );
    }

    return false;
  }

  handleFilterRealEstateSelect = (name, value) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        [name]: value,
      },
    });
  }

  recalculatePrice = (name, value) => {
    const { appConfigurations } = this.props;
    const { realEstateObject } = this.state;

    const isLandPlot = realEstateObject.objectType === appConfigurations.objectFilters.objectType.LAND_PLOT;

    return isLandPlot ? this.recalculateLandAreaPrice(name, value) : this.recalculateSquarePrice(name, value);
  }

  recalculateSquarePrice = (name, value) => {
    let totalPrice = this.state.realEstateObject.totalPrice;
    let area = this.state.realEstateObject.area;
    let squarePrice = this.state.realEstateObject.squarePrice;

    if (name === 'totalPrice') {
      totalPrice = value;
    } else if (name === 'area') {
      area = value;
    }

    if (totalPrice && area) squarePrice = (Math.round(totalPrice / area) || 1);

    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        squarePrice,
        area,
        totalPrice,
      },
    });
  }

  recalculateLandAreaPrice = (name, value) => {
    let totalPrice = this.state.realEstateObject.totalPrice;
    let area = this.state.realEstateObject.area;
    let landAreaPrice = this.state.realEstateObject.landAreaPrice;

    if (name === 'totalPrice') {
      totalPrice = value;
    } else if (name === 'area') {
      area = value;
    }

    if (totalPrice && area) {
      landAreaPrice = (Math.round(totalPrice / area) || 1);
    }

    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        landAreaPrice,
        area,
        totalPrice,
      },
    });

  }

  handleRealEstateInputChange = ({ target: { name, value } }) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    if (name === 'totalPrice' || name === 'area') {
      return this.recalculatePrice(name, value);
    }
    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        [name]: value,
      },
    });
  }

  handleServiceRealEstateSelect = (name, value) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    return this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        isMortgage: null,
        [name]: value,
      },
    });
  }

  handleFilterRealEstateTypeSelect = (name, value) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    return this.setState({
      ...this.state,
      filterErrors: {},
      realEstateObject: {
        ...DEFAULT_REAL_ESTATE_OBJECT,
        [name]: value,
      },
    });
  }

  getSetFromFilterParams(filterParam) {
    const result = new Set();
    Object.values(filterParam).forEach((value) => {
      result.add(value);
    });

    return result;
  }

  handleCityChange = (event) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        cityId: get(event, 'cityId', null),
        districtId: null,
      },
    });
  }

  handleDistrictChange = (data) => {
    const { onAnyValueChange } = this.props;
    onAnyValueChange();
    this.setState({
      ...this.state,
      realEstateObject: {
        ...this.state.realEstateObject,
        districtId: data ? data.id : null,
      },
    });
  }

  handleImageReorder = (startPosition, endPosition) => {
    const { realEstateObject } = this.state;
    const { onAnyValueChange } = this.props;
    this.setState({
      ...this.state,
      realEstateObject: {
        ...realEstateObject,
        photos: arrayMoveImmutable(realEstateObject.photos, startPosition, endPosition),
      },
    });
    onAnyValueChange();
  }

  renderImageGridList = () => {
    const { realEstateObject } = this.state;
    return (
      <UploadedImagesGrid
        isBlobList={!Boolean(realEstateObject.id)}
        images={realEstateObject.photos || []}
        onImageDelete={this.handleDeleteImage}
        onReorder={this.handleImageReorder}
      />
    );
  }

  handleDeleteImage = (index) => {
    const { realEstateObject } = this.state;
    const imageToDelete = realEstateObject.photos[index];
    if (imageToDelete.id) {
      this.props.deleteObjectPhoto(realEstateObject.id, imageToDelete.id);
    } else {
      realEstateObject.photos.splice(index, 1);
      this.setState({
        ...this.state,
        object: {
          ...realEstateObject,
          photos: realEstateObject.photos,
        },
      });
    }
  }

  renderPhotos() {
    const { realEstateObject } = this.state;
    const photos = realEstateObject.photos || [];

    const isUploadButtonVisible =
      photos.length < MAX_COUNT_OF_REAL_ESTATE_OBJECT_PHOTOS;

    return (
      <Fragment>
        <h3>{i18n.t('ADD_PHOTOS')}</h3>
        {photos && this.renderImageGridList()}
        {isUploadButtonVisible && <FileUploadBox onUpload={this.handleImageUpload} />}
      </Fragment>
    );
  }

  render() {
    const { realEstateObject, realEstateObjectErrors } = this.state;
    const {
      classes,
      appConfigurations,
      confirmButtonText,
      cancelButtonText,
      disableTypeSelect,
      isSaveButtonDisabled,
      fetchTagsList,
      tag,
      onCreateNewTag,
    } = this.props;

    const buttonTextConfirm = confirmButtonText || i18n.t('ADD_CLIENT');
    const buttonTextCancel = cancelButtonText || i18n.t('CANCEL');
    const isServiceTypeSelected = Boolean(realEstateObject.objectServiceType) ||
      Boolean(realEstateObject.objectPartialServiceType);
    const disableSaveButton = isSaveButtonDisabled || !realEstateObject.objectType || !isServiceTypeSelected;

    return (
      <form
        onSubmit={this.handleSubmit}
        style={{
          maxWidth: '1024px',
        }}
      >
        <div>
          <CreateRealEstateObjectForm
            filtersList={appConfigurations.objectFilters}
            realEstateObject={realEstateObject}
            citiesList={appConfigurations.citiesList}
            onRealEstateTypeSelect={this.handleFilterRealEstateTypeSelect}
            onFilterRealEstateSelect={this.handleFilterRealEstateSelect}
            onServiceSelect={this.handleServiceRealEstateSelect}
            onCityChange={this.handleCityChange}
            onDistrictChange={this.handleDistrictChange}
            onInputChange={this.handleRealEstateInputChange}
            errors={realEstateObjectErrors}
            disableTypeSelect={disableTypeSelect}
          />
        </div>
        {realEstateObject.objectType && isServiceTypeSelected &&
          <Fragment>
            <TagsForm
              onAddNewTag={onCreateNewTag}
              onLoadMoreTags={fetchTagsList}
              tag={tag}
              onAssignTag={this.handleAssignTag}
              assignedTags={realEstateObject.tags || []}
            />
            {this.renderPhotos()}
          </Fragment>
        }
        <Grid container spacing={2} className={classes.controlButtons}>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={disableSaveButton}
            >
              {buttonTextConfirm}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="default"
              className={classes.cancel}
              component={RouterLink} to={'/objects'}
            >
              {buttonTextCancel}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(CreateSingleRealEstateObjectForm);

CreateSingleRealEstateObjectForm.propTypes = {
  realEstateObject: PropTypes.object,
  classes: PropTypes.object,
  onConfirm: PropTypes.func,
  appConfigurations: PropTypes.object,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableTypeSelect: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  onAnyValueChange: PropTypes.func,
  onObjectPhotosAdded: PropTypes.func,
  deleteObjectPhoto: PropTypes.func,
  fetchTagsList: PropTypes.func.isRequired,
  onCreateNewTag: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};
