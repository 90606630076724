import App from '../resourses/App.resource';

export const FETCH_APP_CONFIGURATION_START = 'FETCH_APP_CONFIGURATION_START';
export const FETCH_APP_CONFIGURATION_SUCCESS = 'FETCH_APP_CONFIGURATION_SUCCESS';
export const FETCH_APP_CONFIGURATION_FAIL = 'FETCH_APP_CONFIGURATION_FAIL';

const fetchAppConfigurationStart = () => {
  return { type: FETCH_APP_CONFIGURATION_START };
};

const fetchAppConfigurationSuccess = (data) => {
  return { type: FETCH_APP_CONFIGURATION_SUCCESS, data };
};

const fetchAppConfigurationFail = (error) => {
  return { type: FETCH_APP_CONFIGURATION_FAIL, error };
};

export const getAppConfiguration = () => {
  return (dispatch) => {
    dispatch(fetchAppConfigurationStart());
    App.fetchConfiguration()
      .then((data) => {
        dispatch(fetchAppConfigurationSuccess(data));
      })
      .catch((e) => {
        dispatch(fetchAppConfigurationFail(e));
      });
  };
};
