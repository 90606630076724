import { get } from 'lodash';
import Storage from './storage';

const USER_AUTH_KEY = 'userAuth';

class AuthService {
  storage = Storage;
  isUserAuthorized() {
    const data = this.getToken();
    return !!data;
  }

  authorizeUser(data) {
    this.storage.setData(USER_AUTH_KEY, data);
  }

  getToken() {
    return get(this.storage.getData(USER_AUTH_KEY), 'token', null);
  }

  destroySession() {
    this.storage.removeData(USER_AUTH_KEY);
  }
}

export default new AuthService();
