export default (theme) => {
  return {
    headerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& cite': {
        color: '#767676',
        fontSize: '1rem',
        fontStyle: 'normal',
        marginRight: '5px',
      },
    },
    headerTitle: {
      color: '#767676',
      fontSize: '1.6rem',
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    filtersCount: {
      marginLeft: theme.spacing(1),
      padding: `0px ${theme.spacing(1)}px`,
      cursor: 'pointer',
      background: 'rgba(229,236,3,0.39)',
    },
  };
};
