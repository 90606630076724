import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { loggerMiddleware, devToolsEnhancer } from '../common/middleware/reduxDevTools';
import catchErrors from '../common/middleware/catchErrors';
import rootReducer from './reducers/index';

const middlewares = [thunkMiddleware, catchErrors, ...loggerMiddleware];
const enhancers = [...devToolsEnhancer];

const createAppStore = compose(
  applyMiddleware(...middlewares),
  ...enhancers
)(createStore);

export default function configureStore(initialState) {
  return createAppStore(rootReducer, initialState);
}
