import axios from '../../../common/axios';

class FavoriteClientResource {
  /**
   * Remove client from favorite
   */
  removeFromFavorite(clientId) {
    return axios.delete(`/v1/realtor/client/${clientId}/favorite`, {})
      .then((res) => res.data);
  }

  /**
   * Add client to favorite
   */
  addToFavorite(clientId) {
    return axios.post(`/v1/realtor/client/${clientId}/favorite`, {})
      .then((res) => res.data);
  }
}

export default new FavoriteClientResource();
