import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import i18n from '../../../../../../common/i18n';
import styles from '../styles';
import { BaseParams } from './BaseParams';
import { Grid } from '@material-ui/core';

export class LandPlotParams extends BaseParams {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderFullParamsList() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid item sm={6} xs={12} md={12} lg={12}>
          { this.renderCitiesFilter() }
        </Grid>
        <Grid sm={12} container>
          { this.renderTotalArea(i18n.t('LAND_AREA_PLACEHOLDER')) }
        </Grid>
        <Grid sm={12} container>
          { this.renderCurrency() }
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          { this.renderTotalPrice() }
          { this.renderAreaPrice() }
        </Grid>
        <Grid sm={12} container>
          { this.renderTitle() }
        </Grid>
        <Grid sm={12} container>
          { this.virtualTourLink()}
        </Grid>
        { this.renderDescription() }
        {this.renderNotes()}
      </Fragment>
    );
  }

  render() {
    const { realEstateObject } = this.props;
    return (
      <Grid container>
        <Grid sm={12}>
          { this.renderPartialServiceType() }
        </Grid>
        { realEstateObject.objectPartialServiceType && this.renderFullParamsList() }
      </Grid>
    );
  }
}

export default withStyles(styles)(LandPlotParams);

LandPlotParams.propTypes = {
  realEstateObject: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
