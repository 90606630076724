import {
  HouseFilterValidator,
  DefaultFilterValidator,
  ApartmentFilterValidator,
  CommercialFilterValidator,
  LandPlotFilterValidator,
  GarageParkingFilterValidator,
} from './';

export const clientFilterFormValidationFactory = (realEstateTypes, exchangeRates, filter) => {
  switch (filter.objectType) {
  case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
    return new HouseFilterValidator(filter, exchangeRates);
  case realEstateTypes.APARTMENT:
    return new ApartmentFilterValidator(filter, exchangeRates);
  case realEstateTypes.COMMERCIAL:
    return new CommercialFilterValidator(filter, exchangeRates);
  case realEstateTypes.LAND_PLOT:
    return new LandPlotFilterValidator(filter, exchangeRates);
  case realEstateTypes.GARAGE_PARKING:
    return new GarageParkingFilterValidator(filter, exchangeRates);
  default:
    return new DefaultFilterValidator(filter, exchangeRates);
  }
};
