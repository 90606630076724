export class UserProfile {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get firstName() {
    return this.props.firstName;
  }

  set firstName(firstName) {
    this.props.firstName = firstName;
  }

  get lastName() {
    return this.props.lastName;
  }

  set lastName(lastName) {
    this.props.lastName = lastName;
  }

  get telephoneNumber() {
    return this.props.telephoneNumber;
  }

  set telephoneNumber(telephoneNumber) {
    this.props.telephoneNumber = telephoneNumber;
  }

  get moreTelephoneNumbers() {
    return this.props.moreTelephoneNumbers;
  }

  set moreTelephoneNumbers(moreTelephoneNumbers) {
    this.props.moreTelephoneNumbers = moreTelephoneNumbers;
  }

  get avatar() {
    return this.props.avatar;
  }

  set avatar(avatar) {
    this.props.avatar = avatar;
  }

  get createdAt() {
    return this.props.createdAt;
  }

  set createdAt(createdAt) {
    this.props.createdAt = createdAt;
  }

  get email() {
    return this.props.email;
  }

  set email(email) {
    this.props.email = email;
  }

  get isApproved() {
    return this.props.isApproved;
  }

  set isApproved(isApproved) {
    this.props.isApproved = isApproved;
  }

  get rejectReason() {
    return this.props.rejectReason;
  }

  set rejectReason(rejectReason) {
    this.props.rejectReason = rejectReason;
  }

  get role() {
    return this.props.role;
  }

  set role(role) {
    this.props.role = role;
  }

  get updatedAt() {
    return this.props.updatedAt;
  }

  set updatedAt(updatedAt) {
    this.props.updatedAt = updatedAt;
  }

  get organization() {
    return this.props.organization;
  }

  set organization(organization) {
    this.props.organization = organization;
  }
}
