import { connect } from 'react-redux';

import { ConfirmProfilePage } from '../../components/pages/ProfilePages/ConfirmProfilePage/ConfirmProfilePage';
import {
  confirmRealtorProfile,
  dropRealtorProfileErrors,
  fetchOrganizationsList,
  clearOrganizationsList,
} from '../../actions';

const mapStateToProps = (state) => {
  const { user, organization, appConfigurations } = state;

  return {
    profile: user.profile,
    organizationsList: organization.organizationsList,
    error: user.error,
    inProgress: user.inProgress,
    errorCodes: appConfigurations.errorCodes,
  };
};

const mapDispatchToProps = {
  confirmRealtorProfile,
  fetchOrganizationsList,
  clearOrganizationsList,
  dropRealtorProfileErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmProfilePage);
