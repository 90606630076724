import i18n from '../../../common/i18n';

const getSimpleTranslation = (prefix, value) => {
  return i18n.t(`${prefix}${value}`);
};

const getComplexTranslation = (prefix, firstValue, secondValue) => {
  return i18n.t(`${prefix}${firstValue}_${secondValue}`);
};

const getRealEstateParamKey = (typeList, realEstateParamValue) => {
  for (const key in typeList) {
    if (typeList[key] === realEstateParamValue) {
      return key;
    }
  }
};

const getRealEstateSubtype = (paramsList, type, subtype) => {
  let subtypeData = null;
  switch (type) {
  case paramsList.objectType.HOUSE_OR_COUNTRY_HOUSE:
    subtypeData = getSubtypeData(paramsList, 'house', subtype,'HOUSE');
    break;
  case paramsList.objectType.APARTMENT:
    subtypeData = getSubtypeData(paramsList, 'apartment', subtype, 'FLOR');
    break;
  case paramsList.objectType.COMMERCIAL:
    subtypeData = getSubtypeData(paramsList, 'commercial', subtype, 'FLOR');
    break;
  case paramsList.objectType.GARAGE_PARKING:
    subtypeData = getSubtypeData(paramsList, 'garageAndParking', subtype, 'PARKING_GARAGE');
    break;
  default:
    return subtypeData;
  }

  return subtypeData;
};

const getSubtypeData = (paramsList, category, subtype, typeTitle) => {
  const objectSubtypes = paramsList.objectSubtypes[category];

  const matchedSubtype = getRealEstateParamKey(objectSubtypes, subtype);

  return [typeTitle, matchedSubtype];
};

export const getRealEstateTypeTranslation = ({ objectType }, realEstateTypeValue) => {
  const realEstateType = getRealEstateParamKey(objectType, realEstateTypeValue);

  return getSimpleTranslation('FILTER_REAL_ESTATE_TYPE_', realEstateType);
};

export const getRealEstateSubtypeTranslation = (...params) => {
  if (!params[2]) return;
  const [category, subtypeName] = getRealEstateSubtype(...params);

  return getComplexTranslation('FILTER_REAL_ESTATE_SUBTYPE_', category, subtypeName);
};

export const getRealEstateServiceTypeTranslation = ({
  objectServiceType,
}, serviceType) => {
  const realEstateServiceType = getRealEstateParamKey(objectServiceType, serviceType);

  return getSimpleTranslation('FILTER_REAL_ESTATE_SERVICE_TYPE_', realEstateServiceType);
};

export const getRealEstateRoomsCountTranslation = ({ roomsCount }, roomsCountValue) => {
  const realEstateRoomsCount = getRealEstateParamKey(roomsCount, roomsCountValue);

  return getSimpleTranslation('FILTER_REAL_ESTATE_ROOMS_COUNT_', realEstateRoomsCount);
};

export const getRealEstateMaterialTranslation = ({ objectMaterial }, materialValue) => {
  const realEstateMaterial = getRealEstateParamKey(objectMaterial, materialValue);
  return getSimpleTranslation('FILTER_REAL_ESTATE_MATERIAL_', realEstateMaterial);
};

export const getRealEstateConditionTranslation = ({ objectCondition }, condition) => {
  const realEstateCondition = getRealEstateParamKey(objectCondition, condition);
  return getSimpleTranslation('FILTER_REAL_ESTATE_FLOR_CONDITION_', realEstateCondition);
};

