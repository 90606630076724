import FavoriteClientResource from 'realtor/resourses/FavoriteClient.resource';
import Alert from 'react-s-alert';

export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST';

const addToFavoriteSuccess = (clientId, list) => {
  const clientsList = list.map((client) => {
    if (client.id === clientId) {
      client.isFavorite = true;
    }
    return client;
  });

  return { type: SET_CLIENTS_LIST, clientsList };
};

const removeFromFavoriteSuccess = (clientId, list) => {
  const clientsList = list.map((client) => {
    if (client.id === clientId) {
      client.isFavorite = false;
    }
    return client;
  });

  return { type: SET_CLIENTS_LIST, clientsList };
};

export const addToFavorite = (client) => {
  return (dispatch, getState) => {
    const state = getState();
    FavoriteClientResource.addToFavorite(client.id)
      .then(() => {
        dispatch(addToFavoriteSuccess(client.id, state.clientList.list));
      })
      .catch((error) => {
        Alert.success(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });

  };
};

export const removeFromFavorite = (client) => {
  return (dispatch, getState) => {
    const state = getState();
    FavoriteClientResource.removeFromFavorite(client.id)
      .then(() => {
        dispatch(removeFromFavoriteSuccess(client.id, state.clientList.list));
      })
      .catch((error) => {
        Alert.success(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });

  };
};
