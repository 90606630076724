export class Authentication {
  constructor(props) {
    this.props = props;
  }

  get telephoneNumber() {
    return this.props.telephoneNumber;
  }

  set telephoneNumber(telephoneNumber) {
    this.props.telephoneNumber = telephoneNumber;
  }

  get password() {
    return this.props.password;
  }

  set password(password) {
    this.props.password = password;
  }

  toServerEntity() {
    return {
      password: this.props.password,
      telephoneNumber: this.props.telephoneNumber.getTelephoneNumberFromContract(),
    };
  }
}
