import i18n from '../../../../../../../common/i18n';
import { LocationOn } from '@material-ui/icons';
import React from 'react';
import style from './styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export const RealEstateObjectDetailsLocation = ({
  realEstateObject,
  classes,
}) => {
  const { city, district } = realEstateObject;
  const districtString = district && district.name && `, ${district.name} ${i18n.t('DISTRICT').toLowerCase()}`;
  return (
    <div className={classes.location}>
      <LocationOn className={classes.locationIcon}/>
      <span>м. {city.name}{districtString}</span>
    </div>
  );
};

RealEstateObjectDetailsLocation.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(RealEstateObjectDetailsLocation);
