import { BaseRealEstateObject } from './BaseRealEstateObject';

export class GarageParking extends BaseRealEstateObject {
  get objectPartialServiceType() {
    return this.props.objectPartialServiceType;
  }

  set objectPartialServiceType(objectPartialServiceType) {
    this.props.objectPartialServiceType = objectPartialServiceType;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  set area(area) {
    this.props.area = area;
  }

  get area() {
    return this.props.area;
  }

  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      area: this.props.area ? Number(this.props.area) : null,
      subtype: this.props.objectSubtype ? Number(this.props.objectSubtype) : null,
      serviceType: this.props.objectPartialServiceType ? Number(this.props.objectPartialServiceType) : null,
    };
  }
}
