export default (theme) => {
  return {
    dataAutocomplete: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    styledPaper: {
      background: '#fff',
      boxShadow: '0 0 3px rgba(0,0,0, .3)',
    },
    addClientButton: {
      '&:hover': {
        background: 'rgba(0, 108, 132, 0.1)',
        cursor: 'pointer',
      },
      '&:active': {
        background: 'rgba(0, 108, 132, 0.2)',
      },
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      color: 'rgb(0, 108, 132)',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.1em',
    },
  };
};
