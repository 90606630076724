import {
  CONFIRM_REGISTRATION_START,
  CONFIRM_REGISTRATION_FAIL,
  CONFIRM_REGISTRATION_SUCCESS,
  DROP_CONFIRM_REGISTRATION_ERRORS,
} from '../actions/confirmRegistration';
import AuthService from '../../../common/sevices/auth';
import ConfirmRegistrationService from '../../../common/sevices/confirmRegistrationService';

const initialState = {
  inProgress: false,
  error: null,
};

export const confirmRegistration = (state = initialState, action) => {
  switch (action.type) {
  case CONFIRM_REGISTRATION_START:
    return {
      ...state,
      inProgress: true,
      error: null,
    };

  case CONFIRM_REGISTRATION_SUCCESS:
    AuthService.authorizeUser(action.data);
    ConfirmRegistrationService.destroyConfirmUserToken();
    return {
      ...state,
      inProgress: false,
    };

  case CONFIRM_REGISTRATION_FAIL:
    return {
      ...state,
      inProgress: false,
      error: action.error,
    };

  case DROP_CONFIRM_REGISTRATION_ERRORS:
    return {
      ...state,
      error: null,
    };

  default:
    return state;
  }
};
