import i18n from '../../../common/i18n';

export const trimValuesAtEnd = (values) => {
  const trimmedValues = {};

  for (const [key, val] of Object.entries(values)) {
    trimmedValues[key] = typeof val === 'string' ? val.trimEnd() : val;
  }

  return trimmedValues;

};

const valueCompareHelper = (price, maxValue, currency = null) => {
  const currencies = ['USD', 'UAH'];

  const currencyLabel = currency === null ? '' : i18n.t(`CURRENCY_${currencies[currency]}`);

  if (price > maxValue) {
    return i18n.t(
      'MAXVALUE_EXCEEDED',
      { currencyLabel, maxValue }
    );
  }
};

export const getErrorBasedOnCurrency = (price, currency, MEDIUMINT_MAX, exchangeRates) => {
  let error;
  // USD
  if (currency === 0) {
    const maxValue = Math.floor(MEDIUMINT_MAX / exchangeRates[0].value);
    if (price > maxValue) {
      error = valueCompareHelper(price, maxValue, currency);
    }
  }
  // UAH
  if (currency === 1) {
    if (price > MEDIUMINT_MAX) {
      error = valueCompareHelper(price, MEDIUMINT_MAX, currency);
    }
  }

  return error;
};

export const validateMaxValue = (value, MEDIUMINT_MAX) => {
  let error = null;

  if (value > MEDIUMINT_MAX) {
    error = valueCompareHelper(value, MEDIUMINT_MAX);
  }

  return error;
};

export const validateRangeHelper = (from, to) => {
  let error = null;

  if (from && to) {
    error = Number(from) >= Number(to) ? i18n.t('RANGE_ERROR') : null;
  }

  return error;
};

export const validateRangeWithEqualHelper = (from, to) => {
  let error = null;

  if (from && to) {
    error = Number(from) > Number(to) ? i18n.t('RANGE_ERROR') : null;
  }

  return error;
};


