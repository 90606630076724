import React from 'react';
import Alert from 'react-s-alert';

import './ReactSAlertStyles.scss';

const NOTIFICATIONS_CONFIG = { limit: 3 };

const ReactSAlert = () => {
  return (<Alert stack={NOTIFICATIONS_CONFIG} />);
};

export default ReactSAlert;
