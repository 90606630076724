import { ORIGINAL_DOMAIN } from '../../Config';

export class ShareTokenHelper {
  static buildShareLinkForSingleObject(token) {
    return `${ORIGINAL_DOMAIN}/share/object?token=${token}`;
  }

  static buildShareLinkForObjectsList(token) {
    return `${ORIGINAL_DOMAIN}/share/objects?token=${token}`;
  }

  static buildShareLinkForSingleObjectWithoutDomain(token) {
    return `/share/object?token=${token}`;
  }
}
