import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ObjectCard from '../../ObjecCard/ObjectCard';
import ObjectFilters from '../../../../common/components/ObjectFilters/ObjectFilters';
import i18n from '../../../../common/i18n';
import { ShareTokenHelper } from '../../../helpers/ShareTokenHelper';
import { getObjectPhoto, IMAGE_SIZE } from '../../../services/imageServise';

const defaultQueryParams = {
  limit: 8,
  offset: 0,
};

export class ShareObjectsListPage extends PureComponent {

  constructor(props) {
    super(props);
    this.props.clearObjectsList();
    this.props.fetchObjects(this.state.queryParams, props.objectsHash);
  }

  state = {
    queryParams: defaultQueryParams,
    isShareLinkDialogOpen: false,
  }

  isLoadMoreButtonVisible = () => {
    const { objectsList } = this.props;
    const { queryParams } = this.state;
    return ((!objectsList.inProgress &&
      queryParams.offset === 0) || queryParams.offset !== 0) &&
      (objectsList.list.length < objectsList.totalCount);
  }

  handleFiltersSubmit = (params) => {
    const queryParams = {
      ...defaultQueryParams,
      ...params,
    };
    this.setState({
      ...this.state,
      queryParams,
    });

    this.props.clearObjectsList();
    this.props.fetchObjects(queryParams, this.props.objectsHash);
  }

  handleFilterChange = (params) => {
    const queryParams = {
      ...this.state.queryParams,
      ...params,
    };
    this.setState({
      ...this.state,
      queryParams,
    });
  }

  handleLoadMoreData = () => {
    const queryParams = {
      ...this.state.queryParams,
      offset: this.state.queryParams.offset + this.state.queryParams.limit,
    };
    this.setState({
      ...this.state,
      queryParams,
    });

    this.props.fetchObjects(queryParams, this.props.objectsHash);
  }

  renderCard() {
    const { objectsList, classes } = this.props;
    const items = objectsList.list.map((item, i) => {
      return (
        <Grid className={classes.objectCardBox} item key={i} sm={6} md={4} lg={3} xl={2} xs={12}>
          <ObjectCard
            title={item.title}
            thumbnail={getObjectPhoto(item.thumbnail, IMAGE_SIZE.SMALL)}
            id={item.id}
            url={ ShareTokenHelper.buildShareLinkForSingleObjectWithoutDomain(item.shareToken) }
            code={item.code}
          >
          </ObjectCard>
        </Grid>
      );
    });
    return (
      <Fragment>
        {items.map((item) => item)}
      </Fragment>
    );
  }

  render() {
    const {
      classes,
      filters,
      regions,
      objectsList,
    } = this.props;
    return (
      <Grid container>
        <Grid className={classes.tabContent} container alignItems={'center'} index={0}>
          <Grid className={classes.filtersBlock} sm={12} item>
            <ObjectFilters
              filters={filters}
              regions={regions}
              showAccessFilter={true}
              onFilterSubmit={this.handleFiltersSubmit}
            />
          </Grid>
          {this.renderCard()}
          {(this.isLoadMoreButtonVisible()) &&
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={objectsList.inProgress}
              onClick={this.handleLoadMoreData}
            >
              {i18n.t('LOAD_MORE', { count: defaultQueryParams.limit })}
            </Button>
          }
        </Grid>
      </Grid>
    );
  }
};

export default withStyles(styles)(ShareObjectsListPage);

ShareObjectsListPage.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchObjects: PropTypes.func.isRequired,
  clearObjectsList: PropTypes.func.isRequired,
  objectsList: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  objectsHash: PropTypes.string.isRequired,
};
