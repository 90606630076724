import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import 'react-s-alert/dist/s-alert-default.css';

import styles from './styles';

import AllAccessRoutes from '../../allAccess/routes/AllAccessRoutes';
import { RealtorCabinetContainer } from 'realtor/containers';

export const App = (
  {
    isConfirmationTokenExist,
    isAppInitialized,
    redirectPath,
    classes,
    isAuthorized,
  }
) => {
  const renderNotAuthorizedRoutes = () => (<AllAccessRoutes isConfirmationTokenExist={isConfirmationTokenExist} />);

  const renderAuthorizedRoutes = () => (
    <RealtorCabinetContainer
      isAppInitialized={isAppInitialized}
      redirectPath={redirectPath}
    />
  );

  return (
    <div className={classes.appWrapper}>
      <main className={classes.mainContent}>
        <Router>
          {isAuthorized ? renderAuthorizedRoutes() : renderNotAuthorizedRoutes() }
        </Router>
      </main>
    </div>
  );

};

export default withStyles(styles)(App);

App.propTypes = {
  isAuthorized: bool,
  isConfirmationTokenExist: bool,
  isAppInitialized: bool,
  classes: PropTypes.object.isRequired,
  redirectPath: PropTypes.string,
};
