import i18n from '../../i18n';

export const mapConfirmRegistrationErrors = (errorCodes, error) => {
  const validationErrors = {};

  if (error.code === errorCodes.INVALID_CONFIRMATION_CODE) {
    validationErrors.confirmationCode = i18n.t('INVALID_CONFIRMATION_CODE');
  }

  return validationErrors;
};

export const mapLoginErrors = (errorCodes, error) => {
  const validationErrors = {};

  if (error.code === errorCodes.USER_WITH_THIS_TELEPHONE_NUMBER_NOT_FOUND) {
    validationErrors.telephoneNumber = i18n.t('USER_WITH_THIS_TELEPHONE_NUMBER_NOT_FOUND');
  }

  if (error.code === errorCodes.INVALID_PASSWORD) {
    validationErrors.password = i18n.t('PASSWORD_DO_NOT_MATCH');
  }

  return validationErrors;
};

export const mapRegistrationErrors = (errorCodes, error) => {
  const validationErrors = {};

  if (error.code === errorCodes.EMAIL_ALREADY_EXIST) {
    validationErrors.email = i18n.t('EMAIL_ALREADY_EXIST');
  }

  if (error.code === errorCodes.TELEPHONE_NUMBER_ALREADY_EXIST) {
    validationErrors.telephoneNumber = i18n.t('TELEPHONE_NUMBER_ALREADY_EXIST');
  }

  return validationErrors;
};

export const mapCreateClientErrors = (errorCodes, error) => {
  const validationErrors = {};

  if (error.code === errorCodes.CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST) {
    validationErrors.telephoneNumber = i18n.t('CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST');
  }

  return validationErrors;
};
