const drawerWidth = 324;

export default (theme) => {
  return {
    mainNavItem: {
      paddingLeft: theme.spacing(3),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '0px',
    },
    toolbarHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    toolbarHeaderTitle: {
      fontSize: '1.7em',
      margin: '0px',
    },
    toolbar: {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    closeButton: {
      cursor: 'pointer',
    },
    filterList: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '3px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#c0c0c0',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#a0a0a0',
      },
      '& h3': {
        fontSize: '1em',
        color: '#5F5F5F',
      },
      '& button': {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1),
      },
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: theme.spacing(1),
      },
      '& .MuiAutocomplete-clearIndicator': {
        padding: theme.spacing(0.5),
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: theme.spacing(0.5),
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: '5px',
      },
      '& .MuiSelect-select': {
        padding: '5px',
      },
    },
    filterControl: {
      marginTop: 'auto',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    submit: {
      margin: '12px 0px',
      background: '#F5841F',
      borderRadius: '22px',
      color: '#fff',
      opacity: 0.8,
      fontWeight: 'bold',
      '&:hover': {
        background: '#F5841F',
        opacity: 1,
      },
    },
    cancel: {
      color: '#3694D1',
      borderRadius: '22px',
      fontWeight: 'bold',
    },
    '@media (max-width: 1024px)': {
      drawerOpen: {
        width: '100%',
      },
    },
  };
};
