import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { NumberFormatCustom } from 'realtor/components/common/PriceNumberMask/PriceNumberMask';

export const AreaFilter = ({ filterErrors , filter, onInputChange, placeholder, isTitleLabel }) => {
  const value = filter.area;

  const style = value ? { paddingLeft: '4px' } : {};

  const renderTitle = (title) => {
    return isTitleLabel ? <label>{title}</label> : <h3>{title}</h3>;
  };

  return (
    <FormControl
      margin={'none'}
      helperText={filterErrors.area}
      error={filterErrors.area}
    >
      {renderTitle(`${i18n.t('TOTAL_AREA')}:`)}
      <TextField
        placeholder={placeholder || i18n.t('AREA_PLACEHOLDER')}
        variant="outlined"
        margin="none"
        required
        fullWidth
        id={'area'}
        name={'area'}
        onChange={onInputChange}
        value={value}
        inputProps={{
          min: 0,
          style,
        }}
        error={!!filterErrors.area}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: value && <InputAdornment position="start"></InputAdornment>,
        }}
      />
      <FormHelperText>{filterErrors.area}</FormHelperText>
    </FormControl>
  );
};

AreaFilter.propTypes = {
  filterErrors: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isTitleLabel: PropTypes.bool,
};
