import { merge } from 'lodash';

export default function translationProvider(appTranslations) {

  return class TranslationsProvider {

    static type = 'backend';

    /**
     * Load requested language and fallback language
     */
    read(language, namespace, callback) {
      // common translations always exist for supported languages - en, zh, ja
      // for not supported languages promise should be rejected so app can fallback to english
      const commonTranslations = import(`./translations/${language}.yml`);

      return Promise
        .all([
          commonTranslations, // eg. translations/ja.yml
          appTranslations(language), // eg. src/reports/i18n/translations/ja.yml
        ])
        .then((translations) => {
          // extend common translations by app translations
          // app translations have greater priority then common so they override app translations
          const merged = merge(translations[0].default, translations[1].default);
          callback(null, merged);
        })
        .catch((err) => {
          callback(err);
        });
    }
  };

}
