import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import i18n from '../../../../../../common/i18n';
import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import { Paper } from '@material-ui/core';
import CreateSingleFilterForm from '../../../forms/CreateSingleFilterForm/CreateSingleFilterForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';

export const DEFAULT_FILTER = {
  currency: 1,
};

export const CreateClientFilterPage = ({
  clientId,
  createClientFilter,
  classes,
  appConfigurations,
  inProgress,
}) => {
  const pageTitle = i18n.t('ADD_FILTER');
  useEffect(() => {
    setPageTitle(pageTitle);
  }, []);
  const handleFormConfirm = (formData) => {
    return createClientFilter(clientId, formData);
  };

  const renderForm = () => {
    return (
      <Fragment>
        <Paper className={classes.paper} elevation={0}>
          <div>
            <CreateSingleFilterForm
              onConfirm={handleFormConfirm}
              filter={DEFAULT_FILTER}
              appConfigurations={appConfigurations}
              confirmButtonText={i18n.t('SAVE_CHANGES')}
              cancelButtonText={i18n.t('SAVE_WITHOUT_CHANGES')}
            />
          </div>
        </Paper>
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{pageTitle}</h1>
        </div>
      </RealtorPageHeader>
    );
  };

  const renderLoader = () => {
    return <CircularProgressBox />;
  };

  const renderContent = () => {
    const showForm = !inProgress;

    return showForm ? renderForm() : renderLoader();
  };

  return (
    <Fragment>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        { renderContent() }
      </Paper>
    </Fragment>
  );
};

CreateClientFilterPage.propTypes = {
  createClientFilter: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  appConfigurations: PropTypes.object,
  inProgress: PropTypes.bool,
};

export default withStyles(styles)(CreateClientFilterPage);
