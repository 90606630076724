export default (theme) => {
  return {
    mobileNavigationBar: {
      display: 'none',
      backgroundColor: '#1B457D',
    },
    logo: {
      marginLeft: theme.spacing(0.5),
    },
    controlButtons: {
      marginLeft: 'auto',
    },
    controlButton: {
      padding: theme.spacing(0.5),
    },
    '@media (max-width: 900px)': {
      mobileNavigationBar: {
        display: 'block',
        '-webkit-appearance': 'block',
      },
    },
  };
};
