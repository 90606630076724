import Auth from '../resourses/Auth.resource';
import { Authentication } from '../domain';
import { TelephoneNumberBuilder } from 'realtor/domain';
import { AuthenticationRequestDataBuilder } from '../helpers/requestDataBuildes/AuthenticationRequestDataBuilder';
import { onRedirect } from '../../actions/redirect';

export const AUTHORIZATION_START = 'AUTHORIZATION_START';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_FAIL = 'AUTHORIZATION_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const DROP_ERRORS_SUCCESS = 'DROP_ERRORS_SUCCESS';

export const REDIRECT_TO_CONFIRM = 'REDIRECT_TO_CONFIRM';

const dropAuthorizationErrorsSuccess = () => {
  return { type: DROP_ERRORS_SUCCESS };
};

const authorizationStart = () => {
  return { type: AUTHORIZATION_START };
};

const authorizationFail = (error) => {
  return {
    type: AUTHORIZATION_FAIL,
    error,
  };
};

const authorizationSuccess = (data) => {
  return {
    type: AUTHORIZATION_SUCCESS,
    data,
  };
};

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const redirectToConfirm = (errorData) => {
  return {
    token: errorData.token,
    type: REDIRECT_TO_CONFIRM,
  };
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(authorizationStart());
    const authenticationDomain = new Authentication({
      telephoneNumber: TelephoneNumberBuilder.buildFromFormData(data.telephoneNumber),
      password: data.password,
    });
    Auth.login(AuthenticationRequestDataBuilder.build(authenticationDomain))
      .then((response) => {
        dispatch(authorizationSuccess(response));
        return dispatch(onRedirect('/clients'));
      })
      .catch(({ error, status }) => {
        if (status === 301) {
          return dispatch(redirectToConfirm(error));
        }
        return dispatch(authorizationFail(error));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutSuccess());
    Auth.logout().finally();
  };
};

export const dropAuthorizationErrors = () => {
  return (dispatch) => {
    dispatch(dropAuthorizationErrorsSuccess());
  };
};
