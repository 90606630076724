import React from 'react';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import i18n from '../../../../../common/i18n';
import PropTypes from 'prop-types';

export const IgnoreLastFloorFilter = ({ filter, onFilterChange }) => {
  const name = 'ignoreLastFloor';
  const isDisabled = filter.isLastFloor;

  return (
    <CustomSwitch
      disabled={isDisabled}
      label={i18n.t('IGNORE_LAST_FLOOR')}
      name={name}
      checked={filter[name]}
      onChange={onFilterChange}
    />
  );
};

IgnoreLastFloorFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
