import { createSelector } from 'reselect';
import { get } from 'lodash';

const getUserProfile = (state) => {
  return get(state, 'user.profile');
};

const _isProfileFilled = (profile) => {
  return Boolean(profile.firstName && profile.lastName);
};

export const isProfileFilled = createSelector([getUserProfile], _isProfileFilled);
