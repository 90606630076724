import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfirmUserProfileContainer } from 'realtor/containers';

const AccountConfirmationRoutes = () => {
  return (
    <Switch>
      <Route path="/profile/confirm" component={ConfirmUserProfileContainer}/>
      <Redirect from="*" to="/profile/confirm" />
    </Switch>
  );
};

export default AccountConfirmationRoutes;
