import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../../../../../../common/i18n';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import ShareObjectButton from './../ShareObjectButton/ShareObjectButton';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const styles = (theme) => {
  return {
    objectPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '12px',
    },
    objectPriceData: {
      color: '#333333',
      '& strong': {
        fontSize: '2rem',
      },
      '& p': {
        margin: 0,
      },
    },
    actions: {
      flexDirection: 'row',
      display: 'flex',
      gap: theme.spacing(1),
    },
  };
};

export const RealEstateObjectDetailsPriceAndActions = ({
  classes,
  realEstateObject,
  hideControlButtons,
  onShareObjectsDialogOpen,
  onAddRealEstateToFavorite,
  onRemoveRealEstateFromFavorite,
}) => {
  const { totalPrice, currency, squarePrice, isFavourite } = realEstateObject;
  const currencyLabel = getCurrencyLabel(currency);

  const renderShareButton = () => {
    return (
      <ShareObjectButton onShareObjectsDialogOpen={onShareObjectsDialogOpen} />
    );
  };

  return (
    <div className={classes.objectPrice}>
      <div className={classes.objectPriceData}>
        <strong>{formatPriceNumber(totalPrice)} {currencyLabel}</strong>
        {squarePrice &&
          <p>{i18n.t('REAL_ESTATE_OBJECT_SQUARE_PRICE_LABEL')}: {squarePrice} {currencyLabel} </p>}
      </div>
      <div className={classes.actions}>
        <IconButton >
          {isFavourite ?
            <Favorite onClick={() => onRemoveRealEstateFromFavorite(realEstateObject)} color={'primary'}/> :
            <FavoriteBorder onClick={() => onAddRealEstateToFavorite(realEstateObject)} color={'primary'} />}
        </IconButton>
        {!hideControlButtons && <div>{renderShareButton()}</div>}
      </div>
    </div>
  );
};

RealEstateObjectDetailsPriceAndActions.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  hideControlButtons: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onShareObjectsDialogOpen: PropTypes.func.isRequired,
  onAddRealEstateToFavorite: PropTypes.func.isRequired,
  onRemoveRealEstateFromFavorite: PropTypes.func.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsPriceAndActions);
