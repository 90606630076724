import OrganizationResource from '../resourses/Organization.resource';

export const FETCH_ORGANIZATIONS_LIST_START = 'FETCH_ORGANIZATIONS_LIST_START';
export const FETCH_ORGANIZATIONS_LIST_FAIL = 'FETCH_ORGANIZATIONS_LIST_FAIL';
export const FETCH_ORGANIZATIONS_LIST_SUCCESS = 'FETCH_ORGANIZATIONS_LIST_SUCCESS';
export const CLEAN_ORGANIZATIONS_LIST = 'CLEAN_ORGANIZATIONS_LIST';

const fetchOrganizationListStart = () => {
  return { type: FETCH_ORGANIZATIONS_LIST_START };
};

const fetchOrganizationListSuccess = (organizationsList) => {
  return { type: FETCH_ORGANIZATIONS_LIST_SUCCESS, organizationsList };
};

const fetchOrganizationListFail = (error) => {
  return { type: FETCH_ORGANIZATIONS_LIST_FAIL, error };
};

const cleanOrganizationList = () => {
  return { type: CLEAN_ORGANIZATIONS_LIST };
};

export const fetchOrganizationsList = (params) => {
  return (dispatch) => {
    dispatch(fetchOrganizationListStart());
    OrganizationResource.getList(params)
      .then((response) => {
        return dispatch(fetchOrganizationListSuccess(response));
      })
      .catch(({ error }) => {
        return dispatch(fetchOrganizationListFail(error));
      });
  };
};

export const clearOrganizationsList = () => {
  return (dispatch) => {
    return dispatch(cleanOrganizationList());
  };
};
