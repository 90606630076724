export class Tag {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get title() {
    return this.props.title;
  }

  set title(title) {
    this.props.title = title;
  }

  get countOfUse() {
    return this.props.countOfUse;
  }

  set countOfUse(countOfUse) {
    this.props.countOfUse = countOfUse;
  }

  toFormEntity() {
    return this.props;
  }

  toServerEntity() {
    return this.props;
  }
}
