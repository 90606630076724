import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { FormControl, TextField } from '@material-ui/core';

import i18n from '../../../i18n';

export default class TelephoneNumberField extends PureComponent {
  render() {
    const { onFieldChange, value, error } = this.props;

    return (
      <FormControl fullWidth margin={'normal'}>
        <label htmlFor="outlined-adornment-telephoneNumber" >{i18n.t('TELEPHONE_NUMBER')}</label>
        <InputMask
          mask="+38(099) 999 99 99"
          onChange={onFieldChange}
          value={value}
          alwaysShowMask
        >
          {() => (
            <TextField
              variant="outlined"
              margin="none"
              required
              fullWidth
              name="telephoneNumber"
              type="test"
              id="telephoneNumber"
              helperText={error}
              error={!!error}
            />
          )}
        </InputMask>
      </FormControl>
    );
  }
}

TelephoneNumberField.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};
