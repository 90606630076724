export const escapeTelephoneNumberSpecSymbols = (telephoneNumber) => {
  return telephoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace('+', '')
    .split('-')
    .join('')
    .split(' ')
    .join('');
};

export const isPhoneNumber = (search) => {
  const regExp = /[+()]/;
  return regExp.test(search);
};
