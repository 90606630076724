import axios from '../../../common/axios';

class Auth {
  /**
   * User registration
   */
  registrations(data) {
    return axios.post('/v1/authentication/realtor', data)
      .then((res) => res.data);
  }

  /**
   * User authorization
   */
  login(data) {
    return axios.post('/v1/authorization/realtor', data)
      .then((res) => res.data);
  }

  /**
   * User logout
   */
  logout() {
    return axios.post('/v1/logout/realtor')
      .then((res) => res.data);
  }

  /**
   * User registration confirm
   */
  registrationsConfirm(data) {
    return axios.post('/v1/authentication/realtor/confirm', data)
      .then((res) => res.data);
  }
}

export default new Auth();
