/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import AuthPageLayer from '../../layears/AuthPageLayer/AuthPageLayer';
import i18n from '../../../../i18n';
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import AuthFooterLayer from '../../layears/AuthFooterLayer/AuthFooterLayer';
import RegistrationConfirmForm from '../../forms/RegistrationConfirmForm/RegistrationConfirmForm';
import { mapConfirmRegistrationErrors } from '../../../helpers/errorsMapper';

export class ConfirmRegistrationPage extends PureComponent {

  constructor(props) {
    super(props);
    props.onConfirmRegistration({
      confirmationCode: '9379-992',
    });
  }

  state = {
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    const { error, errorCodes } = props;
    const errorCode = get(error, 'code');

    const validationErrors = errorCode ? mapConfirmRegistrationErrors(errorCodes, error) : {};

    return {
      ...state,
      errors: validationErrors,
    };
  }

  renderHeader = () => {
    return (
      <FormHeaderLayer title={i18n.t('REGISTRATION')}>
        {`${i18n.t('ALREADY_HAVE_ACCOUNT')} `}
        <Link to="/auth" component={RouterLink}>
          {i18n.t('SIGN_IN')}
        </Link>
      </FormHeaderLayer>
    );
  }

  render() {
    const {
      classes,
      onConfirmRegistration,
      inProgress,
      onDropConfirmRegistrationErrors,
    } = this.props;
    const { errors } = this.state;

    return (
      <AuthPageLayer>
        {this.renderHeader()}
        <hr size={1} className={classes.hr}/>
        <RegistrationConfirmForm
          onConfirmRegistration={ onConfirmRegistration }
          inProgress={inProgress}
          errors={errors}
          onDropErrors={onDropConfirmRegistrationErrors}
        />
        <AuthFooterLayer confirmText={i18n.t('REGISTRATION_CONFIRM_TEXT')}/>
      </AuthPageLayer>
    );
  };
};

export default withStyles(styles)(ConfirmRegistrationPage);

ConfirmRegistrationPage.propTypes = {
  inProgress: PropTypes.bool,
  error: PropTypes.object,
  classes: PropTypes.object.isRequired,
  errorCodes: PropTypes.object,
  onConfirmRegistration: PropTypes.func.isRequired,
  onDropConfirmRegistrationErrors: PropTypes.func.isRequired,
};
