/* eslint-disable import/max-dependencies */
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { LocationOn, EditOutlined, DeleteOutline, FavoriteBorder, Favorite } from '@material-ui/icons';
import PropTypes from 'prop-types';

import styles from './styles';
import i18n from '../../../../../common/i18n';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';
import {
  getRealEstateRoomsCountTranslation,
  getRealEstateServiceTypeTranslation,
  getRealEstateSubtypeTranslation,
} from 'realtor/helpers/objectParams';
import { Link, useHistory } from 'react-router-dom';
import ClientContacts from 'realtor/components/layears/ObjectCard/components/ClientContacts/ClientContacts';
import TagsList from 'realtor/components/layears/ObjectCard/components/TagsList/TagsList';
import ChipByType from 'realtor/components/layears/ChipByType/ChipByType.';
import CustomSlider from 'realtor/components/common/CustomSlider/CustomSlider';
import TextTruncate from 'realtor/components/layears/TextTruncate/TextTruncate';
import cn from 'classnames';
import { ClipObjectFiltersCountWidget } from 'realtor/components/layears/ClipObjectFiltersCount/ClipObjectFiltersCount';


function EditButton({ id, classes }) {
  const history = useHistory();

  const handleUpdate = () => {
    history.push(`/real-estate-object/${id}/edit`);
  };
  return <Button className={classes.controlsButton} onClick={handleUpdate}><EditOutlined/></Button>;
}

const ObjectCard = ({
  onDelete,
  object,
  addRealEstateToFavorite,
  removeRealEstateFromFavorite,
  classes,
  paramsList,
  isFavourite = false,
  isOwnerList,
  onTagClick,
}) => {
  const handleDelete = (e) => {
    e.stopPropagation();

    return onDelete(object);
  };

  const handleOnFavouriteButtonClick = (e) => {
    e.stopPropagation();

    return object.isFavourite ? removeRealEstateFromFavorite(object) : addRealEstateToFavorite(object);
  };

  const renderObjectParams = () => {
    const { subtype, serviceType, type, area, roomsCount, city, floor, flooring, district } = object;
    const realEstateSubtype = getRealEstateSubtypeTranslation(paramsList, type, subtype);
    const realEstateServiceType = getRealEstateServiceTypeTranslation(paramsList, serviceType);
    const realEstateRoomsCount = getRealEstateRoomsCountTranslation(paramsList, roomsCount);
    const districtSlug = district ? `, ${district.name} ${i18n.t('DISTRICT').toLowerCase()}` : '';
    return (
      <div className={classes.objectParams}>
        <LocationOn className={classes.locationIcon}/>
        <span>{city.name}{districtSlug}</span>
        <span className={classes.roundDivider}></span>
        <span>{realEstateServiceType}</span>
        {realEstateSubtype &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{realEstateSubtype}</span>
          </Fragment>
        }
        {roomsCount &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{realEstateRoomsCount} ком.</span>
          </Fragment>}
        {area &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{area} кв.м.</span>
          </Fragment>}
        {floor && flooring &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{floor}/{flooring}</span>
          </Fragment>}
        {type === paramsList.objectType.HOUSE_OR_COUNTRY_HOUSE && flooring &&
          <Fragment>
            <span className={classes.roundDivider}></span>
            <span>{flooring}</span>
          </Fragment>}
      </div>);
  };

  const renderDescription = () => {
    const { description = '' } = object;

    return (
      <div className={classes.objectDescription}>
        <TextTruncate text={description || ''}/>
      </div>);
  };

  const renderControls = () => {
    const { id } = object;
    const editButton = <EditButton classes={classes} id={id}/>;
    const deleteButton = (
      <Button className={classes.controlsButton} onClick={handleDelete}>
        <DeleteOutline/>
      </Button>
    );
    const favouriteButton = (
      <Button
        onClick={handleOnFavouriteButtonClick}
        className={classes.controlsButton}
      >
        {isFavourite ? <Favorite style={{ fill: '#337ab7' }} /> : <FavoriteBorder />}
      </Button>
    );

    return (
      <div className={classes.controls}>
        {isOwnerList ?
          <Fragment>
            {editButton}
            {deleteButton}
          </Fragment> : favouriteButton
        }
      </div>);
  };

  const renderContacts = () => {
    return <ClientContacts client={object.client}/>;
  };

  const renderTags = () => {
    return <TagsList tags={object.tags} onTagClick={onTagClick}/>;
  };

  const { id, totalPriceUah, totalPriceUsd, title, virtualTourUrl, isMyObject = false } = object;

  const priceByCurrency = formatPriceNumber(totalPriceUah || totalPriceUsd || 500000);
  const currencyLabel = totalPriceUsd ? i18n.t('CURRENCY_USD_LITERAL') : i18n.t('CURRENCY_UAH_LITERAL');
  const titleClasses = cn({
    [classes.objectTitle]: true,
    [classes.titleYellow]: isMyObject,
  });
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <div className={classes.objectCard}>
        <div className={classes.sliderWrap}>
          <ChipByType
            paramsList={paramsList}
            object={object}
          />
          {virtualTourUrl && <div className={classes.virtualTourChip}>{i18n.t('3D_TOUR_LANG')}</div>}
          <CustomSlider key={id} images={object.photos} objectId={object.id}/>
          {renderControls()}
        </div>
        <Link target="_blank" className={titleClasses} to={`/real-estate-object/${object.id}`}>
          <span>{title}</span>
        </Link>
        <Link target="_blank" to={`/real-estate-object/${object.id}`}>
          {renderObjectParams()}
          {renderDescription()}
        </Link>
        <div className={classes.objectPrice}>
          <strong>{priceByCurrency} {currencyLabel}</strong>
          {isOwnerList && <ClipObjectFiltersCountWidget realEstateObject={object} />}
        </div>
        {renderTags()}
        {renderContacts()}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(ObjectCard);

EditButton.propTypes = {
  id: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

ObjectCard.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object,
  isOwnerList: PropTypes.bool,
  isFavourite: PropTypes.bool,
  paramsList: PropTypes.object,
  onTagClick: PropTypes.func,
  onDelete: PropTypes.func,
  addRealEstateToFavorite: PropTypes.func,
  removeRealEstateFromFavorite: PropTypes.func,
};
