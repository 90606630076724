import i18n from '../../../../../common/i18n';
import SelectButtonByFilter from 'realtor/components/common/SelectButtonByFilter/SelectButtonByFilter';
import React from 'react';
import PropTypes from 'prop-types';

export const ConditionFilter = ({ filtersList, filter, onFilterChange, onMultiSelectAll, isMulti = false }) => {
  const filterName = 'objectCondition';
  const buttonTitlePrefix = 'FILTER_REAL_ESTATE_FLOR_CONDITION_';
  const header = `${i18n.t('FILTER_REAL_ESTATE_FLOR_CONDITION')}:`;

  return (
    <SelectButtonByFilter
      filter={filter}
      filtersList={filtersList}
      filterName={filterName}
      buttonTitlePrefix={buttonTitlePrefix}
      header={header}
      isMulti={isMulti}
      onSelectAll={onMultiSelectAll}
      onSelect={onFilterChange}
    />
  );
};

ConditionFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func,
  isMulti: PropTypes.bool,
};
