import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import i18n from '../../../../../../common/i18n';
import styles from '../styles';
import SelectButtons from '../../../common/SelectButtons/SelectButtons';
import { BaseParams } from './BaseParams';
import { Grid } from '@material-ui/core';
import { getSubtypeList } from 'realtor/helpers/clientFilterParams';

export class GarageParkingParams extends BaseParams {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderSubtypeType() {
    const { filtersList, realEstateObject, onSelect, errors } = this.props;
    const realEstateTypeCollection = getSubtypeList(realEstateObject, filtersList);

    return (
      <SelectButtons
        list={realEstateTypeCollection}
        onSelect={onSelect}
        selectedValue={realEstateObject.objectSubtype}
        name={'objectSubtype'}
        header={i18n.t('FILTER_REAL_ESTATE_SUBTYPE')}
        error={errors.objectSubtype}
      />
    );
  }

  renderFullParamsList() {
    return (
      <Fragment>
        <Grid sm={12} container>
          { this.renderSubtypeType() }
        </Grid>
        <Grid item sm={6} xs={12} md={12} lg={12}>
          { this.renderCitiesFilter() }
        </Grid>
        <Grid sm={12} container>
          { this.renderTotalArea() }
        </Grid>
        <Grid sm={12} container>
          { this.renderCurrency() }
        </Grid>
        <Grid sm={12} container>
          { this.renderTotalPrice() }
        </Grid>
        <Grid sm={12} container>
          { this.renderTitle() }
        </Grid>
        <Grid sm={12} container>
          { this.virtualTourLink() }
        </Grid>
        { this.renderDescription() }
        {this.renderNotes()}
      </Fragment>
    );
  }

  render() {
    const { realEstateObject } = this.props;

    return (
      <Grid container>
        <Grid sm={12}>
          { this.renderPartialServiceType() }
        </Grid>
        { realEstateObject.objectPartialServiceType && this.renderFullParamsList() }
      </Grid>
    );
  }
}

export default withStyles(styles)(GarageParkingParams);

GarageParkingParams.propTypes = {
  realEstateObject: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};
