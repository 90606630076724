import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import i18n from '../../../../../common/i18n';
import { FiltersParamsFactory } from './FiltersParams/FiltersParamsFactory';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';

export const CreateFilterForm = ({
  filter,
  filtersList,
  onFilterRealEstateTypeSelect,
  disableTypeSelect,
  citiesList,
  onFilterRealEstateSelect,
  onMultiSelect,
  onCityChange,
  onDistrictChange,
  errors,
  onMultiSelectAll,
  onFilterServiceSelect,

}) => {
  const renderTypeSelect = () => {
    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_TYPE');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onFilterRealEstateTypeSelect}
        disabled={disableTypeSelect}
      />
    );
  };

  const renderParamsByType = () => {
    return (<FiltersParamsFactory
      filtersList={filtersList}
      filter={filter}
      onSelect={onFilterRealEstateSelect}
      citiesList={citiesList}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      onMultiSelect={onMultiSelect}
      onMultiSelectAll={onMultiSelectAll}
      onFilterServiceSelect={onFilterServiceSelect}
      errors={errors}
      disableServiceType={disableTypeSelect}
    />);
  };

  return (
    <div>
      <h2>{i18n.t('INFORMATION_ABOUT_REAL_ESTATE')}</h2>
      <form>
        {renderTypeSelect()}
        {renderParamsByType()}
      </form>
    </div>
  );
};

export default withStyles(styles)(CreateFilterForm);

CreateFilterForm.propTypes = {
  filter: PropTypes.object,
  errors: PropTypes.object,
  onMultiSelect: PropTypes.func.isRequired,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array.isRequired,
  onFilterRealEstateTypeSelect: PropTypes.func.isRequired,
  onFilterRealEstateSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func,
  onFilterServiceSelect: PropTypes.func,
  disableTypeSelect: PropTypes.bool,
};
