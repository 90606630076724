import i18n from '../../../../../common/i18n';
import SelectButtonByFilter from 'realtor/components/common/SelectButtonByFilter/SelectButtonByFilter';
import React from 'react';
import PropTypes from 'prop-types';

export const CurrencyFilter = ({ filtersList, filter, onFilterChange }) => {
  const filterName = 'currency';
  const buttonTitlePrefix = 'CURRENCY_';
  const header = `${i18n.t('CURRENCY')}:`;

  return (
    <SelectButtonByFilter
      filter={filter}
      filtersList={filtersList}
      filterName={filterName}
      buttonTitlePrefix={buttonTitlePrefix}
      header={header}
      onSelect={onFilterChange}
    />
  );
};

CurrencyFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
