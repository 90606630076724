import { get } from 'lodash';

export class CityService {

  static getCityFormRegions(regions, cityId) {
    const cities = regions.reduce((accumulator, region) => {
      return [...accumulator, ...get(region, 'cities', [])];
    }, []);

    return cities.find((city) => city.id === cityId);
  }

  static getRegion(regions, regionId) {
    return regions.find(({ id }) => id === regionId);
  }
}
