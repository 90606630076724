export const ON_REDIRECT = 'ON_REDIRECT';

export const onRedirect = (path) => {
  return {
    type: ON_REDIRECT,
    path,
  };
};

export const redirectTo = (path) => {
  return (dispatch) => {
    return dispatch(onRedirect(path));
  };
};

