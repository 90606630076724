/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { get } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import AuthPageLayer from '../../layears/AuthPageLayer/AuthPageLayer';
import LoginForm from '../../forms/LoginForm/LoginForm';
import i18n from '../../../../i18n';
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import AuthFooterLayer from '../../layears/AuthFooterLayer/AuthFooterLayer';
import { mapLoginErrors } from '../../../helpers/errorsMapper';

export class LoginPage extends PureComponent {
  state = {
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    const { error, errorCodes } = props;
    const errorCode = get(error, 'code');

    const validationErrors = errorCode ? mapLoginErrors(errorCodes, error) : {};

    return {
      ...state,
      errors: validationErrors,
    };
  }

  renderHeader = () => {
    return (
      <FormHeaderLayer title={i18n.t('ENTER')}>
        {`${i18n.t('DONT_NAVE_ACCOUNT')} `}
        <Link to="/registration" component={RouterLink}>
          {i18n.t('REGISTRATION')}
        </Link>
      </FormHeaderLayer>
    );
  }

  render() {
    const { login, inProgress, classes, dropAuthorizationErrors } = this.props;
    const { errors } = this.state;
    return (
      <AuthPageLayer>
        {this.renderHeader()}
        <hr size={1} className={classes.hr}/>
        <LoginForm login={login} inProgress={inProgress} errors={errors} onDropErrors={dropAuthorizationErrors}/>
        <AuthFooterLayer confirmText={i18n.t('AUTHORIZATION_CONFIRM_TEXT')}/>
      </AuthPageLayer>
    );
  };
};

export default withStyles(styles)(LoginPage);

LoginPage.propTypes = {
  inProgress: PropTypes.bool,
  error: PropTypes.object,
  classes: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  errorCodes: PropTypes.object,
  dropAuthorizationErrors: PropTypes.func.isRequired,
};
