import React, { Fragment, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import i18n from '../../../../../../common/i18n';
import { CircularProgressBox } from '../../../layears/CircularProgressBox/CircularProgressBox';
import CreateClientWithFiltersForm from '../../../forms/CreateClientWithFiltersForm/CreateClientWithFiltersForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';

const UpdateClientPage = ({
  clientId,
  fetchClient,
  classes,
  updateClient,
  client,
  appConfigurations,
  fetchTagsList,
  tag,
  createNewTag,
  dropCreatedTag,
  dropErrors,
  error,
  inProgress,
  restoreClient,
}) => {
  useEffect(() => {
    setPageTitle(i18n.t('UPDATE_CLIENT'));
  }, []);

  useEffect(() => {
    fetchClient(clientId);
  }, [clientId]);

  const renderHeader = () => {
    return (
      <RealtorPageHeader>
        <div className={classes.headerBox}>
          <h1>{i18n.t('UPDATE_CLIENT')}</h1>
        </div>
      </RealtorPageHeader>
    );
  };

  const handleClientSave = (clientData) => {
    return updateClient(clientId, clientData);
  };

  const renderForm = () => {
    const { filters, ...clientData } = client.toFormEntity();
    const [filter] = filters;

    const fatsData = {
      ...tag,
      tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
    };

    return (
      <Fragment>
        <Paper className={classes.paper} elevation={0}>
          <div>
            <CreateClientWithFiltersForm
              dropCreatedTag={dropCreatedTag}
              onConfirm={handleClientSave}
              client={clientData}
              filter={ filter || {} }
              appConfigurations={ appConfigurations }
              confirmButtonText={i18n.t('SAVE')}
              disableTypeSelect={filter && Boolean(filter.objectType)}
              onLoadMoreTags={fetchTagsList}
              onAddNewTag={createNewTag}
              tag={fatsData}
              errors={error}
              dropErrors={dropErrors}
              isSaveButtonDisabled={inProgress}
              onConfirmRestore={restoreClient}
            />
          </div>
        </Paper>
      </Fragment>
    );
  };

  const renderLoader = () => {
    return <CircularProgressBox />;
  };

  const renderContent = () => {
    return client ? renderForm() : renderLoader();
  };

  return (
    <Fragment>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        <h2>{i18n.t('INFORMATION_ABOUT_CLIENT')}</h2>
        { renderContent() }
      </Paper>
    </Fragment>
  );
};

UpdateClientPage.propTypes = {
  classes: PropTypes.object,
  inProgress: PropTypes.bool.isRequired,
  fetchClient: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  createNewTag: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  client: PropTypes.object,
  appConfigurations: PropTypes.object,
  dropErrors: PropTypes.func.isRequired,
  error: PropTypes.object,
  restoreClient: PropTypes.func.isRequired,
};

export default withStyles(styles)(UpdateClientPage);
