import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import i18n from '../../../../../common/i18n';
import { get, set } from 'lodash';
import { Autocomplete } from '@material-ui/lab';

const DISTRICT_NAME = 'district';

const DistrictField = ({
  districtsList,
  selectedDistrict,
  districtHeader,
  error,
  classes,
  onDistrictChange,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!selectedDistrict){
      return setValue(null);
    }

    const options = getDistrictOptions(districtsList);
    const selectedOption = options.find((opt) => opt.id === selectedDistrict);

    if (districtsList && districtsList.length && selectedOption.name !== get(value, 'name')) {
      return setValue({
        id: selectedOption.id,
        name: selectedOption.name,
      });
    }
  }, [districtsList, selectedDistrict]);

  const getDistrictOptions = (districtsList) => {
    return districtsList.map((district) =>
      ({
        id: district.id,
        name: district.name,
      }));
  };

  const getFieldLabel = () => {
    return districtHeader ?
      <h3>{districtHeader}</h3> :
      <label id={'district'}>{i18n.t('DISTRICT')}</label>;
  };

  const handleInputValueChange = (inputValue) => {
    return setInputValue(inputValue);
  };

  const options = getDistrictOptions(districtsList);
  const label = getFieldLabel();

  return (
    <FormControl fullWidth={true}>
      {label}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => onDistrictChange(newValue)}
        className={classes.districtAutocomplete}
        inputValue={inputValue}
        name={DISTRICT_NAME}
        onInputChange={(event, newInputValue) => {
          handleInputValueChange(newInputValue);
        }}
        options={options}
        fullWidth
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={
          (params) => {
            set(params, 'inputProps.autoComplete', 'chrome-off');

            return (
              <TextField
                variant="outlined"
                {...params}
                helperText={error}
                error={!!error}
              />
            );
          }
        }
      />
      {!!error && <FormHelperText error={error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default withStyles(style)(DistrictField);

DistrictField.propTypes = {
  districtsList: PropTypes.array,
  onDistrictChange: PropTypes.func,
  selectedDistrict: PropTypes.number,
  error: PropTypes.string,
  districtHeader: PropTypes.string,
  classes: PropTypes.object,
};
