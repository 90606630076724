const drawerWidth = 240;
export default (theme) => {
  return {
    header: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    root: {
      display: 'flex',
      height: '100%',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `${theme.spacing(7) + 1}px`,
      [theme.breakpoints.up('sm')]: {
        width: `${theme.spacing(9) + 1}px`,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: `0 ${theme.spacing(1)}px`,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    shareObjectButton: {
      marginLeft: theme.spacing(2),
    },
    rightBar: {
      display: 'flex',
      flexDirection: 'row-reverse',
      flexGrow: 1,
    },
    '@media (max-width: 900px)': {
      mobileContentBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1,
        top: 0,
      },
    },
  };
};
