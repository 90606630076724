import image from '../../../../../../public/images/registration_phone.png';

export default (theme) => {
  const authWrapper = {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  if (theme.isMobile) {
    authWrapper.flexDirection = 'column';
  }

  return {
    paper: {
      background: '../../../public/registration_phone.png',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    authWrapper,
    authBox: {
      width: '90%', // Fix IE 11 issue.
      maxWidth: '510px',
      background: theme.palette.background.paper,
      padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      boxSizing: 'border-box',
      overflow: 'auto',
      maxHeight: '95%',
      height: 'auto',
    },
  };
};
