import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClientsList from 'realtor/components/common/ClientsList/ClientList';

import styles from './styles';
import i18n from '../../../../../../common/i18n';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';

const ORDER_LIST = {
  FROM_NEWEST_TO_OLDER: {
    order: 'created_at',
    dir: 'DESC',
  },
  FROM_OLDER_TO_NEWEST: {
    order: 'created_at',
    dir: 'ASC',
  },
};

const ORDER_KEYS = Object.keys(ORDER_LIST);

export const ClientList = ({ fetchMyTotal, inProgress, totalCount, dropTotalCount, ...props }) => {
  const pageTitle = i18n.t('MY_CLIENTS');

  useEffect(() => () => {
    dropTotalCount();
  }, []);

  useEffect(() => {
    setPageTitle(pageTitle);
    fetchMyTotal();
  },[]);

  if (inProgress || totalCount === null) {
    return (<CircularProgressBox/>);
  }

  return (<ClientsList
    {...props}
    totalCount={totalCount}
    pageTitle={pageTitle}
    isOwnerList={true}
    orderList={ORDER_LIST}
    orderKeys={ORDER_KEYS}
  />);
};

ClientList.propTypes = {
  classes: PropTypes.object,
  clientList: PropTypes.array,
  citiesList: PropTypes.array,
  tagsList: PropTypes.array,
  fetchClients: PropTypes.func,
  fetchTotalAndList: PropTypes.func,
  fetchMyTotal: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteClientFilter: PropTypes.func,
  dropTotalCount: PropTypes.func,
  paramsList: PropTypes.object,
  fetchTagsList: PropTypes.string.isRequired,
};

export default withStyles(styles)(ClientList);
