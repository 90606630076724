export default (theme) => {
  return {
    controlButton: {
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgb(0 0 0 / 14%)',
      borderRadius: '9px',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'none',
      color: '#5F5F5F',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    controlBox: {
      marginLeft: theme.spacing(2),
    },
    filtersButtonActive: {
      backgroundColor: 'rgba(51, 122, 183, 0.9)!important',
    },
    paramsFilter: {
      display: 'flex',
      alignItems: 'center',
    },
    filterButton: {
      backgroundColor: 'rgba(245, 132, 31, 0.8)',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(245, 132, 31, 0.9)',
      },
    },
    orderButton: {
      height: theme.spacing(6),
      width: '220px',
      marginRight: theme.spacing(2),
      color: '#5F5F5F',
      paddingRight: theme.spacing(1),
      '& fieldset': {
        border: 'none',
      },
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingRight: theme.spacing(1),
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    searchBlock: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    search: {
      width: '100%',
      padding: '3.5px 17px',
    },
    controlActions: {
      display: 'flex',
    },
    paramsFormControl: {
      flexDirection: 'row',
    },
    controlIcons: {
      color: '#C1C1C1',
    },
    '@global': {
      '.MuiOutlinedInput-input:placeholder-shown': {
        textOverflow: 'ellipsis',
      },
    },
    '@media (max-width: 599px)': {
      paramsFormControl: {
        flexDirection: 'column',
      },
      controlActions: {
        marginTop: theme.spacing(2),
      },
      orderButton: {
        width: 'auto',
        flex: 1,
      },
      searchBlock: {
        marginRight: 0,
      },
    },
  };
};
