export default () => {
  return {
    imageBox: {
      height: '240px',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  };
};
