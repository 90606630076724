export class City {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get name() {
    return this.props.name;
  }

  set name(name) {
    this.props.name = name;
  }

  get lat() {
    return this.props.lat;
  }

  set lat(lat) {
    this.props.lat = lat;
  }

  get lng() {
    return this.props.lng;
  }

  set lng(lng) {
    this.props.lng = lng;
  }

  get regionId() {
    return this.props.regionId;
  }

  set regionId(regionId) {
    this.props.regionId = regionId;
  }

  get districts() {
    return this.props.districts;
  }

  set districts(districts) {
    this.props.districts = districts;
  }

  get region() {
    return this.props.region;
  }

  set region(region) {
    this.props.region = region;
  }

  toUiEntity() {
    return {
      ...this.props,
    };
  }
}
