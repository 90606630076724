export class TelephoneNumber {
  props = {
    id: null,
    telephoneNumber: null,
  }
  set id(id) {
    this.props.id = id;
  }

  get id() {
    return this.props.id;
  }
  set telephoneNumberFromFormData(telephoneNumber) {
    this.props.telephoneNumber = this._getTelephoneNumberFromFormDataString(telephoneNumber);
  }

  set telephoneNumberFromContract(telephoneNumber) {
    this.props.telephoneNumber = telephoneNumber;
  }

  _getTelephoneNumberFromFormDataString = (telephoneNumber) => {
    return telephoneNumber.replace('(', '').replace(')','').replace('+','').split(' ').join('');
  };

  getTelephoneNumberFromContract = () => {
    return this.props.telephoneNumber;
  }

  getTelephoneNumberView = () => {
    const t = this.props.telephoneNumber.split('');
    return `+${t[0]}${t[1]} (${t[2]}${t[3]}${t[4]}) ${t[5]}${t[6]}${t[7]}-${t[8]}${t[9]}-${t[10]}${t[11]}`;
  }

  getViberUrl = () => {
    return `viber://chat?number=%2B${this.props.telephoneNumber}`;
  };

  getTelegramUrl = () => {
    return `https://t.me/+${this.props.telephoneNumber}`;
  };

  getTelephoneNumberStringForForm = () => {
    const t = this.props.telephoneNumber.split('');

    return `+${t[0]}${t[1]}(${t[2]}${t[3]}${t[4]}) ${t[5]}${t[6]}${t[7]} ${t[8]}${t[9]} ${t[10]}${t[11]}`;
  };

  getTelephoneNumberObjectForForm = () => {
    return {
      id: this.props.id,
      telephoneNumber: this.getTelephoneNumberStringForForm(),
    };
  }

  getCallLink = () => {
    return `tel:+${this.props.telephoneNumber}`;
  }

  getFullProps = () => {
    return this.props;
  }
}
