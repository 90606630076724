import { BaseFilter } from './BaseFilter';

export class ApartmentFilter extends BaseFilter {
  get areaFrom() {
    return this.props.areaFrom;
  }

  set areaFrom(areaFrom) {
    this.props.areaFrom = areaFrom;
  }

  get areaTo() {
    return this.props.areaTo;
  }

  set areaTo(areaTo) {
    this.props.areaTo = areaTo;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  get kitchenAreaFrom() {
    return this.props.kitchenAreaFrom;
  }

  set kitchenAreaFrom(kitchenAreaFrom) {
    this.props.kitchenAreaFrom = kitchenAreaFrom;
  }

  get kitchenAreaTo() {
    return this.props.kitchenAreaTo;
  }

  set kitchenAreaTo(kitchenAreaTo) {
    this.props.kitchenAreaTo = kitchenAreaTo;
  }

  get objectMaterial() {
    return this.props.objectMaterial;
  }

  set objectMaterial(objectMaterial) {
    this.props.objectMaterial = objectMaterial;
  }


  get floorFrom() {
    return this.props.floorFrom;
  }
  set floorFrom(floorFrom) {
    this.props.floorFrom = floorFrom;
  }
  get floorTo() {
    return this.props.floorTo;
  }
  set floorTo(floorTo) {
    this.props.floorTo = floorTo;
  }

  get objectCondition() {
    return this.props.objectCondition;
  }

  set objectCondition(objectCondition) {
    this.props.objectCondition = objectCondition;
  }

  get squarePriceFrom() {
    return this.props.squarePriceFrom;
  }

  set squarePriceFrom(squarePriceFrom) {
    this.props.squarePriceFrom = squarePriceFrom;
  }

  get squarePriceTo() {
    return this.props.squarePriceTo;
  }

  set squarePriceTo(squarePriceTo) {
    this.props.squarePriceTo = squarePriceTo;
  }

  get roomsCount() {
    return this.props.roomsCount;
  }

  set roomsCount(roomsCount) {
    this.props.roomsCount = roomsCount;
  }

  get isMortgage() {
    return this.props.isMortgage;
  }

  set isMortgage(isMortgage) {
    this.props.isMortgage = isMortgage;
  }

  get ignoreLastFloor() {
    return this.props.ignoreLastFloor;
  }

  set ignoreLastFloor(ignoreLastFloor) {
    this.props.ignoreLastFloor = ignoreLastFloor;
  }

  get isLastFloor() {
    return this.props.isLastFloor;
  }

  set isLastFloor(isLastFloor) {
    this.props.isLastFloor = isLastFloor;
  }

  /* eslint-disable complexity */
  toServerEntity() {
    return {
      realEstateSubtype: this.props.objectSubtype ? Array.from(this.props.objectSubtype) : null,
      areaFrom: this.props.areaFrom ? Number(this.props.areaFrom) : 0,
      areaTo: this.props.areaTo ? Number(this.props.areaTo) : null,
      kitchenAreaFrom: this.props.kitchenAreaFrom ? Number(this.props.kitchenAreaFrom) : null,
      kitchenAreaTo: this.props.kitchenAreaTo ? Number(this.props.kitchenAreaTo) : null,
      floorFrom: this.props.floorFrom ? Number(this.props.floorFrom) : null,
      floorTo: this.props.floorTo ? Number(this.props.floorTo) : null,
      livingAreaFrom: this.props.livingAreaFrom ? Number(this.props.livingAreaFrom) : null,
      livingAreaTo: this.props.livingAreaTo ? Number(this.props.livingAreaTo) : null,
      squarePriceFrom: this.props.squarePriceFrom ? Number(this.props.squarePriceFrom) : null,
      squarePriceTo: this.props.squarePriceTo ? Number(this.props.squarePriceTo) : null,
      realEstateRoomsCount: this.props.roomsCount ? Array.from(this.props.roomsCount) : null,
      realEstateCondition: this.props.objectCondition ? Array.from(this.props.objectCondition) : null,
      realEstateMaterial: this.props.objectMaterial ? Array.from(this.props.objectMaterial) : null,
      realEstateServiceType: this.props.objectServiceType ? Number(this.props.objectServiceType) : null,
      isMortgage: this.props.isMortgage,
      ignoreLastFloor: this.props.ignoreLastFloor,
      isLastFloor: this.props.isLastFloor,
      ...this.toBaseServerEntity(),
    };
  }
}
