export default (theme) => {
  return {
    fakeSearch: {
      border: '1px solid #ccc',
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  };
};
