import { fade } from '@material-ui/core/styles';

export default (theme) => {
  return {
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    accordion: {
      marginTop: theme.spacing(3),
    },
    panelPlaceholder: {
      backgroundColor: theme.palette.action.selected,
    },
    selectedItem: {
      fontWeight: theme.typography.fontWeightBold,
    },
    regularItem: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    submitFilters: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuRoot: {
      width: '300px',
    },
  };
};
