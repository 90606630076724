import i18n from '../../i18n';

export const mapProfileConfirmationErrors = (errorsList, error) => {
  const validationErrors = {};

  if (error.code === errorsList.TELEPHONE_NUMBERS_ALREADY_EXIST) {
    validationErrors.moreTelephoneNumbers = error.source.map((errorData) => {
      return errorData ? i18n.t('TELEPHONE_NUMBER_ALREADY_EXIST') : null;
    });
  }

  return validationErrors;
};
