import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';

const getUserProfile = (state) => {
  return get(state, 'user.profile');
};

const getAppConfiguration = (state) => {
  return get(state, 'appConfigurations.objectFilters');
};

const _isAppInitialized = (profile, appFilters) => {
  return !isEmpty(profile) && !isEmpty(appFilters);
};

export const isAppInitialized = createSelector([getUserProfile, getAppConfiguration], _isAppInitialized);
