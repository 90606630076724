import FavoriteRealEstateResource from 'realtor/resourses/FavoriteRealEstate.resource';
import Alert from 'react-s-alert';
import { FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS } from 'realtor/actions/realEstateObject';

export const SET_SEARCH_OBJECTS_LIST = 'SET_SEARCH_OBJECTS_LIST';

const addToFavoriteSuccess = (realEstateObjectId, list) => {
  const objectsList = list.map((realEstateObject) => {
    if (realEstateObject.id === realEstateObjectId) {
      realEstateObject.isFavourite = true;
    }
    return realEstateObject;
  });

  return { type: SET_SEARCH_OBJECTS_LIST, objectsList };
};

const addSingleObjectToFavouriteSuccess = (realEstateObject) => {
  realEstateObject.isFavourite = true;
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, realEstateObject: { ...realEstateObject } };
};

const removeSingleObjectFromFavouriteSuccess = (realEstateObject) => {
  realEstateObject.isFavourite = false;
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, realEstateObject: { ...realEstateObject } };
};

const removeFromFavoriteSuccess = (realEstateObjectId, list) => {
  const objectsList = list.map((realEstateObject) => {
    if (realEstateObject.id === realEstateObjectId) {
      realEstateObject.isFavourite = false;
    }
    return realEstateObject;
  });

  return { type: SET_SEARCH_OBJECTS_LIST, objectsList };
};

export const addSingleObjectToFavourite = (realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();

    FavoriteRealEstateResource.addRealEstateToFavorite(realEstateObject.id)
      .then(() => {
        dispatch(addSingleObjectToFavouriteSuccess(state.realEstateObject.realEstateObject));
      })
      .catch((error) => {
        Alert.error(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });
  };
};

export const removeSingleObjectToFavourite = (realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();

    FavoriteRealEstateResource.removeRealEstateFromFavorite(realEstateObject.id)
      .then(() => {
        dispatch(removeSingleObjectFromFavouriteSuccess(state.realEstateObject.realEstateObject));
      })
      .catch((error) => {
        Alert.error(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });
  };
};

export const addRealEstateToFavorite = (realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();
    FavoriteRealEstateResource.addRealEstateToFavorite(realEstateObject.id)
      .then(() => {
        dispatch(addToFavoriteSuccess(realEstateObject.id, state.objectsList.list));
      })
      .catch((error) => {
        Alert.error(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });

  };
};

export const removeRealEstateFromFavorite = (realEstateObject) => {
  return (dispatch, getState) => {
    const state = getState();
    FavoriteRealEstateResource.removeRealEstateFromFavorite(realEstateObject.id)
      .then(() => {
        dispatch(removeFromFavoriteSuccess(realEstateObject.id, state.objectsList.list));
      })
      .catch((error) => {
        Alert.error(
          error,
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
      });

  };
};
