import { getListParamCollection, getSubtypeList } from 'realtor/helpers/clientFilterParams';
import i18n from '../../../../../../../../common/i18n';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';

export class ListParamsBuilder {
  constructor(filter, paramsList) {
    this.filter = filter;
    this.paramsList = paramsList;
    this.list = [];
  }

  getParamsListData(params, filterKey, title) {
    let stringValue = '';
    if (params.length === this.filter[filterKey].size) {
      stringValue = i18n.t('ANY');
    } else {
      const listToRender = params.filter((subtype) => {
        return this.filter[filterKey].has(subtype.value);
      });

      listToRender.forEach(({ title }, index) => {
        const separator = index === listToRender.length - 1 ? '' : ',';
        stringValue = `${stringValue}${title}${separator} `;
      });
    }
    return {
      title: i18n.t(title),
      value: stringValue,
    };
  }

  getBooleanTypeParam(title) {
    return {
      title: i18n.t(title),
    };
  }

  // eslint-disable-next-line max-statements
  getFromToParamData(from, to, title, prefix, shouldFormat = false) {
    let stringValue = '';
    let formattedFrom = '';
    let formattedTo = '';

    if (shouldFormat) {
      formattedFrom = from ? formatPriceNumber(from) : '';
      formattedTo = to ? formatPriceNumber(to) : '';
    }

    if (from) {
      if (to) {
        stringValue = `${formattedFrom || from} - `;
      } else {
        stringValue = `${i18n.t('FROM')} ${formattedFrom || from}`;
      }
    }

    if (to) {
      if (from) {
        stringValue = `${stringValue}${formattedTo || to}`;
      } else {
        stringValue = `${i18n.t('TO')} ${formattedTo || to}`;
      }
    }

    return {
      title: i18n.t(title),
      value: `${stringValue} ${i18n.t(prefix)}`,
    };
  }

  addIgnoreLastFloor() {
    if (this.filter.ignoreLastFloor) {
      const param = this.getBooleanTypeParam('IGNORE_LAST_FLOOR');

      this.list.push(param);
    }

    return this;

  }

  addIsMortgage() {
    if (this.filter.isMortgage) {
      const param = this.getBooleanTypeParam('FILTER_REAL_ESTATE_ONLY_MORTGAGE');

      this.list.push(param);
    }

    return this;

  }

  addMaterial() {
    if (this.filter.objectMaterial) {
      const collection = getListParamCollection(this.paramsList, 'objectMaterial', 'FILTER_REAL_ESTATE_MATERIAL_');

      const param = this.getParamsListData(
        collection,
        'objectMaterial',
        'FILTER_REAL_ESTATE_MATERIAL'
      );

      this.list.push(param);
    }

    return this;
  }

  addCondition() {
    if (this.filter.objectCondition) {
      const collection = getListParamCollection(
        this.paramsList,
        'objectCondition',
        'FILTER_REAL_ESTATE_FLOR_CONDITION_'
      );

      const param = this.getParamsListData(
        collection,
        'objectCondition',
        'FILTER_REAL_ESTATE_FLOR_CONDITION'
      );

      this.list.push(param);
    }

    return this;
  }

  addRoomsCount() {
    if (this.filter.roomsCount) {
      const collection = getListParamCollection(
        this.paramsList,
        'roomsCount',
        'FILTER_REAL_ESTATE_ROOMS_COUNT_'
      );

      const param = this.getParamsListData(
        collection,
        'roomsCount',
        'FILTER_REAL_ESTATE_ROOMS_COUNT'
      );

      this.list.push(param);
    }

    return this;
  }

  addTotalArea() {
    if (this.filter.areaFrom || this.filter.areaTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.areaFrom,
          this.filter.areaTo,
          'TOTAL_AREA',
          'AREA_PLACEHOLDER'
        )
      );
    }

    return this;
  }

  addSquarePrice() {
    if (this.filter.squarePriceFrom || this.filter.squarePriceTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.squarePriceFrom,
          this.filter.squarePriceTo,
          'SQUARE_PRICE',
          getCurrencyLabel(this.filter.currency),
          true
        )
      );
    }

    return this;
  }

  addFloor() {
    if (this.filter.floorFrom || this.filter.floorTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.floorFrom,
          this.filter.floorTo,
          'FLOOR'
        )
      );
    }

    return this;
  }

  addFlooring() {
    if (this.filter.flooringFrom || this.filter.flooringTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.flooringFrom,
          this.filter.flooringTo,
          'FLOORING'
        )
      );
    }

    return this;
  }

  addKitchenArea() {
    if (this.filter.kitchenAreaFrom || this.filter.kitchenAreaTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.kitchenAreaFrom,
          this.filter.kitchenAreaTo,
          'KITCHEN_AREA',
          'AREA_PLACEHOLDER'
        )
      );
    }

    return this;
  }

  addLivingArea() {
    if (this.filter.livingAreaFrom || this.filter.livingAreaTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.livingAreaFrom,
          this.filter.livingAreaTo,
          'LIVING_AREA',
          'AREA_PLACEHOLDER'
        )
      );
    }

    return this;
  }
  addLandArea() {
    if (this.filter.landAreaFrom || this.filter.landAreaTo) {
      this.list.push(
        this.getFromToParamData(
          this.filter.landAreaFrom,
          this.filter.landAreaTo,
          'LAND_AREA',
          'LAND_AREA_PLACEHOLDER'
        )
      );
    }

    return this;
  }

  addSubtype() {
    const subtypeList = getSubtypeList(this.filter, this.paramsList);
    if (subtypeList && subtypeList.length) {
      let stringValue = '';
      if (subtypeList.length === this.filter.objectSubtype.size) {
        stringValue = i18n.t('ANY');
      } else {
        const subtypeListToRender = subtypeList.filter((subtype) => {
          return this.filter.objectSubtype.has(subtype.value);
        });

        subtypeListToRender.forEach(({ title }, index) => {
          const separator = index === subtypeListToRender.length - 1 ? '' : ',';
          stringValue = `${stringValue}${title}${separator} `;
        });
      }

      this.list.push({
        title: i18n.t('REAL_ESTATE_SUBTYPE'),
        value: stringValue,
      });
    }

    return this;
  }

  getList() {
    return this.list;
  }
}
