import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { DialogContent, Slide, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../common/i18n';
import Alert from 'react-s-alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShareObjectsDialog = (props) => {
  return (
    <Dialog
      open={props.isDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{i18n.t('COPY_LINK_TITLE')}</DialogTitle>
      <DialogContent>
        {props.shareLink && <TextField
          fullWidth
          name={'share'}
          onFocus={(event) => event.target.select()}
          value={ props.shareLink }
        />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            Alert.success(
              i18n.t('LINK_COPY_SUCCESS'),
              { position: 'top-right', effect: 'slide', timeout: 3000 }
            );
            navigator.clipboard.writeText(props.shareLink);
            return props.onDialogClose();
          }}
          color="primary"
        >
          {i18n.t('COPY')}
        </Button>
        <Button
          variant="contained"
          onClick={props.onDialogClose}
          color="default"
        >
          {i18n.t('CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareObjectsDialog.propTypes = {
  onDialogClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  shareLink: PropTypes.string,
};

export default ShareObjectsDialog;
