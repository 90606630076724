import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../../../../common/i18n';

const NoClientsFound = ({ classes }) => {
  return (
    <div className={classes.emptyStateBox}>
      <Typography className={classes.noClientsText}>{i18n.t('NO_CLIENTS_FOUND')} :(</Typography>
    </div>
  );
};

NoClientsFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default NoClientsFound;
