const VISIBLE_CODE_LENGTH = 4;

export class ItemCode {
  constructor(code) {
    this.code = code;
  }

  getFullCode() {
    return `CID${this.code}`;
  }

  getVisibleCode() {
    const stringCode = String(this.code);

    return stringCode.slice(stringCode.length - VISIBLE_CODE_LENGTH);
  }
}
