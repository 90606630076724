import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import i18n from '../../../../i18n';
import styles from './styles';
import { NumberFormatCustom } from '../PriceNumberMask/PriceNumberMask';
import cn from 'classnames';

export const PriceRangeInput = ({
  nameFrom,
  nameTo,
  valueFrom,
  valueTo,
  classes,
  placeholderFrom,
  placeholderTo,
  label,
  error,
  currencyPrefix,
  header,
  errorTo = '',
  errorFrom = '',
  onFieldChange,
}) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    return onFieldChange(name, value);
  };

  const styleFrom = valueFrom ? { paddingLeft: '4px' } : {};
  const styleTo = valueTo ? { paddingLeft: '4px' } : {};

  const labelData = header ? <h3>{header}</h3> : <label>{label}</label>;

  const fieldErrorClasses = cn(
    classes.errorText,
    classes.fieldErrorText
  );

  return (
    <div className={classes.rangeTextInputBox}>
      {labelData}
      <div className={classes.rangeTextField}>
        <span>{i18n.t('FROM')}</span>
        <FormControl
          className={classes.rangeTextFieldTextInput}
          margin={'none'}
          helperText={!!errorFrom || !!error}
          error={!!errorFrom || !!error}
        >
          <TextField
            placeholder={placeholderFrom}
            variant="outlined"
            margin="none"
            required
            fullWidth
            id={nameFrom}
            name={nameFrom}
            onChange={handleInputChange}
            value={valueFrom}
            inputProps={{
              min: 0,
              style: styleFrom,
            }}
            error={!!errorFrom || !!error}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: valueFrom && <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
            }}
          />
          <FormHelperText className={fieldErrorClasses}>{errorFrom}</FormHelperText>
        </FormControl>
        <div className={classes.dash}></div>
        <span>{i18n.t('TO')}</span>
        <FormControl
          className={classes.rangeTextFieldTextInput}
          margin={'none'}
          helperText={!!errorTo || !!error}
          error={!!errorTo || !!error}
        >
          <TextField
            placeholder={placeholderTo}
            variant="outlined"
            margin="none"
            required
            fullWidth
            id={nameTo}
            name={nameTo}
            onChange={handleInputChange}
            value={valueTo}
            inputProps={{
              min: 0,
              style: styleTo,
            }}
            error={!!errorTo || !!error}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: valueTo && <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
            }}
          />
          <FormHelperText className={fieldErrorClasses}>{errorTo}</FormHelperText>
        </FormControl>
      </div>
      <FormControl>
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(PriceRangeInput);

PriceRangeInput.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  nameFrom: PropTypes.string,
  nameTo: PropTypes.string,
  valueFrom: PropTypes.number,
  valueTo: PropTypes.number,
  placeholderFrom: PropTypes.string,
  placeholderTo: PropTypes,
  classes: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.string,
  currencyPrefix: PropTypes.string,
  header: PropTypes.string,
  errorFrom: PropTypes.string,
  errorTo: PropTypes.string,
};
