import i18n from '../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';

export const KitchenAreaRangeFilter = ({ onSelect, filter, errors }) => {
  return (
    <RangeTextInput
      valueFrom={filter.kitchenAreaFrom}
      valueTo={filter.kitchenAreaTo}
      nameFrom={'kitchenAreaFrom'}
      nameTo={'kitchenAreaTo'}
      onFieldChange={onSelect}
      placeholderFrom={i18n.t('AREA_PLACEHOLDER')}
      placeholderTo={i18n.t('AREA_PLACEHOLDER')}
      label={i18n.t('KITCHEN_AREA')}
      error={errors.kitchenArea}
      errorFrom={errors.kitchenAreaFrom}
      errorTo={errors.kitchenAreaTo}
    />
  );
};

KitchenAreaRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
