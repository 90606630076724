
export class BaseRealEstateObject {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get objectType() {
    return this.props.objectType;
  }

  set objectType(objectType) {
    this.props.objectType = objectType;
  }

  get cityId() {
    return this.props.cityId;
  }

  set cityId(cityId) {
    this.props.cityId = cityId;
  }

  get districtId() {
    return this.props.districtId;
  }

  set districtId(districtId) {
    this.props.districtId = districtId;
  }

  get description() {
    return this.props.description;
  }

  set description(description) {
    this.props.description = description;
  }

  get notes() {
    return this.props.notes;
  }

  set notes(notes) {
    this.props.notes = notes;
  }

  get currency() {
    return this.props.currency;
  }

  set currency(currency) {
    this.props.currency = currency;
  }

  get totalPrice() {
    return this.props.totalPrice;
  }

  set totalPrice(totalPrice) {
    this.props.totalPrice = totalPrice;
  }

  get objectServiceType() {
    return this.props.objectServiceType;
  }

  set objectServiceType(objectServiceType) {
    this.props.objectServiceType = objectServiceType;
  }

  get livingArea() {
    return this.props.livingArea;
  }

  set livingArea(livingArea) {
    this.props.livingAreaFrom = livingArea;
  }

  get code() {
    return this.props.code;
  }

  set code(code) {
    this.props.code = code;
  }

  get location() {
    return this.props.location;
  }

  set location(location) {
    this.props.location = location;
  }

  get clientId() {
    return this.props.clientId;
  }

  set clientId(clientId) {
    this.props.clientId = clientId;
  }

  get client() {
    return this.props.client;
  }

  set client(client) {
    return this.props.client = client;
  }

  get virtualTourUrl() {
    return this.props.virtualTourUrl;
  }

  set virtualTourUrl(virtualTourUrl) {
    return this.props.virtualTourUrl = virtualTourUrl;
  }

  get photos() {
    return this.props.photos;
  }

  set photos(photos) {
    return this.props.photos = photos;
  }

  get tags() {
    return this.props.tags;
  }

  set tags(tags) {
    return this.props.tags = tags;
  }

  toBaseServerEntity() {
    return {
      id: this.props.id,
      type: this.props.objectType,
      title: this.props.title,
      cityId: this.props.cityId,
      districtId: this.props.districtId,
      description: this.props.description,
      currency: this.props.currency,
      clientId: this.props.clientId,
      totalPrice: this.props.totalPrice ? Number(this.props.totalPrice) : null,
      virtualTourUrl: this.props.virtualTourUrl,
      notes: this.props.notes,
      photos: this.props.photos,
      tags: this.props.tags,
    };
  }

  toFormEntity() {
    return this.props;
  }
}
