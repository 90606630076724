import i18n from '../../../../../../../../common/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => {
  return {
    formControl: {
      maxWidth: '400px',
    },
  };
};

export const TitleInput = ({ filterErrors , realEstateObject, onInputChange, classes }) => {
  const value = realEstateObject.title;

  return (
    <FormControl
      margin={'none'}
      helperText={filterErrors.title}
      error={filterErrors.title}
      fullWidth={true}
      classes={{ root: classes.formControl }}
    >
      <label>{`${i18n.t('TITLE')}:`}</label>
      <TextField
        variant="outlined"
        margin="none"
        required
        fullWidth
        id={'title'}
        name={'title'}
        onChange={onInputChange}
        value={value}
        error={!!filterErrors.title}
      />
      <FormHelperText>{filterErrors.title}</FormHelperText>
    </FormControl>
  );
};

TitleInput.propTypes = {
  filterErrors: PropTypes.object.isRequired,
  realEstateObject: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(TitleInput);
