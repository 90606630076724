import {
  REGISTRATION_SUCCESS,
  REGISTRATION_START,
  REGISTRATION_FAIL,
  DROP_ERRORS_SUCCESS,
} from '../actions/userRegistration';
import ConfirmRegistrationService from '../../../common/sevices/confirmRegistrationService';

const initialState = {
  inProgress: false,
  errors: null,
  registrationSuccess: false,
};

// eslint-disable-next-line complexity
export const registration = (state = initialState, action) => {
  switch (action.type) {
  case REGISTRATION_START:
    return {
      ...state,
      errors: null,
      inProgress: true,
    };
  case REGISTRATION_FAIL:
    return {
      ...state,
      errors: action.errors,
      inProgress: false,
    };
  case REGISTRATION_SUCCESS:
    ConfirmRegistrationService.saveConfirmUserToken(action.data.token);
    location.href = '/confirm-registration';
    return {
      ...state,
      inProgress: false,
      registrationSuccess: true,
      errors: null,
    };
  case DROP_ERRORS_SUCCESS:
    return {
      ...state,
      errors: null,
    };
  default:
    return state;
  }
};
