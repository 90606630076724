import { get } from 'lodash';
import { DOMAIN } from '../../Config';

export const IMAGE_SIZE = {
  SMALL: 'small',
  ORIGIN: 'origin',
};

export const DEFAULT_IMAGE_URL = `${DOMAIN}/static/images/image-not-found.png`;

export const getObjectPhoto = (photo, size) => {
  const photoUrl = get(photo, `image[${size}]`, null);
  return photoUrl || DEFAULT_IMAGE_URL;
};
