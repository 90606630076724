import React, { Fragment } from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import i18n from '../../../../i18n';
import TelephoneNumberField from '../../../../../common/components/fields/TelephoneNumberField/TelephoneNumberField';

const DEFAULT_NUMBERS_COUNT = 2;
const TELEPHONE_NUMBER_MASK_VALUE = '+38(0__) ___ __ __';

const MoreTelephoneNumbers = ({
  moreTelephoneNumbers,
  classes,
  maxCountOfNumbers = DEFAULT_NUMBERS_COUNT,
  errors,
  onNumbersChange,
}) => {

  const handleAddMoreTelephoneNumber = () => {
    return onNumbersChange([...moreTelephoneNumbers, { telephoneNumber: '', id: null }]);
  };

  const handleMoreTelephoneNumberChange = (index) => {
    return (event) => {
      const { value } = event.target;

      const moreTelephoneNumbersList = [...moreTelephoneNumbers];
      moreTelephoneNumbersList[index] = {
        id: moreTelephoneNumbersList[index].id,
        telephoneNumber: value !== TELEPHONE_NUMBER_MASK_VALUE ? value : '',
      };

      return onNumbersChange(moreTelephoneNumbersList);
    };
  };
  const renderMoreTelephoneNumbers = () => {
    return moreTelephoneNumbers.map((telephoneNumber, key) => {
      const error = errors.moreTelephoneNumbers ? errors.moreTelephoneNumbers[key] : null;

      return (
        <TelephoneNumberField
          value={telephoneNumber.telephoneNumber}
          key={key}
          onFieldChange={handleMoreTelephoneNumberChange(key)}
          error={error}
        />
      );
    });
  };

  return (
    <Fragment>
      { moreTelephoneNumbers.length < maxCountOfNumbers &&
        <Link
          className={classes.addMoreTelephoneNumber}
          onClick={handleAddMoreTelephoneNumber}
        >
          {i18n.t('ADD_MORE_TELEPHONE_NUMBER')}
        </Link>
      }
      { renderMoreTelephoneNumbers() }
    </Fragment>
  );
};

export default withStyles(styles)(MoreTelephoneNumbers);

MoreTelephoneNumbers.propTypes = {
  classes: PropTypes.object.isRequired,
  moreTelephoneNumbers: PropTypes.array,
  maxCountOfNumbers: PropTypes.number,
  errors: PropTypes.object,
  onNumbersChange: PropTypes.func,
};
