/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import {
  Container,
} from '@material-ui/core';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import AccountConfirmationRoutes from 'realtor/routes/AccountConfirmationRoutes';
import NavigationBar from 'realtor/components/layears/NavigationBar/NavigationBar';
import { RealtorRoutes } from 'realtor/routes/RealtorRoutes';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import MobileNavigationBar from 'realtor/components/layears/MobileNavigationBar/MobileNavigationBar';
import clsx from 'clsx';
import ShareObjectsDialog from '../../../../components/Dialogs/ShareObjectsDialog/ShareObjectsDialog';
import { MyObjectsPageContainer } from 'realtor/containers';

export const RealtorCabinet = (
  {
    fetchShareToken,
    fetchUserInfo,
    redirectPath,
    redirectTo,
    userProfile,
    logout,
    classes,
    shareLink,
    isProfileFilled,
    isAppInitialized,
  }
) => {
  useEffect(() => {
    fetchShareToken();
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (redirectPath){
      redirectTo(null);
    }
  }, [redirectPath]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareLinkDialogOpen, setIsShareLinkDialogOpen] = useState(false);
  const [isMenuOpenFromMobile, setIsMenuOpenFromMobile] = useState(false);

  const handleMenuToggle = (isMenuOpenFromMobile) => {
    setIsMenuOpen(!isMenuOpen);
    setIsMenuOpenFromMobile(isMenuOpenFromMobile);
  };

  const handleShareLinkDialogClose = () => {
    setIsShareLinkDialogOpen(false);
  };

  const renderNavigation = () => {
    return (
      <NavigationBar
        isMenuOpen={isMenuOpen}
        onMenuToggle={handleMenuToggle}
        isMenuOpenFromMobile={isMenuOpenFromMobile}
        userProfile={userProfile}
        logout={logout}
      />
    );
  };

  const renderContent = () => {
    return (
      <Router indexRoute={MyObjectsPageContainer}>
        <MobileNavigationBar
          onMenuToggle={handleMenuToggle}
        />
        <div
          className={clsx({
            [classes.mobileContentBackground]: isMenuOpen,
          })}
          onClick={handleMenuToggle}
        />
        <div className={classes.root}>
          <CssBaseline />
          {renderNavigation()}
          <Container className={classes.content}>
            <RealtorRoutes redirectPath={redirectPath}/>
          </Container>
        </div>
        <ShareObjectsDialog
          isDialogOpen={isShareLinkDialogOpen}
          shareLink={shareLink}
          onDialogClose={handleShareLinkDialogClose}
        />
      </Router>
    );
  };

  const renderNotConfirmedContent = () => {
    return (
      <AccountConfirmationRoutes />
    );
  };

  if (!isAppInitialized) {
    return (
      <CircularProgressBox/>
    );
  }

  return isProfileFilled ? renderContent() : renderNotConfirmedContent();
};

export default withStyles(styles)(RealtorCabinet);

RealtorCabinet.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchShareToken: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  shareLink: PropTypes.string,
  isProfileFilled: bool,
  isAppInitialized: bool,
  redirectPath: PropTypes.string,
  redirectTo: PropTypes.func,
  userProfile: PropTypes.object,
};
