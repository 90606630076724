export default (theme) => {
  return {
    tag: {
      color: '#385898',
      cursor: 'pointer',
    },
    clientCardHeader: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      background: '#f1f1f1',
    },
    clientCardHeaderWithAccordion: {
      marginLeft: theme.spacing(1),
    },
    ownClientCardHeader: {
      backgroundColor: 'rgba(245, 203, 105, 1)',
    },
    accordionDetails: {
      display: 'block',
      width: '100%',
      background: '#f8f8f8f8',
    },
    paramsViewListColumn: {
      padding: theme.spacing(1),
    },
    paramsViewListColumnTitle: {
      color: '#767676',
    },
    clientCard: {
      marginBottom: theme.spacing(2),
      border: '1px solid #C1C1C1',
      borderRadius: '8px',
      boxShadow: 'none',
    },
    clientCardHeaderItem: {
      alignItems: 'center',
      display: 'flex',
    },
    socialButton: {
      padding: theme.spacing(1),
    },
    clientTelephoneNumber: {
      marginRight: theme.spacing(2),
    },
    filterControl: {
      justifyContent: 'end',
      order: 4,
    },
    cardControl: {
      justifyContent: 'end',
    },
    filterBox: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    filterValue: {
      background: '#F1F1F1',
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginRight: theme.spacing(1),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    filterDataContainer: {
      background: '#FCFCFC',
    },
    cardControlBox: {
      display: 'flex',
    },
    filterInfoBox: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    clientFilterControlBox: {
      display: 'none',
    },
    '.filterBox:hover .clientFilterControlBox': {
      display: 'flex',
    },
    clientParamsBox: {
      width: '100%',
    },
    clientParamsHeader: {
      flexDirection: 'row-reverse',
      padding: 0,
      gap: theme.spacing(1.5),
    },
    '@media (max-width: 1024px)': {
      idBox: {
        flex: 1,
      },
      clientFullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '160px',
        whiteSpace: 'nowrap',
      },
      filterInfoBox: {
        padding: `${theme.spacing(1)}px 0px`,
      },
      filterValue: {
        maxWidth: '115px',
      },
    },
    '@media (max-width: 960px)': {
      filterInfoBox: {
        columnGap: `${theme.spacing(1)}px`,
      },
      filterValue: {
        marginRight: 0,
      },
    },
    '@media (max-width: 600px)': {
      filterControl: {
        order: 0,
      },
    },
  };
};
