import { BaseFilter } from './BaseFilter';

export class HouseFilter extends BaseFilter {
  get areaFrom() {
    return this.props.areaFrom;
  }

  set areaFrom(areaFrom) {
    this.props.areaFrom = areaFrom;
  }

  get landAreaFrom() {
    return this.props.landAreaFrom;
  }

  set landAreaFrom(landAreaFrom) {
    this.props.landAreaFrom = landAreaFrom;
  }

  get landAreaTo() {
    return this.props.landAreaTo;
  }

  set landAreaTo(landAreaTo) {
    this.props.landAreaTo = landAreaTo;
  }

  get areaTo() {
    return this.props.areaTo;
  }

  set areaTo(areaTo) {
    this.props.areaTo = areaTo;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  get kitchenAreaFrom() {
    return this.props.kitchenAreaFrom;
  }

  set kitchenAreaFrom(kitchenAreaFrom) {
    this.props.kitchenAreaFrom = kitchenAreaFrom;
  }

  get kitchenAreaTo() {
    return this.props.kitchenAreaTo;
  }

  set kitchenAreaTo(kitchenAreaTo) {
    this.props.kitchenAreaTo = kitchenAreaTo;
  }

  get objectMaterial() {
    return this.props.objectMaterial;
  }

  set objectMaterial(objectMaterial) {
    this.props.objectMaterial = objectMaterial;
  }

  get flooringFrom() {
    return this.props.flooringFrom;
  }

  set flooringFrom(flooringFrom) {
    this.props.flooringFrom = flooringFrom;
  }

  get flooringTo() {
    return this.props.flooringTo;
  }

  set flooringTo(flooringTo) {
    this.props.flooringTo = flooringTo;
  }

  get objectCondition() {
    return this.props.objectCondition;
  }

  set objectCondition(objectCondition) {
    this.props.objectCondition = objectCondition;
  }

  get squarePriceFrom() {
    return this.props.squarePriceFrom;
  }

  set squarePriceFrom(squarePriceFrom) {
    this.props.squarePriceFrom = squarePriceFrom;
  }

  get squarePriceTo() {
    return this.props.squarePriceTo;
  }

  set squarePriceTo(squarePriceTo) {
    this.props.squarePriceTo = squarePriceTo;
  }

  get roomsCount() {
    return this.props.roomsCount;
  }

  set roomsCount(roomsCount) {
    this.props.roomsCount = roomsCount;
  }

  get isMortgage() {
    return this.props.isMortgage;
  }

  set isMortgage(isMortgage) {
    this.props.isMortgage = isMortgage;
  }

  get ignoreLastFloor() {
    return this.props.ignoreLastFloor;
  }

  set ignoreLastFloor(ignoreLastFloor) {
    this.props.ignoreLastFloor = ignoreLastFloor;
  }

  /* eslint-disable complexity */
  toServerEntity() {
    return {
      realEstateSubtype: this.props.objectSubtype ? Array.from(this.props.objectSubtype) : null,
      areaFrom: this.props.areaFrom ? Number(this.props.areaFrom) : 0,
      areaTo: this.props.areaTo ? Number(this.props.areaTo) : null,
      kitchenAreaFrom: this.props.kitchenAreaFrom ? Number(this.props.kitchenAreaFrom) : null,
      kitchenAreaTo: this.props.kitchenAreaTo ? Number(this.props.kitchenAreaTo) : null,
      flooringFrom: this.props.flooringFrom ? Number(this.props.flooringFrom) : null,
      flooringTo: this.props.flooringTo ? Number(this.props.flooringTo) : null,
      livingAreaFrom: this.props.livingAreaFrom ? Number(this.props.livingAreaFrom) : null,
      livingAreaTo: this.props.livingAreaTo ? Number(this.props.livingAreaTo) : null,
      squarePriceFrom: this.props.squarePriceFrom ? Number(this.props.squarePriceFrom) : null,
      squarePriceTo: this.props.squarePriceTo ? Number(this.props.squarePriceTo) : null,
      realEstateRoomsCount: this.props.roomsCount ? Array.from(this.props.roomsCount) : null,
      realEstateCondition: this.props.objectCondition ? Array.from(this.props.objectCondition) : null,
      landAreaFrom: this.props.landAreaFrom ? Number(this.props.landAreaFrom) : null,
      landAreaTo: this.props.landAreaTo ? Number(this.props.landAreaTo) : null,
      realEstateMaterial: this.props.objectMaterial ? Array.from(this.props.objectMaterial) : null,
      realEstateServiceType: this.props.objectServiceType ? Number(this.props.objectServiceType) : null,
      isMortgage: this.props.isMortgage,
      ignoreLastFloor: this.props.ignoreLastFloor,
      ...this.toBaseServerEntity(),
    };
  }
}
